import "./index.scss";
import "../../App.scss";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "../../Hooks/useInterval";
// import useTimeout from "../../Hooks/useTimeout";
import { selectReduxSlice, setStats, setEndlessLevel, setEndlessLevelProgress } from "../../Store/store";
import { useLocation } from "react-router-dom";
import {
  fetchAudio,
  fetchImage,
  shuffle,
  getSearchParams,
  capEveryWord,
} from "../nonUIFuncs";
import styled from "styled-components";
import PropTypes from 'prop-types';

import PopupWord from "../UI/PopupWord";
import CloseEndless from "../UI/CloseEndless";
import EndEndlessGame from "../UI/EndEndlessGame";
import FloatingNumber from "../UI/FloatingNumber";
import RoundBanner from "../UI/RoundBanner";

let steadyClock = 0;
let currentTarget = undefined;
let shortTimer = 0;
let correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
let bodyElement = null;
let chosenNumber = null;
let firstNumber = null;
let secondNumber = null;
let chosenRoundData = [];
let chosenNumberLanguage = null;
let firstNumberLanguage = null;
let secondNumberLanguage = null;
let addOrSubtract = 0;
let switchDisplayVar = "stats";
let id = 0;
let showPopup = false;
let audioFile = null;
let round = 0;
let ready = false;
let number = null;
const ROUND_MAX = 10;

const BubblePop = ({
  checkWorld,
  updateLevelData,
  setProgress,
  children,
  findStars,
  starsObj,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);

  const DOMArrayRef = useRef([]);
  const cssArrayRef = useRef([]);
  const reduxStateRef = useRef(reduxState);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const chosenAudioRef = useRef("");

  const [correctness, setCorrectness] = useState(null);
  const [chosenPopupWord, setChosenPopupWord] = useState("");
  const [DOMArray, setDOMArray] = useState([]);
  const [cssArray, setCssArray] = useState([]);
  const [mouseClickX, setMouseClickX] = useState(0);
  const [mouseClickY, setMouseClickY] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [chosenAudio, setChosenAudio] = useState("");
  const [spawnObjectsDelay, setSpawnObjectsDelay] = useState(null);
  const difficulty = Location.pathname.includes("/endless-mode/")
    ? Params.find((d) => d.id === "difficulty").value.toLowerCase()
    : reduxState.difficulty;
  const getDifficultyValue = (array) => {
    if (difficulty === "easy") {
      return array[0];
    } else if (difficulty === "medium") {
      return array[1];
    } else if (difficulty === "hard") {
      return array[2];
    }
  };
  const NewArrayTimer = 15;
  const SpliceNumber = getDifficultyValue([2, 3, 5]);

  cssArrayRef.current = cssArray;
  DOMArrayRef.current = DOMArray;
  reduxStateRef.current = reduxState;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  chosenAudioRef.current = chosenAudio;
  const runTimers = () => {
    steadyClock = steadyClock + 1;
    shortTimer = shortTimer + 1;
    if (shortTimer === NewArrayTimer) {
      noAnswer();
    }
    if (spawnObjectsDelay !== null && steadyClock % spawnObjectsDelay === 0) {
      spawnObjects();
    }
  };
  useInterval(() => {
    runTimers();
  }, 1000);
  useEffect(() => {
    if (!Location.pathname.includes("/endless-mode/")) {
      let string = `${Params.find((d) => d.id === "game-type").value} - ${
        Params.find((d) => d.id === "game-name").value
      } - ${difficulty}`;
      document.title = capEveryWord(string);
    }
    dispatch(setEndlessLevel(0));
    dispatch(setEndlessLevelProgress(0));
    switchDisplayVar = "stats";
    bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.style.overflow = "hidden";
    ready = true;
    return () => {
      if (bodyElement !== null) {
        bodyElement.style.overflow = "auto";
      }
      switchDisplayVar = "stats";
      round = 0;
      shortTimer = 0;
    };
  }, []);
  const changeDisplay = () => {
    shortTimer = 0;
    let previousTarget = currentTarget;
    let mutatableData = [];
    reduxState.sortedData.forEach((d) => mutatableData.push(d));
    let sortedData = shuffle(mutatableData);
    chosenRoundData = [];
    if (reduxStateRef.current.specificGame.category === "Math") {
      chosenNumber = minMaxScreen(2, reduxStateRef.current.sortedData.length);
      let fnRange = chosenNumber - 1;
      firstNumber = minMaxScreen(1, fnRange);
      secondNumber = chosenNumber - firstNumber;
      chosenNumberLanguage =
        reduxStateRef.current.sortedData[chosenNumber - 1].Language;
      firstNumberLanguage =
        reduxStateRef.current.sortedData[firstNumber - 1].Language;
      secondNumberLanguage =
        reduxStateRef.current.sortedData[secondNumber - 1].Language;
      addOrSubtract = Math.floor(Math.random() * 2);
      if (addOrSubtract === 0) {
        currentTarget = reduxStateRef.current.sortedData.find(
          (d) => Number(d.English) === secondNumber
        );
      } else {
        currentTarget = reduxStateRef.current.sortedData.find(
          (d) => Number(d.English) === chosenNumber
        );
      }
    } else {
      let randomChosenTargetNumber = Math.floor(
        Math.random() * reduxStateRef.current.sortedData.length
      );
      currentTarget = mutatableData[randomChosenTargetNumber];
      if (previousTarget !== undefined) {
        while (previousTarget?.English === currentTarget.English) {
          randomChosenTargetNumber = Math.floor(
            Math.random() * reduxStateRef.current.sortedData.length
          );
          currentTarget = mutatableData[randomChosenTargetNumber];
        }
      }
    }
    chosenRoundData.push(currentTarget);
    let possibleDistractors = sortedData.filter(
      (d) => d.English !== currentTarget.English
    );
    let roundDistractors = possibleDistractors.splice(0, SpliceNumber);
    roundDistractors.forEach((d) => chosenRoundData.push(d));
    newObjectArray();
  };
  const nextEndlessLevel = () => {
    dispatch(setEndlessLevel(reduxStateRef.current.currentLevel + 1));
    dispatch(setEndlessLevelProgress(0));
    number = document.getElementById("levelNumber");
    number?.classList.add("levelNumber");
    setTimeout(() => number?.classList.remove("levelNumber"), 1200);
    setTimeout(() => setProgress("0%"), 700);
  };
  
  const checkMath = (e) => {
    let isEndless = Location.pathname.includes("/endless-mode/");
    let eTarget = e.target.className.includes("contents")
      ? e.target
      : e.target.children[0];
    // clearTimeout(runNewArray);
    let clickedObject = DOMArrayRef.current.find((DOMElement) => {
      return DOMElement.props.id === eTarget.id;
    });
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    let clickedObjectImg;
    if (clickedObject.props.children.props.style !== undefined) {
      clickedObjectImg =
        clickedObject.props.children.props.style.backgroundImage;
    } else {
      clickedObjectImg =
        clickedObject.props.children.props.children.props.style.backgroundImage;
    }
    let targetImgPath = `url(${fetchImage(currentTarget.App_Art)})`;
    // let targetImgPath = `url(${fetchImage(currentTargetRef.current.App_Art)})`;
    if (targetImgPath === clickedObjectImg) {
      if (isEndless === false) {
        let progressBarVal = Math.round(
          ((reduxStateRef.current.stats.roundStats.score + 1) / ROUND_MAX) * 100
        );
        setProgress(`${progressBarVal}%`);
      } else {
        let current = reduxStateRef.current.currentLevelProgress + 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      }
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = reduxStateRef.current.currentLevelProgress + 1;
      dispatch(setEndlessLevelProgress(progress));
      if (reduxStateRef.current.currentLevel + 2 === progress && isEndless) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness("correct");
    } else {
      if (isEndless && reduxStateRef.current.currentLevelProgress > 0) {
        let current = reduxStateRef.current.currentLevelProgress - 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      } else {
        let progressBarVal = Math.round(
          ((reduxStateRef.current.stats.roundStats.score - 1) / ROUND_MAX) * 100
        );
        setProgress(`${progressBarVal}%`);
      }
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (reduxStateRef.current.currentLevelProgress > 0) {
        dispatch(
          setEndlessLevelProgress(
            reduxStateRef.current.currentLevelProgress - 1
          )
        );
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness("wrong");
    }
    if (reduxStateRef.current.stats.roundStats.score + 1 <= 10 || isEndless) {
      changeDisplay();
    }
    setTimeout(() => setCorrectness(null), 1000);
  };
  const stepDifficulty = () => {
    setDOMArray([]);
    setCssArray([]);
    switchDisplayVar = "game";
    setProgress("0%");
    ready = false;
    if (round < 3) {
      round = round + 1;
      changeDisplay();
    } else {
      checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const newObjectArray = async () => {
    setCssArray([]);
    setDOMArray([]);

    shuffle(chosenRoundData);
    let newCssArray = [];
    for (let i = 0; i < chosenRoundData.length; i++) {
      id = id + 1;
      let currentData = chosenRoundData[i];
      let backgroundImage = currentData?.App_Art;
      let randomX = minMaxScreen(
        reduxStateRef.current.width * 0.12,
        reduxStateRef.current.width * 0.6
      );
      let randomR = Math.random() * 255;
      let randomG = Math.random() * 255;
      let randomB = Math.random() * 255;
      let containingDivStyle = {
        left: randomX,
        backgroundColor: `rgb(${randomR},${randomG},${randomB})`,
      };
      let divStyle = {
        backgroundColor: `rgb(${randomR},${randomG},${randomB})`,
        backgroundImage: `url(${fetchImage(backgroundImage)})`,
      };
      let uniqueStringContainer = `container${i}${difficulty}${randomX}`;
      newCssArray.push(
        <div
          onClick={(e) => checkMath(e)}
          id={`${i}`}
          style={containingDivStyle}
          key={uniqueStringContainer}
          className={`bubblepop_itemcontainer fallinganimation_${difficulty}`}
        >
          <div
            id={`${id}`}
            key={uniqueStringContainer}
            className="bubblepop_hitbox"
          >
            <div
              id={`${i}`}
              style={divStyle}
              className="bubblepop_hitboxcontents"
            />
          </div>
        </div>
      );
    }
    ready = true;
    setCssArray(newCssArray);
      if (reduxStateRef.current.stats.roundStats.score < 9) {
        setTimeout(() => playAudio(currentTarget.App_Audio), 100);
      }
    setSpawnObjectsDelay(1);
  };
  const spawnObjects = () => {
    let randomizedList = shuffle(cssArrayRef.current);
    let newObject = undefined;
    if (randomizedList.length > 0) {
      newObject = randomizedList[0];
      setCssArray(randomizedList.filter((d) => d.key !== newObject.key));
      setDOMArray([...DOMArrayRef.current, newObject]);
    }
    if (newObject === undefined) {
      setSpawnObjectsDelay(null);
    }
  };
  
  // useInterval(spawnObjects, spawnObjectsDelay);
  const noAnswer = () => {
    shortTimer = 0;
    setMouseClickX(reduxStateRef.current.width / 2);
    setMouseClickY(reduxStateRef.current.height / 2);
    dispatch(
      setStats({
        misses: reduxStateRef.current.stats.misses + 1,
        score: reduxStateRef.current.stats.score - 1,
        roundStats: {
          misses: reduxStateRef.current.stats.roundStats.misses + 1,
          score: reduxStateRef.current.stats.roundStats.score - 1,
        },
      })
    );
    setCorrectness("wrong");
    changeDisplay();
    setTimeout(() => setCorrectness(null), 1000);
    let isEndless = Location.pathname.includes('/endless-mode/');
    if (isEndless && reduxStateRef.current.currentLevelProgress > 0) {
      let current = reduxStateRef.current.currentLevelProgress - 1;
      let denom = reduxStateRef.current.currentLevel + 2;
      let progressBarVal = Math.round((current / denom) * 100);
      setProgress(`${progressBarVal}%`);
      if (reduxStateRef.current.currentLevelProgress > 0) {
        dispatch(
          setEndlessLevelProgress(
            reduxStateRef.current.currentLevelProgress - 1
          )
        );
      }
    } else {
      let progressBarVal = Math.round(
        ((reduxStateRef.current.stats.roundStats.score - 1) / ROUND_MAX) * 100
      );
      setProgress(`${progressBarVal}%`);
    }
  };
  const playAudio = (audioName) => {
    try {
      if (audioFile !== null) {
        audioFile.pause();
        audioFile.currentTime = 0;
        audioFile = null;
      }
    } catch (e) {
      console.log(audioName, e);
    }
    if (audioName !== chosenAudio) {
      audioFile = new Audio(fetchAudio(audioName));
      setChosenAudio(audioName);
      audioFile.play();
    }
  };
  const stopAudio = () => {
    if (audioFile !== null) {
      audioFile.pause();
      audioFile.currentTime = 0;
      audioFile = null;
    }
  };
  const addSubtract = () => {
    return (
      <div
        className="bubblepop_keywordbox"
        onClick={() => playAudio(currentTarget.App_Audio)}
      >
        <div className="bubblepop_keyword noselect">
          {addOrSubtract <= 0
            ? `${chosenNumberLanguage} - ${firstNumberLanguage}`
            : `${firstNumberLanguage} + ${secondNumberLanguage}`}
        </div>
      </div>
    );
  };
  const minMaxScreen = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  if (switchDisplayVar === "stats") {
    if (Location.pathname.includes("/endless-mode/")) {
      ready = true;
      switchDisplayVar = "game";
      changeDisplay();
    }
    if (shortTimer > 1) {
      stepDifficulty();
    }
  } else {
    if (
      reduxStateRef.current.stats.roundStats.score >= ROUND_MAX &&
      ready === true &&
      !Location.pathname.includes("/endless-mode/")
    ) {
      // clearTimeout(runNewArray);
      shortTimer = 0;
      switchDisplayVar = "stats";
      stopAudio();
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    top: calc(${mouseClickYRef.current}px - 10vh);
    left: ${mouseClickXRef.current}px;
    z-index: 99999;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  return (
    <div className="bubblepop_outercontainer">
      {children}
      {switchDisplayVar === "stats" ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className="centerContentContainer">
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          {reduxState.specificGame.category === "Math" ? (
            addSubtract()
          ) : (
            <div
              className="bubblepop_keywordbox"
              onClick={() => playAudio(currentTarget.App_Audio)}
            >
              <p
                className="bubblepop_keyword noselect"
                style={{
                  fontSize:
                    currentTarget.Language.length > 20
                      ? "2vmin"
                      : currentTarget.Language.length > 12
                      ? "3vmin"
                      : "5vmin",
                }}
              >
                {currentTarget.Language}
              </p>
            </div>
          )}
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {DOMArrayRef.current}
          {Location.pathname.includes("/endless-mode/") ? (
            <div>
              <CloseEndless
                enabled={Location.pathname.includes("/endless-mode/")}
                onClick={setShowEndlessEnd}
              />
              <EndEndlessGame
                enabled={showEndlessEnd}
                currentLevel={reduxStateRef.current.currentLevel}
                setShowEndlessEnd={setShowEndlessEnd}
                updateLevelData={updateLevelData}
                endGame={undefined}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default BubblePop;

BubblePop.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
  setProgress: PropTypes.func,
  children: PropTypes.object,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};