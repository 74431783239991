import "./index.scss";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DragDropContext } from "react-beautiful-dnd";

// import StatScreen from "../StatScreen";
import RoundBanner from '../UI/RoundBanner';
import Card from "./Card";
import { shuffle, fetchImage, fetchAudio } from "../nonUIFuncs";
import PopupWord from "../UI/PopupWord";
import FloatingNumber from "../UI/FloatingNumber";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

let popNumber = 2;
let formattedData = {
  cards: {},
  cardLocations: {},
  cardLocationOrder: [],
};
let listening = false;
let switchDisplayVar = "stats";
let buttons = [">", "<", "="];
let plusOrMinusVar = "+";
let plusOrMinusTwo = "+";
let firstSegment = null;
let secondSegment = null;
let ready = false;
let resetClock = 0;
let showPopup = false;
let setFullTime = true;
let allTime = 0;
let background = null;
let correctSound = null;
let round = 0;
let shortTimer = 0;

const Crocodile = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const reduxStateRef = useRef(reduxState);
  const chosenNumbersRef = useRef();
  const reduxStatsRef = useRef(reduxState.stats);
  const [correctness, setCorrectness] = useState(null);
  const [drag, setDrag] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [chosenNumbers, setChosenNumbers] = useState([]);
  chosenNumbersRef.current = chosenNumbers;
  reduxStatsRef.current = reduxState.stats;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    let timer = setInterval(() => shortTimer = shortTimer + 1, 1000);
    ready = false;
    listening = false;
    switchDisplayVar = "stats";
    round = 0;
    correctSound = new Audio(`${fetchAudio("correctSound.mp3")}`);
    gatherData();
    return () => {
      ready = false;
      round = 0;
      listening = false;
      switchDisplayVar = "stats";
      clearInterval(timer);
      resetData();
      formattedData = {
        cards: {},
        cardLocations: {},
        cardLocationOrder: [],
      };
    };
  }, []);
  const gatherData = () => {
    popNumber = 4;
    if (reduxStateRef.current.difficulty === "easy") {
      popNumber = 2;
    } else if (reduxStateRef.current.difficulty === "medium") {
      popNumber = 3;
    }
    resetData();
    plusOrMinus();
    let mutatableNumbers = [...reduxStateRef.current.sortedData];
    console.log('MUT', mutatableNumbers);
    setChosenNumbers(shuffle(mutatableNumbers).slice(0, popNumber));
    formatData();
  };
  const formatData = () => {
    formattedData.cardLocations = {
      ...formattedData.cardLocations,
      [`solveSpot${0}`]: {
        id: `solveSpot${0}`,
        contentId: [],
      },
    };
    formattedData.cardLocationOrder.push(`solveSpot${0}`);
    let i = 0;
    buttons.forEach((datum) => {
      formattedData.cards = {
        ...formattedData.cards,
        [`cardDraggable${i}`]: {
          id: `cardDraggable${i}`,
          content: datum,
        },
      };
      formattedData.cardLocations = {
        ...formattedData.cardLocations,
        [`location${i}`]: {
          id: `location${i}`,
          contentId: [`cardDraggable${i}`],
        },
      };
      formattedData.cardLocationOrder.push(`location${i}`);
      i = i + 1;
    });
    console.log('formattedData', formattedData);
    ready = true;
  };
  const plusOrMinus = () => {
    if (Math.random() > 0.5) {
      plusOrMinusVar = "+";
    } else {
      plusOrMinusVar = "-";
    }
    if (Math.random() > 0.5) {
      plusOrMinusTwo = "+";
    } else {
      plusOrMinusTwo = "-";
    }
  };

  const resetData = () => {
    // setChosenNumbers([]);
    formattedData = {
      cards: {},
      cardLocations: {},
      cardLocationOrder: [],
    };
  };
  const stepDifficulty = () => {
    props.setProgress("0%");
    ready = false;
    switchDisplayVar = "game";

    resetClock = 0;
    resetData();
    setFullTime = true;
    if (round < 3) {
      round = round + 1;
      gatherData();
    } else {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const onDragEnd = (result) => {
    setDrag(false);
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let begin = formattedData.cardLocations[source.droppableId];
    let end = formattedData.cardLocations[destination.droppableId];
    let beginContent = begin.contentId;
    let endContent = end.contentId;
    const newBegin = {
      ...begin,
      contentId: endContent,
    };
    const newEnd = {
      ...end,
      contentId: beginContent,
    };
    const newFormattedData = {
      ...formattedData,
      cardLocations: {
        ...formattedData.cardLocations,
        [newBegin.id]: newBegin,
        [newEnd.id]: newEnd,
      },
    };
    formattedData = newFormattedData;
    if (formattedData.cardLocations.solveSpot0.contentId.length > 0) {
      checkMath();
    }
  };
  const checkMath = () => {
    listening = false;
    let filledId = formattedData.cardLocations.solveSpot0.contentId[0];
    let filledContent = formattedData.cards[filledId].content;
    let solution = undefined;
    if (filledContent === ">") {
      solution = firstSegment > secondSegment;
    } else if (filledContent === "<") {
      solution = firstSegment < secondSegment;
    } else {
      solution = firstSegment === secondSegment;
    }
    if (solution === true) {
      correctSound.play();
      let popupWords = reduxStateRef.current.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          hits: reduxStatsRef.current.hits + 1,
          score: reduxStatsRef.current.score + 1,
          roundStats: {
            hits: reduxStatsRef.current.roundStats.hits + 1,
            score: reduxStatsRef.current.roundStats.score + 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness("correct");
      if (reduxStatsRef.current.roundStats.score >= 9) {
        shortTimer = 0;
        switchDisplayVar = "stats";
      }
    } else {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStatsRef.current.misses + 1,
          score: reduxStatsRef.current.score - 1,
          roundStats: {
            misses: reduxStatsRef.current.roundStats.misses + 1,
            score: reduxStatsRef.current.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness("wrong");
    }
    gatherData();
    setTimeout(() => setCorrectness(null), 1000);
  };
  if (background === null) {
    background = `url(${fetchImage(reduxStateRef.current.specificGame.backgroundImg)})`;
  }
  if (switchDisplayVar === "stats") {
    if (setFullTime === true) {
      setFullTime = false;
    }
    if (shortTimer === 2) {
      stepDifficulty();
    }
  }
  const displayData = (FD) => {
    let cardOptions = FD.cardLocationOrder.map(
      (cardLocationId, index) => {
        const cardLocation = FD.cardLocations[cardLocationId];
        const content = cardLocation.contentId.map(
          (cardId) => FD.cards[cardId]
        );
        return (
          <Card
            key={cardLocation.id}
            cardLocation={cardLocation}
            content={content}
            indexed={index}
          />
        );
      }
    );
    let solveSpot = cardOptions.filter((spot) => spot.key === "solveSpot0");
    let symbolOptions = cardOptions.slice(1);
    let displayDOM = {};
    let doMath = {
      "+": function (x, y) {
        return x + y;
      },
      "-": function (x, y) {
        return x - y;
      },
    };
    let testStyle = {
      fontSize: 15,
    };
    let longString = 0;
    chosenNumbersRef.current.forEach((obj) => {
      longString = longString + obj.Language.length;
    });
    testStyle.fontSize = 1000 / longString;
    if (testStyle.fontSize > 45) {
      testStyle.fontSize = 45;
    } else if (testStyle.fontSize < 25) {
      testStyle.fontSize = 25;
    }
    console.log('CH', chosenNumbersRef.current);
    if (reduxStateRef.current.difficulty === "easy") {
      firstSegment = Number(chosenNumbersRef.current[0].English);
      secondSegment = Number(chosenNumbersRef.current[1].English);
      displayDOM = (
        <div className="CG-displayDOM">
          <div className="CG-numberDis">
            {chosenNumbersRef.current[0].Language}
          </div>
          {solveSpot}
          <div className="CG-numberDis">
            {chosenNumbersRef.current[1].Language}
          </div>
        </div>
      );
    } else if (reduxStateRef.current.difficulty === "medium") {
      firstSegment = doMath[plusOrMinusVar](
        Number(chosenNumbersRef.current[0].English),
        Number(chosenNumbersRef.current[1].English)
      );
      secondSegment = Number(chosenNumbersRef.current[2].English);
      displayDOM = (
        <div className="CG-displayDOM">
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[0].Language}
          </div>
          <div className="CG-symbolDis">{plusOrMinusVar}</div>
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[1].Language}
          </div>
          {solveSpot}
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[2].Language}
          </div>
        </div>
      );
    } else {
      firstSegment = doMath[plusOrMinusVar](
        Number(chosenNumbersRef.current[0].English),
        Number(chosenNumbersRef.current[1].English)
      );
      secondSegment = doMath[plusOrMinusTwo](
        Number(chosenNumbersRef.current[2].English),
        Number(chosenNumbersRef.current[3].English)
      );
      displayDOM = (
        <div className="CG-displayDOM">
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[0].Language}
          </div>
          <div className="CG-symbolDis">{plusOrMinusVar}</div>
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[1].Language}
          </div>
          {solveSpot}
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[2].Language}
          </div>
          <div className="CG-symbolDis">{plusOrMinusTwo}</div>
          <div className="CG-numberDis" style={testStyle}>
            {chosenNumbersRef.current[3].Language}
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="CG-placemat">{displayDOM}</div>
        <div className="CG-DD-outerContainer">
          <div className="CG-DD-innerContainer">{symbolOptions}</div>
        </div>
      </div>
    );
  };
  return (
    <DragDropContext onDragStart={() => setDrag(true)} onDragEnd={onDragEnd}>
      <div
        id="fullScreen"
        style={{ backgroundImage: background }}
        className="fullScreen overflow-y: hidden;">
        {switchDisplayVar === "stats" ? (
          <RoundBanner
            round={round + 1}
            findStars={props.findStars}
            starsObj={props.starsObj}
          />
        ) : (
          <div className="centerContentContainer">
            <div className="CG-plusMinusContainer">
              <FloatingNumber correctness={correctness} />
            </div>
            {ready && chosenNumbersRef.current.length > 0 ? displayData(formattedData) : null}
            {/* {ready ? <DisplayData FD={formattedData} /> : null} */}

            <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          </div>
        )}
      </div>

      {listening ? <div className="CG-fullScreen" /> : null}
    </DragDropContext>
  );
};

export default Crocodile;

Crocodile.propTypes = {
  checkWorld: PropTypes.func,
};

// SwitchDisplay.propTypes = {
//   chosenPopupWord: PropTypes.string,
//   correctness: PropTypes.string,
//   displayData: PropTypes.func,
//   roundStats: PropTypes.object,
//   stepDifficulty: PropTypes.func,
// };
