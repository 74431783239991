import "./index.scss";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import PropTypes from "prop-types";

const Symbol = (props) => {
  const droppableContents = () => {
    return props.content.map((content) => {
      return (
        <Draggable
          className="solveItGame-yesClick"
          key={content.id}
          type="symbol"
          draggableId={content.id}
          index={props.indexed}
        >
          {(provided) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <div className="noselect symbol-buttonStyle symbol-buttonContents">
                {content.content}
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  };
  return (
    <Droppable type="symbol" droppableId={props.buttonLocation.id}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="symbol-emptySpot symbol-buttonContents"
        >
          {droppableContents()}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
export default Symbol;

Symbol.propTypes = {
  buttonLocation: PropTypes.object,
  content: PropTypes.array,
  indexed: PropTypes.number,
};
