import "./index.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, findPlayerLevel } from "../../Store/store";
import { goBack, fetchImage } from "../nonUIFuncs";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";


const PlayAgainScreen = ({
  setScreen,
  gameTime,
  updateScoreData,
  scoreType,
  gameTypeImage,
  starsObj,
  game,
  bestScore,
  gameToSave,
  starsToAdd,
}) => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  const location = useLocation();
  // const [game, setGame] = useState({time: 0, score: 0, stars: 0});
  let unitName = "";
  let Key = 1;
  let searchArr =
    location.search
      .replace("?", "")
      .split("&")
      .find((str) => str.includes("unit=")) || "";
  // let game = {time: 0, score: 0, stars: 0};
  useEffect(() => {
    // findStars();
  }, []);
  if (searchArr !== undefined) {
    unitName = searchArr
      .split("=")[1]
      .replaceAll("%20", " ")
      .replaceAll("%27", "'")
      .replaceAll("%E2%80%99", "’");
    if (isNaN(Number(unitName.slice(0, 2)))) {
      Key = Number(unitName.slice(0, 1));
      unitName = unitName.slice(1);
    } else {
      Key = Number(unitName.slice(0, 2));
      unitName = unitName.slice(2);
    }
  }

  return (
    <div>
      <div className="playagain_container">
        <div className="playagain_container_background" />
        <div className="playagain_contents">
          {/* Stat Keeper Start */}
          <div className="playagain_statkeeper">
            <div className="playagain_statkeeper_contents">
              {scoreType === "time" ? (
                <div className="playagain_statkeeper_column_left">
                  <div className="playagain_statbanner">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_statname">Time</p>
                    </div>
                    <p className="playagain_statnumber">{gameTime}</p>
                  </div>
                </div>
              ) : (
                <div className="playagain_statkeeper_column_left">
                  <div className="playagain_statbanner">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_statname">Hits</p>
                    </div>
                    <p className="playagain_statnumber">
                      {reduxState.stats.hits}
                    </p>
                  </div>
                  <div className="playagain_statbanner">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_statname">Misses</p>
                    </div>
                    <p className="playagain_statnumber">
                      {reduxState.stats.misses}
                    </p>
                  </div>
                  <div className="playagain_statbanner">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_statname">Score</p>
                    </div>
                    <p className="playagain_statnumber">
                      {reduxState.stats.score}
                    </p>
                  </div>
                  {gameTime !== null && scoreType !== "normal" ? (
                    <div className="playagain_statbanner">
                      <div className="playagain_scorecontainer">
                        <p className="playagain_statname">Time</p>
                      </div>
                      <p className="playagain_statnumber">{gameTime}</p>
                    </div>
                  ) : null}
                </div>
              )}
              <div
                style={{
                  width: 0,
                  border: "2px dotted rgba(55,55,55,0.4)",
                  height: "90%",
                  margin: "clamp(5px, 2.5vmin, 20px)",
                }}
              />
              <div className="playagain_statkeeper_column">
                <div
                  className="playagain_closebutton"
                  onClick={() => setScreen("playScreen")}
                />
                <div className="playagain_levelcontainer">
                  <div className="playagain_leveltextcontainer">
                    <p className="playagain_leveltext">
                      Level {reduxState.playerLevelNumbers.playerLevel}{" "}
                      {/* TODO: Automate this number */}
                    </p>
                  </div>
                </div>
                <div className="playagain_starcontainer">
                  <div className="playagain_starone">
                    {starsObj.starOne ? (
                      <div className="playagain_fullstar" />
                    ) : null}
                  </div>
                  <div className="playagain_startwo">
                    {starsObj.starTwo ? (
                      <div className="playagain_fullstar" />
                    ) : null}
                  </div>
                  <div className="playagain_starthree">
                    {starsObj.starThree ? (
                      <div className="playagain_fullstar" />
                    ) : null}
                  </div>
                </div>
                <div className="playagain_bluestreak">
                  <div className="playagain_fullscorecontainer">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_yourscore">Your Score</p>
                    </div>
                    {scoreType === "time" ? (
                      <p className="playagain_scorenumber">{gameTime}</p>
                    ) : (
                      <p className="playagain_scorenumber">
                        {reduxState.stats.score}
                      </p>
                    )}
                  </div>
                  {bestScore === "current" ? (
                    <div className="playagain_highestscoreimg" />
                  ) : null}
                </div>
                <div className="playagain_bluestreak">
                  {reduxState.width > 500 ? (
                    <div className="playagain_greenarrow" />
                  ) : null}
                  <div className="playagain_fullscorecontainer">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_previousbest">Previous Best</p>
                    </div>
                    {scoreType === "time" ? (
                      <p className="playagain_scorenumber">{game.time || 0}</p>
                    ) : (
                      <p className="playagain_scorenumber">{game.score || 0}</p>
                    )}
                  </div>
                  {bestScore === "previous" ? (
                    <div className="playagain_highestscoreimg" />
                  ) : null}
                </div>
                <div
                  className="playagain_continuebutton"
                  onClick={() => {
                    updateScoreData(gameToSave); // TODO: send through the best score, not the most recent
                    dispatch(
                      findPlayerLevel(starsToAdd, reduxState.playerLevelNumbers)
                    );
                    setScreen("playScreen");
                  }}
                />
              </div>
            </div>
          </div>
          {/* Stat Keeper End */}
        </div>
      </div>
      {/* UI Start */}
      <div>
        {/* Top Banner */}
        <div className="playagain_topbanner">
          <div className="playagain_topbanner_contents">
            <div
              className="playagain_topbanner_badge"
              style={{
                backgroundImage: `url(${fetchImage(
                  `Common_Category_Badge_${Key}1.png`
                )})`,
              }}
            />
            <h1 className="playagain_topbanner_headertext">{unitName}</h1>
            <div
              className="playagain_topbanner_badge"
              style={{
                backgroundImage: `url(${fetchImage(
                  `Common_Category_Badge_${Key}1.png`
                )})`,
              }}
            />
          </div>
        </div>
        {/* Back Button */}
        <Link
          to={{
            pathname: goBack(location).pathname,
            search: goBack(location).search,
          }}
          className="playagain_backbutton"
        />
        <div className="playagain_gametypebadgecontainer">
          <div
            className="playagain_gametypebadge"
            style={{ backgroundImage: `url(${fetchImage(gameTypeImage)})` }}
          />
        </div>
      </div>
      {/* UI End */}
    </div>
  );
};

export default PlayAgainScreen;

PlayAgainScreen.propTypes = {
  setScreen: PropTypes.func,
  gameTime: PropTypes.number,
  updateScoreData: PropTypes.func,
  scoreType: PropTypes.string,
  gameTypeImage: PropTypes.string,
  starsObj: PropTypes.object,
  game: PropTypes.object,
  bestScore: PropTypes.string,
  gameToSave: PropTypes.object,
  starsToAdd: PropTypes.number,
};