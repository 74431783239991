import './index.scss';
import '../../App.scss';

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReduxSlice, setStats, setEndlessLevel, setEndlessLevelProgress } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import {
  fetchAudio,
  fetchImage,
  shuffle,
  getSearchParams,
  capEveryWord,
} from "../nonUIFuncs";
import styled from 'styled-components';
import PropTypes from 'prop-types';

import PopupWord from '../UI/PopupWord';
import CloseEndless from '../UI/CloseEndless';
import EndEndlessGame from '../UI/EndEndlessGame';
import FloatingNumber from '../UI/FloatingNumber';
import RoundBanner from '../UI/RoundBanner';



let audioFile = null;
let correctSound = new Audio(`${fetchAudio('correctSound.mp3')}`);
let smallerDim = 4;
let chosenTarget = null;
let chosenDistractors = [];
let fullRoundData = [];
let flipWord = false;
let fullTime = 30;
let showPopup = false;
let round = 0;
let ready = false;
let switchDisplayVar = 'stats';
let shortTimer = 0;
let audioDOM = [];

const WhackAMole = ({
  checkWorld,
  setProgress,
  updateLevelData,
  children,
  findStars,
  starsObj,
}) => {
  let updateClock;
  let timer;

  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);
  const difficultyRef = useRef('');
  const reduxStateRef = useRef(reduxState);
  const bodyXRef = useRef(0);
  const bodyYRef = useRef(0);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const gameDOMArrayRef = useRef([]);

  const [keyword, setKeyword] = useState('');
  const [correctness, setCorrectness] = useState('');
  const [mouseClickX, setMouseClickX] = useState(0);
  const [mouseClickY, setMouseClickY] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState('');
  const [displayedClock, setDisplayedClock] = useState(0);
  const [difficulty, setDifficulty] = useState('easy');
  const [bodyX, setBodyX] = useState(0);
  const [bodyY, setBodyY] = useState(0);
  const [gameDOMArray, setGameDOMArray] = useState([])

  difficultyRef.current = difficulty;
  reduxStateRef.current = reduxState;
  bodyXRef.current = bodyX;
  bodyYRef.current = bodyY;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  gameDOMArrayRef.current = gameDOMArray;

  useEffect(() => {
    if (!Location.pathname.includes("/endless-mode/")) {
      let string = `${Params.find((d) => d.id === "game-type").value} - ${
        Params.find((d) => d.id === "game-name").value
      } - ${reduxStateRef.current.difficulty}`;
      document.title = capEveryWord(string);
      setDifficulty(reduxStateRef.current.difficulty);
    } else {
      setDifficulty(Params.find(
        (d) => d.id === "difficulty"
      ).value.toLowerCase());
    }
    switchDisplayVar = 'stats';
    round = 0;
    shortTimer = 0;
    createAudio();
    setGameDOMArray([]);
    intervalEntity();
    smallerDim = reduxStateRef.current.height;
    if (reduxStateRef.current.height > reduxStateRef.current.width) {
      smallerDim = reduxStateRef.current.width;
    }
    dispatch(setEndlessLevel(0));
    dispatch(setEndlessLevelProgress(0));
    setDisplayedClock(0);
    getRoundData(reduxStateRef.current.difficulty);
    return (() => {
      setGameDOMArray([]);
      clearInterval(timer);
      clearInterval(updateClock);
      switchDisplayVar = 'stats';
      round = 0;
      shortTimer = 0;
      pauseAllAudio();
      setDifficulty('easy');
    });
  },[]);
  const intervalEntity = () => {
    let newItemInterval = 1800;
    if (difficultyRef.current === 'medium') {
      newItemInterval = 1500;
    } else if (difficultyRef.current === 'hard') {
      newItemInterval = 1200;
    }
    timer = setInterval(() => changeDisplay(), newItemInterval);
    if (Location?.state?.endless === true) {
      updateClock = setInterval(() => getRoundData(difficultyRef.current), 10000);
    } else {
      updateClock = setInterval(updateDisplayedClock, 1000);
    }
  };
  const nextEndlessLevel = () => {
    dispatch(setEndlessLevel(reduxStateRef.current.currentLevel + 1));
    dispatch(setEndlessLevelProgress(0));
    let number = document.getElementById('levelNumber');
    number?.classList.add('levelNumber');
    setTimeout(() => number?.classList.remove('levelNumber'), 1200);
    setTimeout(() => setProgress("0%"), 700);
  };
  const updateDisplayedClock = () => {
    shortTimer = shortTimer + 1;
    if (shortTimer === 10 || shortTimer === 20) {
      pauseAllAudio();
      getRoundData(difficultyRef.current);
    }
    setDisplayedClock(shortTimer + 1);
    setProgress(`${Math.round(((shortTimer + 1) / fullTime)* 100)}%`);
  };
  const getRoundData = (dif) => {
    let previousTarget = chosenTarget;
    let shuffled = shuffle(reduxState.sortedData);
    chosenTarget = shuffled[0];
    if (previousTarget !== null) {
      while (previousTarget.English === chosenTarget.English) {
        shuffled = shuffle(reduxState.sortedData);
        chosenTarget = shuffled[0];
      }
    }
    setKeyword(chosenTarget.Language);
    let findDistractors = shuffled.filter(
      (d) => d.English !== chosenTarget.English
    );
    let shuffledDistractors = shuffle(findDistractors);
    if (dif === 'easy') {
      chosenDistractors = shuffledDistractors.slice(0, 1);
    } else if (dif === 'medium') {
      chosenDistractors = shuffledDistractors.slice(0, 2);
    } else {
      chosenDistractors = shuffledDistractors.slice(0, 3);
    }
    fullRoundData = [];
    if (dif === 'easy') {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    } else if (dif === 'medium') {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    } else {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    }
    chosenDistractors.forEach((d) => {
      fullRoundData.push(d);
    });
    setTimeout(() => playAudio(chosenTarget.App_Audio), 1);
  };
  const pauseAllAudio = () => {
    correctSound.pause();
    correctSound.currentTime = 0;
    if (audioFile !== null) {
      audioFile.pause();
      audioFile.currentTime = 0;
    }
  };
  const createAudio = () => {
    audioDOM = reduxState.sortedData.map((d) => {
      if (d.App_Audio.length === 0) {
        return null;
      }
      return (
        <audio
          id={d.App_Audio}
          key={`${d.App_Audio}${d.Key}`}
          src={`${fetchAudio(d.App_Audio)}`}
        />
      );
    })
  }
  const playAudio = (fileName) => {
    audioFile = document.getElementById(fileName);
    if (audioFile !== null) {
      audioFile.play();
    }
  };
  const changeDisplay = () => {
    let imgDim = smallerDim / 5;
    let randomTarget = Math.floor(Math.random() * fullRoundData.length);
    let moleData = fullRoundData[randomTarget];
    let gameTarget = moleData.App_Art;
    let bodyXVar = bodyXRef.current;
    let bodyYVar = bodyYRef.current;
    let randomX = Math.random();
    let randomY = Math.random();
    let xDifficulty = 3;
    let yDifficulty = 6.6;
    if (difficultyRef.current === 'easy') {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (bodyYVar === 5) {
          if (randomX <= 0.5) {
            bodyXVar = 1;
          } else {
            bodyXVar = 2;
          }
        } else {
          bodyXVar = 1.5;
        }
      }
    } else if (difficultyRef.current === 'medium') {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (randomX <= 0.5) {
          bodyXVar = 1;
        } else {
          bodyXVar = 2;
        }
      }
    } else {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        xDifficulty = 4;
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (randomX <= 0.33) {
          bodyXVar = 1;
        } else if (randomX <= 0.66) {
          bodyXVar = 2;
        } else {
          bodyXVar = 3;
        }
      }
    }
    let moleSprite = `${fetchImage(gameTarget)}`;
    let positionStyle = {
      backgroundImage: `url(${moleSprite})`,
      top: ((reduxStateRef.current.height * bodyYVar) / yDifficulty
        - imgDim / 2) - (reduxStateRef.current.height / 5),
      left: ((reduxStateRef.current.width * bodyXVar) / xDifficulty
        - imgDim / 2) - (reduxStateRef.current.width / 12),
      width: imgDim,
      height: imgDim,
    };
    let newGameDOMArray = gameDOMArrayRef.current.filter(
      (element) => element.props['data-position'] !== `${bodyXVar},${bodyYVar}`,
    );
    setGameDOMArray([
      ...newGameDOMArray,
      <div
        className='whackamole_mole'
        onClick={(e) => checkCorrectness(e)}
        key={`${randomX}${randomY}`}
        data-position={`${bodyXVar},${bodyYVar}`}
        data-english={moleData.English}
        style={positionStyle}
      />
    ]);
    setBodyX(bodyXVar);
    setBodyY(bodyYVar);
  };
  const checkCorrectness = (e) => {
    let isEndless = Location.pathname.includes("/endless-mode/");
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    pauseAllAudio();
    let clicked = reduxState.sortedData.find((d) => d.English.toString() === e.target.dataset.english.toString());
    setGameDOMArray(gameDOMArrayRef.current.filter((ele) => ele.props['data-position'] !== e.target.dataset.position));

    setTimeout(() => playAudio(clicked.App_Audio), 1);

    if (clicked.English === chosenTarget.English) {
      if (isEndless) {
        let current = reduxStateRef.current.currentLevelProgress + 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      }
      let popupWords = reduxStateRef.current.popupWords.filter((d) => d.type === 'correct');
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSound.volume = 0.2;
      correctSound.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('correct');
      let progress = reduxStateRef.current.currentLevelProgress + 1;
      dispatch(setEndlessLevelProgress(progress));
      if (
        reduxStateRef.current.currentLevel + 2 === progress &&
        Location?.state?.endless === true
      ) {
          setTimeout(nextEndlessLevel, 700);
        }
    } else {
      if (isEndless && reduxStateRef.current.currentLevelProgress > 0) {
        let current = reduxStateRef.current.currentLevelProgress - 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      }
      let popupWords = reduxStateRef.current.popupWords.filter((d) => d.type === 'incorrect');
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('wrong');
      if (reduxStateRef.current.currentLevelProgress > 0) {
        dispatch(
          setEndlessLevelProgress(
            reduxStateRef.current.currentLevelProgress - 1
          )
        );
      }
    }
    setTimeout(() => setCorrectness(''), 1000);
  };
  const stepDifficulty = () => {
    setDisplayedClock(0);
    setProgress('0%');
    ready = false;
    switchDisplayVar = 'game';
    if (round < 3) {
      round = round + 1;
      changeDisplay();
    } else {
      checkWorld();
    }
  };
  if (switchDisplayVar === 'stats') {
    if (Location?.state?.endless) {
      ready = false;
      switchDisplayVar = 'game';
      // changeDisplay();
    }
    if (shortTimer >= 1) {
      stepDifficulty();
    }
    pauseAllAudio();
  } else {
    if (fullTime <= shortTimer) {
      setDisplayedClock(0);
      shortTimer = 0;
      switchDisplayVar = 'stats';
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${mouseClickYRef.current}px - 10vh);
    left: ${mouseClickXRef.current}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {children}
      {switchDisplayVar === "stats" ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className="centerContentContainer">
          {audioDOM}
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <div
            className="whackamole_keywordbox"
            onClick={() => playAudio(chosenTarget.App_Audio)}
          >
            <div
              style={{
                fontSize:
                  keyword.length > 21
                    ? "2vmin"
                    : keyword.length > 12
                    ? "3vmin"
                    : "5vmin",
              }}
              className="keyword noselect whackamole_keyword"
            >
              {keyword}
            </div>
          </div>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {gameDOMArrayRef.current}
          <CloseEndless
            enabled={Location?.state?.endless}
            onClick={setShowEndlessEnd}
          />
          <EndEndlessGame
            enabled={showEndlessEnd}
            currentLevel={reduxStateRef.current.currentLevel}
            setShowEndlessEnd={setShowEndlessEnd}
            updateLevelData={updateLevelData}
            endGame={undefined}
          />
          {Location.pathname.includes("/endless-mode/") ? (
            <div>
              <CloseEndless
                enabled={Location.pathname.includes("/endless-mode/")}
                onClick={setShowEndlessEnd}
              />
              <EndEndlessGame
                enabled={showEndlessEnd}
                currentLevel={reduxStateRef.current.currentLevel}
                setShowEndlessEnd={setShowEndlessEnd}
                updateLevelData={updateLevelData}
                endGame={undefined}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default WhackAMole;

WhackAMole.propTypes = {
  checkWorld: PropTypes.func,
  setProgress: PropTypes.func,
  updateLevelData: PropTypes.func,
  children: PropTypes.object,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};