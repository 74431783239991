import "./index.scss";
// import {Icon} from "semantic-ui-react";

const LoadingScreen = () => {
  return (
    <div className="loadingscreen_container">
      <div className="loadingscreen_contentContainer">
        <div className="loadingscreen_content">
          {/* <Icon fitted loading size="huge" name="spinner" /> */}
        </div>
        <p className="loadingscreen_content">Loading...</p>
        <p className="loadingscreen_contentSmall">
          Please make sure you have a strong internet connection.
        </p>
        <p className="loadingscreen_contentSmall">
          If this screen lasts longer than 10 seconds, reload your browser.
        </p>
      </div>
    </div>
  );
};

export default LoadingScreen;
