const AppName = "Salish Kids Zone";
const Language = "Salish";
const Tribe = "Salish";
const FirebaseConfig = {
  apiKey: "AIzaSyDcG0JRsEx6Ecq34NivPj7NihMotNYi8U0",
  authDomain: "salish-7c4e1.firebaseapp.com",
  projectId: "salish-7c4e1",
  storageBucket: "salish-7c4e1.appspot.com",
  messagingSenderId: "384220497463",
  appId: "1:384220497463:web:40c34d91fb0ee89cc461b2",
  measurementId: "G-9HB6C8GQQS",
};
const FilePath = "https://storage.googleapis.com/salishmedia/";

const SheetsToPull = [
  { collection: "KZ_AppData", nameToSave: "gameData" },
  { collection: "KZ_GameDescriptions", nameToSave: "gameDescriptions" },
  { collection: "KZ_GameFilterData", nameToSave: "data" },
  { collection: "KZ_GoldStar", nameToSave: "goldStar" },
  { collection: "KZ_PopupWords", nameToSave: "popupWords" },
  { collection: "KZ_uiWords", nameToSave: "uiWords" },
  { collection: "KZ_HistoryData", nameToSave: "historyData" },
  { collection: "KZ_StorybookSentence", nameToSave: "storybookSentence" },
  { collection: "KZ_StorybookVocab", nameToSave: "storybookVocab" },
];

const ParamsToFindGame = [
  { data: "category", param: "unit" },
  { data: "target", param: "game-name" },
  { data: "displayGameType", param: "game-type"},
];

const NumberDataCategory = "Math";

const BottomColor = "rgba(0, 124, 20, 1.0)";

const VersionNumber = "2.02.00056";

const Credits = {
  credits: [
    "Design Concept by Chaney Bell",
    "Translated by Pat Pierre",
    "Language Edited By Tahini Pete, Melanie Sandoval, Jesse Nenemay, and Rosie Matt",
    "Website created and designed through Native Teaching Aids LLC by Thomas Koefod and Spencer Kittle",
  ],
  links: [
    {
      link: "https://www.nkwusm.com",
      image: "nkwusmLogoFull.png",
    },
    {
      link: "https://www.nativeteachingaids.com",
      image: "ntalogo.png",
    },
  ],
};

const config = {
  AppName,
  Language,
  Tribe,
  FirebaseConfig,
  FilePath,
  SheetsToPull,
  ParamsToFindGame,
  BottomColor,
  NumberDataCategory,
  VersionNumber,
  Credits,
};

export default config;

export {
  AppName,
  Language,
  Tribe,
  FirebaseConfig,
  FilePath,
  SheetsToPull,
  ParamsToFindGame,
  BottomColor,
  NumberDataCategory,
  VersionNumber,
  Credits,
};
