import "./index.scss";
import "../../App.scss";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectReduxSlice, setStats, setEndlessLevel, setEndlessLevelProgress } from "../../Store/store";
import { useLocation } from "react-router-dom";
import { fetchAudio, fetchImage, shuffle, placementArray, getSearchParams, capEveryWord } from "../nonUIFuncs";
import styled from "styled-components";
import PropTypes from 'prop-types';

import PopupWord from "../UI/PopupWord";
import CloseEndless from "../UI/CloseEndless";
import EndEndlessGame from "../UI/EndEndlessGame";
import FloatingNumber from "../UI/FloatingNumber";
import RoundBanner from "../UI/RoundBanner";

let displayArray = [];
let displayArrayDenom = [3, 3];
let currentTarget = {
  App_Audio: "",
  English: "",
  App_Art: "",
};
let imageArray = [];
let gameDOMArray = [];
let correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
let clickedAudio = null;
const ROUND_TIME = 40;
let testNum = 1;
let positionIndex = [];
let showPopup = false;
let playButton = `url(${fetchImage("playButton.png")})`;

let changeDisplayTimeout = null;
let timer = null;
let stepDifficultyTimeout = null;
const Wordless = ({
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  setScreenCover,
  findStars,
  starsObj,
}) => {
  const dispatch = useDispatch();
  const Location = useLocation();
  const Params = getSearchParams(Location);
  const reduxState = useSelector(selectReduxSlice);

  const currentTimeRef = useRef(0);
  const reduxStateRef = useRef(reduxState);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const roundRef = useRef(0);

  const [correctness, setCorrectness] = useState(null);
  const [firstTime, setFirstTime] = useState(true);
  const [chosenPopupWord, setChosenPopupWord] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [display, setDisplay] = useState(!Location.pathname.includes("/endless-mode/") ? "stats" : "game");
  const [mouseClickX, setMouseClickX] = useState(0);
  const [mouseClickY, setMouseClickY] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [round, setRound] = useState(0);

  currentTimeRef.current = currentTime;
  reduxStateRef.current = reduxState;
  let difficulty = !Location.pathname.includes("/endless-mode/")
    ? reduxStateRef.current.difficulty
    : Params.find((d) => d.id === "difficulty").value.toLowerCase();
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  roundRef.current = round;
  let endRoundTimeout = null;

  useEffect(() => {
    if (!Location.pathname.includes("/endless-mode/")) {
      let string = `${Params.find((d) => d.id === "game-type").value} - ${
        Params.find((d) => d.id === "game-name").value
      } - ${reduxStateRef.current.difficulty}`;
      document.title = capEveryWord(string);
      // difficulty = reduxStateRef.current.difficulty;
      timer = setInterval(updateDisplayClock, 1000);
      if (stepDifficultyTimeout !== null) {
        clearTimeout(stepDifficultyTimeout);
      }
      stepDifficultyTimeout = setTimeout(stepDifficulty, 3000);
    } else {
      // difficulty = Params.find(
      //   (d) => d.id === "difficulty"
      // ).value.toLowerCase();
    }
    dispatch(setEndlessLevel(0));
    dispatch(setEndlessLevelProgress(0));
    changeDisplay();
    return () => {
      setDisplay("stats");
      clearInterval(timer);
      clearTimeout(endRoundTimeout);
      setCurrentTime(0);
      // shortTimer = 0;
      setRound(0);
      gameDOMArray = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeNewRound = () => {
    endRoundTimeout = setTimeout(() => {
      if (stepDifficultyTimeout !== null) {
        clearTimeout(stepDifficultyTimeout);
      }
      stepDifficultyTimeout = setTimeout(stepDifficulty, 3000);
      stopRound();
      setDisplay("stats");
    }, ROUND_TIME * 1000);
  };

  const stopRound = () => {
    clearTimeout(changeDisplayTimeout);
    clearTimeout(endRoundTimeout);
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
      clickedAudio = null;
    }
  };

  const updateDisplayClock = () => {
    // shortTimer = shortTimer + 1;
    if (Location?.state?.endless !== true) {
      let testVal = Math.floor(((currentTimeRef.current + 1) / ROUND_TIME) * 100);
      setProgress(testVal >= 100 ? "100%" : `${testVal}%`);
      setCurrentTime(currentTimeRef.current + 1);
    }
  };

  const changeDisplay = () => {
    positionIndex = [];
    let previousTarget = currentTarget;
    setScreenCover(false);
    imageArray = [];
    let shuffled = shuffle(reduxStateRef.current.sortedData);
    currentTarget = shuffled[0];
    if (previousTarget !== undefined) {
      while (previousTarget.English === currentTarget.English) {
        shuffled = shuffle(reduxStateRef.current.sortedData);
        currentTarget = shuffled[0];
      }
    }
    setTimeout(() => playAudio(currentTarget.App_Audio), 1);
    imageArray.push(currentTarget);
    let distractorAmount = 1;
    let fullDistractorArray = shuffled.filter(
      (d) => d.English !== currentTarget.English
    );
    if (difficulty === "easy") {
      displayArray = placementArray(1, 1.5, 3, 3.5, 1, 1);
      displayArrayDenom = [4, 4];
    } else if (difficulty === "medium") {
      displayArray = placementArray(0.5, 1.5, 3.5, 3.5, 1, 1);
      displayArrayDenom = [4, 4];
    } else {
      if (shuffled.length === 8) {
        displayArray = [
          {x: 1.8, y: 1.25},
          {x: 1.3, y: 2.25},
          {x: 1.3, y: 3.25},
          {x: 2.8, y: 1.25},
          {x: 2.3, y: 2.25},
          {x: 2.3, y: 3.25},
          {x: 3.3, y: 2.25},
          {x: 3.3, y: 3.25},
        ];
      } else {
        displayArray = placementArray(1.3, 1.25, 4.3, 3.5, 1, 1);
      }
      displayArrayDenom = [6, 5];
    }
    distractorAmount = displayArray.length - 1;
    let slicedDistractorArray = fullDistractorArray.slice(0, distractorAmount);
    slicedDistractorArray.forEach((d) => imageArray.push(d));
    imageArray = shuffle(imageArray);
    gameDifficultyDisplay();
  };

  const nextEndlessLevel = () => {
    dispatch(setEndlessLevelProgress(0));
    dispatch(setEndlessLevel(reduxStateRef.current.currentLevel + 1));
    let number = document.getElementById("levelNumber");
    number?.classList.add("levelNumber");
    setTimeout(() => number?.classList.remove("levelNumber"), 1200);
    setTimeout(() => setProgress("0%"), 700);
  };

  const checkCorrectness = (e) => {
    let isEndless = Location.pathname.includes("/endless-mode/");
    let clickedObject = gameDOMArray.find(
      (DOMElement) => DOMElement.props.id === e.target.id
    );
    let audioFile =
      reduxStateRef.current.sortedData.find(
        (d) => d.Language === clickedObject.props.language
      )?.App_Audio || "";
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    setTimeout(() => playAudio(audioFile), 1);
    let clickedObjectImg =
      clickedObject.props.children.props.style.backgroundImage;
    let targetImgPath = `url(${fetchImage(currentTarget.App_Art)})`;
    if (targetImgPath === clickedObjectImg) {

      if (isEndless) {
        console.log(reduxStateRef.current.currentLevelProgress);
        let current = reduxStateRef.current.currentLevelProgress + 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        console.log(current, '/', denom);
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      }

      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      changeDisplayTimeout = setTimeout(changeDisplay, 2000);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = reduxStateRef.current.currentLevelProgress + 1;
      dispatch(setEndlessLevelProgress(progress))
      if (
        reduxStateRef.current.currentLevel + 2 === progress && isEndless
      ) {
        setTimeout(nextEndlessLevel, 700);
      }
      setScreenCover(true);
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness("correct");
      setTimeout(() => setScreenCover(false), 2000);
    } else {
      if (isEndless && reduxStateRef.current.currentLevelProgress > 0) {
        let current = reduxStateRef.current.currentLevelProgress - 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      }
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      clickedAudio.onEnded = () => playAudio(currentTarget.App_Audio);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (reduxStateRef.current.currentLevelProgress > 0) {
        dispatch(setEndlessLevelProgress(reduxStateRef.current.currentLevelProgress - 1));
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness("wrong");
    }
    setTimeout(() => setCorrectness(null), 1000);
    let removedElement = gameDOMArray.find((DOMElement, index) => {
      if (DOMElement.props.id === e.target.id) {
        let newIndex = index;
        positionIndex.forEach((position) => {
          if (position === newIndex || positionIndex.length < newIndex) {
            newIndex = index + 1;
          }
        });
        positionIndex.push(newIndex);
      }
      return DOMElement.props.id === e.target.id;
    });
    gameDOMArray = gameDOMArray.filter(
      (DOMElement) => DOMElement.props.id !== e.target.id
    );
    imageArray = imageArray.filter(
      (d) => d.Language !== removedElement.props.Language
    );
  };

  const stepDifficulty = () => {
    initializeNewRound();
    setProgress("0%");
    setDisplay("game");
    setCurrentTime(0);
    if (roundRef.current < 3) {
      timer = setInterval(updateDisplayClock, 1000);
      setRound(roundRef.current + 1);
      changeDisplay();
    } else {
      checkWorld();
    }
  };

  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    imageArray.forEach((d) => {
      let imgDimm = reduxStateRef.current.width / 6;
      let buffer = 15;
      if (reduxStateRef.current.width > reduxStateRef.current.height) {
        imgDimm = reduxStateRef.current.height / 6;
      }
      if (difficulty !== "hard") {
        if (imgDimm < 50) {
          imgDimm = 50;
        } else if (imgDimm > 375) {
          imgDimm = 375;
        }
      } else {
        imgDimm = reduxStateRef.current.width / 8;
        if (reduxStateRef.current.width > reduxStateRef.current.height) {
          imgDimm = reduxStateRef.current.height / 8;
        }
        if (imgDimm < 35) {
          imgDimm = 35;
        } else if (imgDimm > 300) {
          imgDimm = 300;
        }
      }
      positionIndex.forEach((pos) => {
        while (key === pos) {
          key = pos + 1;
        }
      });
      let xyCoord = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let divStyle = {
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            (imgDimm + buffer) / 2,
          width: imgDimm + buffer,
          height: imgDimm + buffer,
        };
        let contentStyle = {
          backgroundImage: `url(${fetchImage(d.App_Art)})`,
          height: imgDimm,
          width: imgDimm,
        };
        gameDOMArray.push(
          <div
            language={d.Language}
            key={key}
            id={`${key}`}
            style={divStyle}
            className="wordless_vocabcontainer"
          >
            <div
              language={d.Language}
              id={`${key}`}
              style={contentStyle}
              onClick={checkCorrectness}
              className="wordless_vocab"
            />
          </div>
        );
      }
    });
  };

  const playAudio = (file) => {
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
      clickedAudio = null;
    }
    try {
      clickedAudio = new Audio(fetchAudio(file));
      clickedAudio.play();
    } catch (e) {
      console.log(file, e);
    }
  };

  if (display === "stats") {
    clearInterval(timer);
    if (Location.pathname.includes("/endless-mode/")) {
      setDisplay("game");
    }
  } else {
    if (testNum !== reduxState.height * reduxState.width) {
      testNum = reduxState.height * reduxState.width;
      gameDifficultyDisplay();
    }
  }

  let innerImg = {
    backgroundImage: playButton,
  };
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${mouseClickYRef.current}px - 18vh);
    left: ${mouseClickXRef.current}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div className="wordless_fullscreen">
      {children}
      {display === "stats" ? (
        <RoundBanner
          round={roundRef.current + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className="centerContentContainer">
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <div
            id="shakeBox"
            onClick={() => playAudio(currentTarget.App_Audio)}
            className={
              difficulty === "hard"
                ? "wordless_keywordbox_hard shakebutton"
                : "wordless_keywordbox shakebutton"
            }
          >
            <div className="wordless_innerimg" style={innerImg} />
          </div>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {gameDOMArray}
          {Location.pathname.includes("/endless-mode/") ? (
            <div>
              <CloseEndless
                enabled={Location.pathname.includes("/endless-mode/")}
                onClick={setShowEndlessEnd}
              />
              <EndEndlessGame
                enabled={showEndlessEnd}
                currentLevel={reduxStateRef.current.currentLevel}
                setShowEndlessEnd={setShowEndlessEnd}
                updateLevelData={updateLevelData}
                endGame={undefined}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Wordless;

Wordless.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
  children: PropTypes.object,
  setProgress: PropTypes.func,
  setScreenCover: PropTypes.func,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};