import "./index.scss";
import "../../App.scss";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectReduxSlice, setStats, setEndlessLevel, setEndlessLevelProgress } from "../../Store/store";
import { useLocation } from "react-router-dom";
import {
  fetchAudio,
  fetchImage,
  shuffle,
  placementArray,
  compare,
  getSearchParams,
  capEveryWord,
} from "../nonUIFuncs";
import styled from "styled-components";
import PropTypes from 'prop-types';

import PopupWord from "../UI/PopupWord";
import CloseEndless from "../UI/CloseEndless";
import EndEndlessGame from "../UI/EndEndlessGame";
import FloatingNumber from "../UI/FloatingNumber";
import RoundBanner from "../UI/RoundBanner";

let difficultyVar = "easy";
let displayedClock = 0;
let displayArray = [];
let displayArrayDenom = [3, 3];
let imageArray = [];
let shortTimer = 0;
let correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
let subCatAudio = [];
let clickedAudio = null;
let switchDisplayVar = "stats";
let testNum = 1;
let positionIndex = [];
let showPopup = false;
let currentIndex = 0;
let currentTargetAudio = null;
let audioDOM = [];
let round = 0;
let ready = false;
let gameDOMArray = [];
let currentTarget = {
  English: "",
  amount: 1,
  Language: "",
  LanguageNumber: "",
  App_Audio: "",
};
let roundData = [
  {
    English: "",
    amount: 1,
    Language: "",
    LanguageNumber: "",
    App_Audio: "",
  },
];
let keywordString = "";
let keyword = "";
let howMany = "";

const Counting = ({
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  findStars,
  starsObj,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);

  const clickedArrayRef = useRef([]);
  const reduxStateRef = useRef(reduxState);

  const [correctness, setCorrectness] = useState(null);
  const [clickedArray, setClickedArray] = useState([]);
  const [chosenPopupWord, setChosenPopupWord] = useState("");
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);

  clickedArrayRef.current = clickedArray;
  reduxStateRef.current = reduxState;

  useEffect(() => {
    if (!Location.pathname.includes("/endless-mode/")) {
      let string = `${Params.find((d) => d.id === "game-type").value} - ${
        Params.find((d) => d.id === "game-name").value
      } - ${reduxStateRef.current.difficulty}`;
      document.title = capEveryWord(string);
      difficultyVar = reduxState.difficulty;
      switchDisplayVar = "stats";
    } else {
      switchDisplayVar = "game";
      difficultyVar = Params.find(
        (d) => d.id === "difficulty"
      ).value.toLowerCase();
    }
    
    let timer = setInterval(updateDisplayedClock, 1000);
    dispatch(setEndlessLevel(0));
    dispatch(setEndlessLevelProgress(0));

    difficultyVar = reduxStateRef.current.difficulty;
    ready = true;
    return () => {
      switchDisplayVar = "stats";
      difficultyVar = "easy";
      clearInterval(timer);
      displayedClock = 0;
      gameDOMArray = [];
      shortTimer = 0;
      round = 0;
    };
  }, []);
  const updateDisplayedClock = () => {
    if (!Location.pathname.includes("/endless-mode/")) {
      displayedClock = displayedClock + 1;
    }
    shortTimer = shortTimer + 1;
  };
  const changeDisplay = () => {
    currentIndex = 0;
    let sliceNumber = 4;
    let iterations = 6;
    if (difficultyVar === "medium") {
      sliceNumber = 6;
      iterations = 8;
    } else if (difficultyVar === "hard") {
      sliceNumber = 8;
      iterations = 10;
    } else {
      positionIndex = [];
    }
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
    }
    let shuffled = shuffle(reduxStateRef.current.sortedData).slice(
      0,
      sliceNumber
    );
    imageArray = [];
    for (let i = 0; i < iterations; i++) {
      imageArray.push(...shuffled);
    }
    let tall = reduxStateRef.current.height > reduxStateRef.current.width;
    if (difficultyVar === "easy") {
      displayArray = placementArray(0.45, 1.1, 4.25, 3.9, 1, 0.8);
      displayArrayDenom = [5, 5];
      if (tall) {
        displayArray = placementArray(0.45, 1.5, 4.45, 4.5, 1, 0.8);
        displayArrayDenom = [5, 6];
      }
    } else if (difficultyVar === "medium") {
      displayArray = placementArray(0.45, 1.2, 5.65, 4.85, 0.9, 0.75);
      displayArrayDenom = [7, 6];
      if (tall) {
        displayArray = placementArray(0.55, 1.5, 4.55, 6, 0.9, 0.7);
        displayArrayDenom = [6, 8];
      }
    } else {
      displayArray = placementArray(0.67, 1.5, 6.87, 5.7, 0.795, 0.7);
      displayArrayDenom = [9, 7];
      if (tall) {
        displayArray = placementArray(0.45, 1.8, 5.85, 7.7, 0.9, 0.75);
        displayArrayDenom = [7, 10];
      }
    }
    shuffleData();
  };
  const shuffleData = () => {
    let numberData = [...reduxStateRef.current.numberData].sort((a, b) =>
      compare(a.English, b.English)
    );
    imageArray = shuffle(imageArray);
    roundData = [];
    let englishArray = [];
    imageArray = imageArray.slice(0, displayArray.length);
    let uniqueKey = 0;
    imageArray = imageArray.map((d) => {
      if (englishArray.includes(d.English)) {
        roundData = roundData.map((key) => {
          if (key.English === d.English) {
            let newKey = {
              ...key,
              amount: key.amount + 1,
              LanguageNumber: numberData[key.amount + 1].Language,
            };
            return newKey;
          } else {
            return key;
          }
        });
      } else {
        englishArray.push(d.English);
        roundData.push({
          English: d.English,
          amount: 1,
          Language: d.Language,
          LanguageNumber: numberData[1].Language,
          App_Audio: d.App_Audio,
        });
      }
      uniqueKey = uniqueKey + 1;
      let newDatum = {
        ...d,
        newKey: `${d.English}${uniqueKey}`,
      };
      return newDatum;
    });
    currentTarget = roundData[currentIndex];
    keyword = `${roundData[currentIndex].Language}`;
    howMany = `${roundData[currentIndex].LanguageNumber}`;
    currentTargetAudio = new Audio(
      `${fetchAudio(roundData[currentIndex].App_Audio)}`
    );
    playAudioWithNumber(currentTargetAudio);
    gameDifficultyDisplay();
  };
  const playAudioWithNumber = (currentTargetAudio) => {
    let numberData = reduxStateRef.current.numberData.find(
      (d) => Number(d.English) === roundData[currentIndex].amount
    );
    let numberAudio = new Audio(`${fetchAudio(numberData.App_Audio)}`);
    currentTargetAudio.play();
    currentTargetAudio.onended = () => {
      if (numberData.App_Audio.length > 0) {
        numberAudio.play();
      }
    };
  };
  const nextEndlessLevel = () => {
    dispatch(setEndlessLevel(reduxStateRef.current.currentLevel + 1));
    dispatch(setEndlessLevelProgress(0));
    let number = document.getElementById("levelNumber");
    number?.classList.add("levelNumber");
    setTimeout(() => number?.classList.remove("levelNumber"), 1200);
    setTimeout(() => setProgress("0%"), 700);
  };
  const checkCorrectness = () => {
    let isEndless = Location.pathname.includes("/endless-mode/");
    let correct = "incorrect";
    let i = 0;
    if (clickedArrayRef.current.length === currentTarget.amount) {
      clickedArrayRef.current.forEach((string) => {
        if (string.includes(currentTarget.English)) {
          i = i + 1;
        }
      });
    }
    if (i === currentTarget.amount) {
      correct = "correct";
    }
    if (correct === "correct") {
      if (isEndless !== false) {
        let current = reduxStateRef.current.currentLevelProgress + 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      } else {
        // TODO: Figure out how to fill the progress bar. try "gameDOMArray"
      }
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      correctSoundEffect.play();
      let progress = reduxStateRef.current.currentLevelProgress + 1;
      dispatch(setEndlessLevelProgress(progress));

      if (reduxStateRef.current.currentLevel + 2 === progress && isEndless) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness("correct");
      gameDOMArray = gameDOMArray.filter(
        (datum) =>
          !datum.props.id.includes(currentTarget.English) // TODO: Might be the wrong path, check here if erroring
      );
      let newIndex = currentIndex + 1;
      let newTarget = roundData[newIndex];
      currentIndex = newIndex;
      currentTarget = newTarget;
      if (roundData[currentIndex] !== undefined) {
        keyword = `${roundData[currentIndex].Language}`;
        howMany = `${roundData[currentIndex].LanguageNumber}`;
      }
      if (newTarget === undefined) {
        if (Location.pathname.includes("/endless-mode/")) {
          clickedArrayRef.current.forEach((string) => {
            let element = document.getElementById(string);
            element?.classList.remove("counting_clicked");
            element?.classList.add("counting_notclicked");
            setClickedArray([]);
          });
          changeDisplay();
        }
      } else {
        currentTargetAudio = new Audio(`${fetchAudio(newTarget.App_Audio)}`);
        playAudioWithNumber(currentTargetAudio);
      }
      setClickedArray([]);
    } else {
      if (isEndless && reduxStateRef.current.currentLevelProgress > 0) {
        let current = reduxStateRef.current.currentLevelProgress - 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      }
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (reduxStateRef.current.currentLevelProgress > 0) {
        dispatch(
          setEndlessLevelProgress(
            reduxStateRef.current.currentLevelProgress - 1
          )
        );
      }
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness("wrong");
      clickedArrayRef.current.forEach((string) => {
        let element = document.getElementById(string);
        element?.classList.remove("counting_clicked");
        element?.classList.add("counting_notclicked");
        setClickedArray([]);
      });
    }
    setTimeout(() => setCorrectness(null), 1000);
  };
  const stepDifficulty = () => {
    setProgress("0%");
    ready = false;
    switchDisplayVar = "game";
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
    }
    if (round < 3) {
      round = round + 1;
      changeDisplay();
    } else {
      checkWorld();
    }
  };
  const adjustElements = () => {
    let newArray = [...gameDOMArray];
    let imgDimm = reduxStateRef.current.width / 10;
    if (difficultyVar === "hard") {
      imgDimm = reduxStateRef.current.width / 13;
    }
    let buffer = 15;
    if (reduxStateRef.current.width > reduxStateRef.current.height) {
      imgDimm = reduxStateRef.current.height / 10;
      if (difficultyVar === "hard") {
        imgDimm = reduxStateRef.current.height / 13;
      }
    }
    if (imgDimm < 50 && difficultyVar !== "hard") {
      imgDimm = 50;
    } else if (imgDimm > 375) {
      imgDimm = 375;
    }
    newArray = newArray.map((element) => {
      let coords = element.props.children.props["data-xycoord"];
      let container = {
        top:
          (reduxStateRef.current.height * coords.y) / displayArrayDenom[1] -
          (imgDimm + buffer) / 2,
        left:
          (reduxStateRef.current.width * coords.x) / displayArrayDenom[0] -
          (imgDimm + buffer) / 2,
      };
      return (
        <div
          key={element.key}
          id={element.props.id}
          style={container}
          className={element.props.className}
        >
          <div
            {...element.props.children.props}
            style={{
              ...element.props.children.props.style,
              width: imgDimm,
              height: imgDimm,
            }}
          />
        </div>
      );
    });
    gameDOMArray = newArray;
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    let imgDimm = reduxStateRef.current.width / 10;
    if (difficultyVar === "hard") {
      imgDimm = reduxStateRef.current.width / 13;
    }
    let buffer = 15;
    if (reduxStateRef.current.width > reduxStateRef.current.height) {
      imgDimm = reduxStateRef.current.height / 10;
      if (difficultyVar === "hard") {
        imgDimm = reduxStateRef.current.height / 13;
      }
    }
    if (imgDimm < 50 && difficultyVar !== "hard") {
      imgDimm = 50;
    } else if (imgDimm > 375) {
      imgDimm = 375;
    }
    imageArray = shuffle(imageArray);
    let arr = [];
    imageArray.forEach((d) => {
      positionIndex.forEach((pos) => {
        while (key === pos) {
          key = pos + 1;
        }
      });
      let xyCoord = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let image = {
          backgroundImage: `url(${fetchImage(d.App_Art)})`,
          height: imgDimm,
          width: imgDimm,
        };
        let container = {
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            (imgDimm + buffer) / 2,
        };
        arr.push(
          <div
            id={d.newKey}
            key={key}
            style={container}
            className="counting_notclicked"
          >
            <div
              data-xycoord={xyCoord}
              className="counting_item"
              style={image}
              onClick={() => editClicked(d.newKey)}
            />
          </div>
        );
      }
    });
    gameDOMArray = arr;
    ready = true;
  };
  const editClicked = (clickedKey) => {
    let element = document.getElementById(clickedKey);
    if (clickedArrayRef.current.includes(clickedKey)) {
      element?.classList.remove("counting_clicked");
      element?.classList.add("counting_notclicked");
      setClickedArray(
        clickedArrayRef.current.filter((key) => key !== clickedKey)
      );
    } else {
      element?.classList.add("counting_clicked");
      element?.classList.remove("counting_notclicked");
      setClickedArray([...clickedArrayRef.current, clickedKey]);
    }
  };
  if (switchDisplayVar === "stats") {
    if (Location.pathname.includes("/endless-mode/")) {
      ready = false;
      switchDisplayVar = "game";
      changeDisplay();
    }
    if (shortTimer > 1) {
      stepDifficulty();
    }
  } else {
    if (
      testNum !==
      reduxStateRef.current.height * reduxStateRef.current.width
    ) {
      testNum = reduxStateRef.current.height * reduxStateRef.current.width;
      adjustElements();
    }
    if (gameDOMArray.length === 0 && ready === true) {
      shortTimer = 0;
      switchDisplayVar = "stats";
      if (clickedAudio !== null) {
        clickedAudio.pause();
        clickedAudio.currentTime = 0;
      }
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${reduxStateRef.current.height / 2}px - 18vh);
    left: ${reduxStateRef.current.width / 2}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div className="counting_fullscreen">
      {children}
      {switchDisplayVar === "stats" ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className="centerContentContainer">
          {audioDOM}
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {gameDOMArray || null}
          {Location.pathname.includes("/endless-mode/") ? (
            <div>
              <CloseEndless
                enabled={Location.pathname.includes("/endless-mode/")}
                onClick={setShowEndlessEnd}
              />
              <EndEndlessGame
                enabled={showEndlessEnd}
                currentLevel={reduxStateRef.current.currentLevel}
                setShowEndlessEnd={setShowEndlessEnd}
                updateLevelData={updateLevelData}
                endGame={undefined}
              />
            </div>
          ) : null}
          {keyword.length !== 0 && howMany.length !== 0 ? (
            <div
              className="topcontainer counting_centercontainer"
              onClick={() => playAudioWithNumber(currentTargetAudio)}
            >
              <div className="counting_topcontainertext">{keyword}</div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  checkCorrectness();
                }}
                className="counting_checkbutton"
              >
                Check
              </div>
              <div className="counting_topcontainertext">{howMany}</div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Counting;

Counting.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
  children: PropTypes.object,
  setProgress: PropTypes.func,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};