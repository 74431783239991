import '../index.scss';
import '../CategorySelect/index.scss';
import React from "react";
import PropTypes from "prop-types";

const ReadyToStart = (props) => {
  if (!props.categories || props.categories.length > 0) {
    return (
      <div className="EM-RTS-overlayScreen">
        <div className="EMRTS-box EM-RTS-popupWindow">
          <div className="EMRTS-contentcontainer">{props.children}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="EM-RTS-overlayScreen">
        <div className="EMRTS-box EM-RTS-popupWindow">
          <div
            className="EMRTS-contentcontainer"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "70%",
            }}
          >
            <div>
              <div className="EM-CS-RTS-titletext">
                There are no selected categories.
              </div>
              <div className="EM-CS-RTS-titletext">
                Please select at least one category.
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div
                className="EM-CS-button-selectcats"
                onClick={props.resetCategories}
              >
                Select Categories
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default ReadyToStart;
ReadyToStart.propTypes = {
  categories: PropTypes.array,
  resetCategories: PropTypes.func,
  children: PropTypes.element,
};
