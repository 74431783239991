import "./index.scss";
import "../../../App.scss";
import PropTypes from 'prop-types';

const PopupWord = ({ enabled, popupWord }) => {
  if (enabled) {
    return (
      <div className="popUpWord-Container popupWord-shakeBox">
        <p className="popupWord-keyword">{popupWord}</p>
      </div>
    );
  } else {
    return null;
  }
};

export default PopupWord;

PopupWord.propTypes = {
  enabled: PropTypes.bool,
  popupWord: PropTypes.string,
};