import "./index.scss";
import "../../App.scss";

import React, {useState, useEffect, useRef} from "react";
import { selectReduxSlice, setStats } from "../../Store/store";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { fetchAudio, fetchImage, shuffle, getSearchParams, capEveryWord } from "../nonUIFuncs";
import styled from "styled-components";
import PropTypes from "prop-types";

import PopupWord from "../UI/PopupWord";
import CloseEndless from "../UI/CloseEndless";
import EndEndlessGame from "../UI/EndEndlessGame";
import FloatingNumber from "../UI/FloatingNumber";
import RoundBanner from "../UI/RoundBanner";

let audioDOM = [];
let audioFile = null;
let correctSoundEffect = null;
let difficultyData = [];
let difficultyVar = "easy";
let fullTime = 0;
let imageDOMArray = [];
let imageIndex = [];
let normalColor = "rgba(202, 176, 133, 1)";
let ready = false;
let round = 0;
let selectedColor = "rgba(150, 200, 255, 1)";
let shortTimer = 0;
let showPopup = false;
let switchDisplayVar = "stats";
let testNum = 0;
let timeout = false;

const VerbActivity = ({
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  setScreenCover,
  findStars,
  starsObj,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const routeLocation = useLocation();
  const Params = getSearchParams(routeLocation);
  const reduxStateRef = useRef(reduxState);
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const [correctness, setCorrectness] = useState(null);
  const [chosenPopupWord, setChosenPopupWord] = useState("");
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [mouseClickX, setMouseClickX] = useState(0);
  const [mouseClickY, setMouseClickY] = useState(0);

  reduxStateRef.current = reduxState;
  currentLevelRef.current = currentLevel;
  currentLevelProgressRef.current = currentLevelProgress;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  useEffect(() => {
    let string = `${Params.find((d) => d.id === "game-type").value} - ${Params.find((d) => d.id === 'game-name').value} - easy`;
    document.title = capEveryWord(string);
    let timer = setInterval(updateDisplayedClock, 1000);
    setCurrentLevel(0);
    difficultyVar = reduxState.difficulty;
    fullTime = 0;
    switchDisplayVar = "stats";
    createAudio();
    changeDisplay(); // TODO: Write this function
    correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
    ready = true;
    return () => {
      if (audioFile !== null) {
        audioFile.pause();
        audioFile.currentTime = 0;
        audioFile = null;
      }
      fullTime = 0;
      switchDisplayVar = "stats";
      difficultyVar = "easy";
      round = 0;
      clearInterval(timer);
      imageDOMArray = [];
      resetStats();
      setCorrectness(null);
    };
  }, []);
  const updateDisplayedClock = () => {
    shortTimer = shortTimer + 1;
  };
  const resetStats = () => {
    shortTimer = 0;
    timeout = false;
    switchDisplayVar = "game";
  };
  const getRandomCategoryData = (data) => {
    let random = Math.floor(Math.random() * data.length);
    let questionCategory = data[random]["Verb Name"];
    let questionData = data.filter(
      (d) => d["Verb Name"] === questionCategory
    );
    return questionData;
  };
  const changeDisplay = () => {
    imageIndex = [];
    setScreenCover(false);
    let questionData;

    // if (difficultyVar === "easy") {
      questionData = getRandomCategoryData(reduxState.sortedData);
      if (questionData.length < 4) {
        let removedArr = reduxState.sortedData.filter((d) => d !== questionData[0]["Verb Name"]);;
        let dataToAdd = getRandomCategoryData(removedArr);
        questionData = [
          ...questionData,
          ...dataToAdd,
        ];
      }
      let rand = Math.floor(Math.random() * 4);
      let shuffled = shuffle(questionData);
      let slicedData = shuffled.slice(0, 4);
      difficultyData = slicedData.map((d, ind) => {

        return { ...d, correct: ind === rand ? true : false };
      });
    // } else if (difficultyVar === "medium") {
    //   console.log("this should not happen yet"); // TODO: Add in this difficulty later
    // } else {
    //   console.log("this should not happen yet"); // TODO: Add in this difficulty later
    // }
    gameDifficultyDisplay(); // TODO: Create this function next
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number?.classList.add("levelNumber");
    setTimeout(() => number?.classList.remove("levelNumber"), 1200);
  };
  const checkCorrectness = (e, d, index) => {
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    if (d.correct) {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === 'correct'
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (
        currentLevelRef.current + 2 === progress
        && Location?.state?.endless === true
      ) {
        setTimeout(nextEndlessLevel, 700); // TODO: bring in this function
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness("correct");
      changeDisplay();
    } else {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === 'incorrect'
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness("wrong");
    }
    setTimeout(() => setCorrectness(null), 1000);
    imageDOMArray = imageDOMArray.filter(
      (d) => d.props.id !== e.target.id
    );
  };
  const stepDifficulty = () => {
    setProgress("0%");
    ready = false;
    switchDisplayVar = "game";
    if (round < 3) {
      round = round + 1;
      changeDisplay();
    } else {
      checkWorld(fullTime);
    }
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    imageDOMArray = [];
    difficultyData.forEach((d, index) => {
      let divStyle = {
        backgroundColor: normalColor,
        backgroundImage: `url(${fetchImage(d.App_Art)})`,
      };
      let container = {};
      if (key % 2 !== 0) {
        container.justifyContent = 'flex-end'
      }
      key = key + 1;
      imageDOMArray.push(
        <div
          key={key}
          className={key < 3 ? "va_imageclick_top" : "va_imageclick_bottom"}
          style={container}>
          <div
            onClick={(e) => checkCorrectness(e, d, index)}
            id={`${key}`}
            style={divStyle}
            className="va_imageclick"
          />
        </div>
      );
      ready = true;
    });
  };
  const createAudio = () => {
    audioDOM = reduxState.sortedData.map((d) => {
      if (d.App_Audio.length === 0) {
        return null;
      }
      return (
        <audio
          id={d.App_Audio}
          key={`${d.App_Audio}${d.Key}`}
          src={`${fetchAudio(d.App_Audio)}`}
        />
      );
    });
  };
  if (switchDisplayVar === 'stats') {
    if (routeLocation?.state?.endless ? routeLocation.state.endless : false) {
      ready = false;
      switchDisplayVar = 'game';
      changeDisplay();
      return null;
    }
    if (shortTimer > 1) {
      dispatch(
        setStats({
          roundStats: {
            score: 0,
            hits: 0,
            misses: 0,
          }
        })
      )
      stepDifficulty();
    }
    if (audioFile !== null) {
      audioFile.pause();
      audioFile.currentTime = 0;
      audioFile = null;
    }
  } else {
    if (testNum !== reduxState.width * reduxState.height) {
      testNum = reduxState.width * reduxState.height;
      gameDifficultyDisplay();
    }
    if (reduxStateRef.current.stats.roundStats.score >= 10 && ready === true) {
      setTimeout(() => {
        shortTimer = 0;
        switchDisplayVar = 'stats';
      }, 2000);
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${mouseClickYRef.current}px - 18vh);
    left: ${mouseClickXRef.current}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  let vmin = reduxStateRef.current.width > reduxStateRef.current.height
    ? reduxStateRef.current.height
    : reduxStateRef.current.width;
  let centerDim = 0.35 * vmin;
  if (centerDim < 100) {
    centerDim = 100;
  } else if (centerDim > 350) {
    centerDim = 350;
  }
  let correctAnswer = difficultyData.find((d) => d.correct);
  let centerContainer = {
    width: centerDim,
    height: centerDim,
    borderRadius: centerDim,
  };
  let centerTextFontSize = 20;
  if (correctAnswer !== undefined) {
    centerTextFontSize = (centerDim * 1.9) / correctAnswer.Language.length;
    if (centerTextFontSize < 8) {
      centerTextFontSize = 8;
    } else if (centerTextFontSize > 40) {
      centerTextFontSize = 40
    }
  } else {
    console.log('THERE IS NO CORRECT ANSWER');
  }
  return (
    <div className="va_fullscreen">
      {children}
      {switchDisplayVar === "stats" ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className="centerContentContainer">
          <div style={centerContainer} className="va_centercontainer">
            {difficultyData.length > 0 ? (
              <div
                style={{fontSize: centerTextFontSize}}
                className="va_centertext">
                <div>{correctAnswer.English}</div>
                <div>{correctAnswer.Language}</div>
              </div>
            ) : null}
          </div>
          {audioDOM}
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          <div className="va_imageclickcontainer">{imageDOMArray}</div>
          <CloseEndless
            enabled={
              routeLocation?.state?.endless
                ? routeLocation.state.endless
                : false
            }
            onClick={setShowEndlessEnd}
          />
          <EndEndlessGame
            enabled={showEndlessEnd}
            currentLevel={currentLevel}
            setShowEndlessEnd={setShowEndlessEnd}
            updateLevelData={updateLevelData}
            endGame={undefined}
          />
        </div>
      )}
    </div>
  );
};

export default VerbActivity;

VerbActivity.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
  children: PropTypes.object,
  setProgress: PropTypes.func,
  setScreenCover: PropTypes.func,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};