import "./index.scss";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DragDropContext } from "react-beautiful-dnd";

import { StartGame, GameEnds } from "./StartGame";
import Table from "./Table";
import { shuffle, fetchImage } from "../nonUIFuncs";
import PopupWord from "../UI/PopupWord";
import FloatingNumber from "../UI/FloatingNumber";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";

// let formattedData = {
// 	cards:{},
// 	cardLocations: {},
// 	cardLocationOrder: [],
// };
let switchDisplayVar = "loading";
let ready = false;
let remainingCards = [];
let removedCards = [];
let usableData = [];
let newCardID = 0;
let correct = false;
let draggedId = undefined;
let showPopup = false;

const DisplayDroppables = (props) => {
  let cardOptions = props.formattedData.cardLocationOrder.map(
    (cardLocationId) => {
      let cardLocation = props.formattedData.cardLocations[cardLocationId];
      let content = cardLocation.contentId.map(
        (cardId) => props.formattedData.cards[cardId]
      );
      content = content.filter((datum) => datum !== undefined);
      return (
        <Table
          content={content}
          cardLocation={cardLocation}
          drag={props.drag}
          key={cardLocation.id}
        />
      );
    }
  );
  return cardOptions;
};

const SwitchDisplay = (props) => {
  if (switchDisplayVar === "loading") {
    return (
      <div className="timeline-loading-screen">
        <div className="timeline-loading-container">
          <div className="timeline-loading-text">Loading...</div>
        </div>
      </div>
    );
  }
  if (switchDisplayVar === "game") {
    return (
      <div className="centerContentContainer">
        <div className="timeline-plus-minus-container">
          <FloatingNumber correctness={props.correctness} />
        </div>
        {ready ? (
          <DisplayDroppables
            drag={props.drag}
            formattedData={props.formattedData}
            setFormattedData={props.setFormattedData}
          />
        ) : null}
        <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
      </div>
    );
  } else {
    return null;
  }
};

const HistoryTimeline = (props) => {
  let setDiv;
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const formattedDataRef = useRef();
  const reduxStatsRef = useRef();
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [correctness, setCorrectness] = useState(null);
  const [drag, setDrag] = useState(false);
  const [startGame, setStartGame] = useState("start");
  const [formattedData, setFormattedData] = useState({
    cards: {},
    cardLocations: {},
    cardLocationOrder: [],
  });
  formattedDataRef.current = formattedData;
  reduxStatsRef.current = reduxState.stats;
  useEffect(() => {
    gatherData();
    return () => {
      clearTimeout(setDiv);
      setCorrectness(null);
    };
  }, []);

  const gatherData = () => {
    removedCards = [];
    switchDisplayVar = "game";
    usableData = reduxState.historyData.filter(
      (datum) => Number(datum.App) === 1
    );
    usableData = usableData.filter((datum) => datum.data !== "");
    usableData = usableData.map((datum) => {
      if (isNaN(datum.startDate)) {
        if (datum.startDate === "Creation") {
          return {
            ...datum,
            startDate: -9999,
            endDate: -9999,
          };
          // datum.startDate = -9999;
          // datum.endDate = -9999;
        } else if (datum.startDate === "Time Immemorial") {
          // datum.startDate = -9999;
          // datum.endDate = 9999;
          return {
            ...datum,
            startDate: -9999,
            endDate: 9999,
          };
        } else if (datum.startDate === "July 1855") {
          // datum.startDate = 1855;
          // datum.endDate = 1855;
          return {
            ...datum,
            startDate: 1855,
            endDate: 1855,
          };
        } else if (datum.startDate === "October 1855") {
          // datum.startDate = 1855;
          // datum.endDate = 1855;
          return {
            ...datum,
            startDate: 1855,
            endDate: 1855,
          };
        } else {
          return datum;
        }
      } else {
        return datum;
      }
    });
    let shuffledData = shuffle(usableData);
    let cardsToRemove = 5;
    let i;
    for (i = 0; i < cardsToRemove; i++) {
      let newDatum = shuffledData[0];
      shuffledData = shuffledData.filter((datum) => datum.Key !== newDatum.Key);
      removedCards.push(newDatum);
    }
    remainingCards = shuffledData;

    // -------- SPECIFIC CARDS FOR TESTING ONLY -------------
    // removedCards = [];
    // let arr = ["Creation", "Time Immemorial", "July 1855", "July 1855", "October 1855"];
    // let staticData = usableData;
    // const func = (str) => {
    //   let item = staticData.find((d) => d.Date === str);
    //   removedCards.push(item);
    //   staticData.filter((d) => d.Key !== item.Key);
    // }
    // arr.forEach((d) => func(d));
    // console.log(removedCards);
    // -------- SPECIFIC CARDS FOR TESTING ONLY -------------
    formatData();
  };

  const formatData = () => {
    formattedDataRef.current.cardLocations = {
      table: {
        id: "table",
        title: "Table",
        contentId: [],
      },
      hand: {
        id: "hand",
        title: "Your Hand",
        contentId: [],
      },
    };
    formattedDataRef.current.cardLocationOrder = ["table", "hand"];
    let i = 0;
    removedCards.forEach((datum) => {
      formattedDataRef.current.cards = {
        ...formattedDataRef.current.cards,
        [`cardDraggable${i}`]: {
          id: `cardDraggable${i}`,
          content: datum.data,
          date: datum.Date,
          startDate: datum.startDate,
          endDate: datum.endDate,
        },
      };
      formattedDataRef.current.cardLocations.hand.contentId.push(
        `cardDraggable${i}`
      );
      i = i + 1;
    });
    ready = true;
  };
  const onDragEnd = (result) => {
    setDrag(false);
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let begin = formattedDataRef.current.cardLocations[source.droppableId];
    let end = formattedDataRef.current.cardLocations[destination.droppableId];
    if (begin === end) {
      let beginContent = Array.from(begin.contentId);
      beginContent.splice(source.index, 1);
      beginContent.splice(destination.index, 0, draggableId);
      const newBegin = {
        ...begin,
        contentId: beginContent,
      };
      const newFormattedData = {
        ...formattedDataRef.current,
        cardLocations: {
          ...formattedDataRef.current.cardLocations,
          [newBegin.id]: newBegin,
        },
      };
      setFormattedData(newFormattedData);
      return;
    }
    let beginContent = Array.from(begin.contentId);
    beginContent.splice(source.index, 1);
    const newBegin = {
      ...begin,
      contentId: beginContent,
    };
    let endContent = Array.from(end.contentId);
    endContent.splice(destination.index, 0, draggableId);
    draggedId = draggableId;
    const newEnd = {
      ...end,
      contentId: endContent,
    };
    const newFormattedData = {
      ...formattedDataRef.current,
      cardLocations: {
        ...formattedDataRef.current.cardLocations,
        [newBegin.id]: newBegin,
        [newEnd.id]: newEnd,
      },
    };
    setFormattedData(newFormattedData);
    setTimeout(() => {
      checkDate(newEnd, draggableId);
    }, 750);
  };
  const selectNewCard = () => {
    delete formattedDataRef.current.cards[draggedId];
    let tableContent = formattedDataRef.current.cardLocations.table.contentId;
    tableContent.splice(tableContent.indexOf(draggedId), 1);
    formattedDataRef.current.cardLocations.table.contentId = tableContent;
    newCardID = newCardID + 1;
    let newCardData = remainingCards[0];
    remainingCards = remainingCards.filter(
      (datum) => datum.Key !== newCardData.Key
    );
    formattedDataRef.current.cards = {
      ...formattedDataRef.current.cards,
      [`newCard${newCardID}`]: {
        id: `newCard${newCardID}`,
        content: newCardData.data,
        date: newCardData.Date,
        startDate: newCardData.startDate,
        endDate: newCardData.endDate,
      },
    };
    formattedDataRef.current.cardLocations.hand.contentId.push(
      `newCard${newCardID}`
    );
  };
  const checkDate = (newEnd, draggableId) => {
    let draggedCardDates = {};
    let leftCardDates = {};
    let rightCardDates = {};
    let draggedCardIndex = newEnd.contentId.indexOf(draggableId);

    let existingCards = {
      ...newEnd,
      contentId: newEnd.contentId.filter(
        (datum) => formattedDataRef.current.cards[datum] !== undefined
      ),
    };
    draggedCardDates = {
      start: Number(formattedDataRef.current.cards[draggableId].startDate),
      end: Number(formattedDataRef.current.cards[draggableId].endDate),
    };

    let leftCard =
      formattedDataRef.current.cards[
        existingCards.contentId[draggedCardIndex - 1]
      ];
    if (leftCard !== undefined) {
      leftCardDates = {
        start: Number(leftCard.startDate),
        end: Number(leftCard.endDate),
      };
    }
    let rightCard =
      formattedDataRef.current.cards[
        existingCards.contentId[draggedCardIndex + 1]
      ];
    if (rightCard !== undefined) {
      rightCardDates = {
        start: Number(rightCard.startDate),
        end: Number(rightCard.endDate),
      };
    }
    //Create Arrays for start dates on all cards to left and end dates on all cards to the right
    let startDates = [];
    let i = 0;
    for (i = 0; i < draggedCardIndex; i++) {
      startDates.push(
        Number(
          formattedDataRef.current.cards[existingCards.contentId[i]].startDate
        )
      );
    }
    let endDates = [];
    let j = 0;
    for (j = draggedCardIndex + 1; j < existingCards.contentId.length; j++) {
      endDates.push(
        Number(
          formattedDataRef.current.cards[existingCards.contentId[j]].endDate
        )
      );
    }
    // Loop through each dates array to determine correctness
    let incorrect = false;
    startDates.forEach((year) => {
      if (year > draggedCardDates.end) {
        incorrect = true;
      }
    });
    endDates.forEach((year) => {
      if (year < draggedCardDates.start) {
        incorrect = true;
      }
    });
    // By adding in the startDates and endDates arrays above some of the below logic is redundant, this should be simplified
    if (incorrect === false) {
      // The first card dropped will always be correct because there are no cards to compare it to.
      if (existingCards.contentId.length === 1) {
        correct = true;
        // Do check on cards in past here
      } else if (existingCards.contentId.length === draggedCardIndex + 1) {
        if (
          draggedCardDates.end >= leftCardDates.start ||
          draggedCardDates.end === 9999
        ) {
          correct = true;
        } else {
          correct = false;
        }
        //Do check on cards in future here
      } else if (draggedCardIndex === 0) {
        if (
          draggedCardDates.start <= rightCardDates.end ||
          draggedCardDates.start === -9999
        ) {
          correct = true;
        } else {
          correct = false;
        }
        // do both checks here
      } else {
        if (
          (draggedCardDates.end >= leftCardDates.start &&
            draggedCardDates.start <= rightCardDates.end) ||
          (draggedCardDates.start === -9999 && draggedCardDates.end === 9999)
        ) {
          correct = true;
        } else {
          correct = false;
        }
      }
    } else {
      correct = false;
    }
    if (correct) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness("correct");
      dispatch(
        setStats({
          hits: reduxStatsRef.current.hits + 1,
          score: reduxStatsRef.current.score + 1,
          roundStats: {
            hits: reduxStatsRef.current.roundStats.hits + 1,
            score: reduxStatsRef.current.roundStats.score + 1,
          },
        })
      );
      if (formattedDataRef.current.cardLocations.hand.contentId.length === 0) {
        clearTimeout(setDiv);
        // ready = false;
        remainingCards = [];
        removedCards = [];
        usableData = [];
        // newCardID = 0;
        // correct = false;
        // draggedId = undefined;
        // showPopup = false;
        setStartGame("end");
        gatherData();
        // props.checkWorld();
      }
    } else {
      selectNewCard();
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness("wrong");
      dispatch(
        setStats({
          misses: reduxStatsRef.current.misses + 1,
          score: reduxStatsRef.current.score - 1,
          roundStats: {
            misses: reduxStatsRef.current.roundStats.misses + 1,
            score: reduxStatsRef.current.roundStats.score - 1,
          },
        })
      );
    }
    setDiv = setTimeout(() => setCorrectness(null), 1000);
  };

  return (
    <div
      className="timeline-fullscreen-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <DragDropContext onDragStart={() => setDrag(true)} onDragEnd={onDragEnd}>
        {startGame === "game" ? (
          <div
            id="fullScreen"
            className="fullScreen HG-fullScreen overflow-y: hidden;">
            <SwitchDisplay
              chosenPopupWord={chosenPopupWord}
              correctness={correctness}
              drag={drag}
              formattedData={formattedDataRef.current}
              roundStats={reduxStatsRef.current.roundStats}
              setFormattedData={setFormattedData}
              time={props.time}
              uiWords={reduxState.uiWords}
            />
          </div>
        ) : startGame === "start" ? (
          <StartGame setStartGame={setStartGame} />
        ) : <GameEnds setStartGame={setStartGame} /> }
      </DragDropContext>
    </div>
  );
};

export default HistoryTimeline;

HistoryTimeline.propTypes = {
  checkWorld: PropTypes.func,
  time: PropTypes.number,
};
SwitchDisplay.propTypes = {
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  drag: PropTypes.bool,
  formattedData: PropTypes.object,
  roundStats: PropTypes.object,
  setFormattedData: PropTypes.func,
  time: PropTypes.number,
  uiWords: PropTypes.array,
};
DisplayDroppables.propTypes = {
  drag: PropTypes.bool,
  formattedData: PropTypes.object,
  setFormattedData: PropTypes.func,
};

{
  /* Checked PropTypes */
}
