import "./index.scss";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faForward,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { fetchImage, fetchAudio } from "../nonUIFuncs";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";

let sentenceAudio = null;
let vocabAudioArray = [];
let vocabAudio = null;

const StoryGame = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  const backgroundRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [storyPages, setStoryPages] = useState([]);
  const [background, setBackground] = useState(null);
  backgroundRef.current = background;
  useEffect(() => {
    getPages();
    return () => {
      // if (sentenceAudio !== null) {
      //   sentenceAudio.pause();
      //   sentenceAudio.currentTime = 0;
      //   sentenceAudio = null;
      // }
      // if (vocabAudio !== null) {
      //   vocabAudio[1].pause();
      //   vocabAudio[1].currentTime = 0;
      //   vocabAudio = null;
      // }
    };
  }, []);

  const findPage = (page) => {
    return storyPages.find((d) => d.page === page);
  };
  const getSentenceAudio = (pageIdx, pages) => {
    let str = `${fetchAudio(pages.find((d) => d.page === pageIdx).App_Audio).replaceAll(' ', '%20')}`
    return new Audio(str);
  };
  const getPages = () => {
    let pages = reduxState.storybookSentence.filter((d) => d.story === reduxState.sortedData[0].storyGame);
    setStoryPages(pages);
    setBackground(`url(${fetchImage(pages.find((d) => d.page === 1).App_Art)})`);
    sentenceAudio = getSentenceAudio(pageNumber, pages);
    sentenceAudio.play();
    props.setProgress(`${Math.round((1 / pages.length) * 100)}%`);
  };
  const nextPage = () => {
    sentenceAudio.pause();
    let newPage = pageNumber + 1;
    setPageNumber(newPage);
    if (pageNumber > storyPages.length - 1) {
      props.findStars(true);
      props.checkWorld();
    } else {
      sentenceAudio = getSentenceAudio(newPage, storyPages);
      setBackground(`url(${fetchImage(findPage(newPage).App_Art)})`);
      sentenceAudio.play();
      props.setProgress(`${Math.round((newPage / storyPages.length) * 100)}%`);
    }
  };
  const previousPage = () => {
    sentenceAudio.pause();
    let newPage = pageNumber - 1;
    setPageNumber(newPage);
    if (newPage === 0) {
      props.backToStart();
    } else {
      sentenceAudio = getSentenceAudio(newPage, storyPages);
      setBackground(`url(${fetchImage(findPage(newPage).App_Art)})`);
      sentenceAudio.play();
      props.setProgress(`${Math.round((newPage / storyPages.length) * 100)}%`);
    }
  };
  const _playVocabAudio = (audio) => {
    if (sentenceAudio !== null) {
      sentenceAudio.pause();
      sentenceAudio.currentTime = 0;
    }
    if (vocabAudio !== null) {
      vocabAudio[1].pause();
      vocabAudio[1].currentTime = 0;
      vocabAudio = null;
    }
    vocabAudio = vocabAudioArray.find((d) => d[0] === audio);
    vocabAudio[1].play();
  };
  const _playSentenceAudio = () => {
    if (sentenceAudio !== null) {
      sentenceAudio.pause();
      sentenceAudio.currentTime = 0;
    }
    if (vocabAudio !== null) {
      vocabAudio[1].pause();
      vocabAudio[1].currentTime = 0;
      vocabAudio = null;
    }
    sentenceAudio.play();
  };
  const vocabDisplay = () => {
    vocabAudioArray = [];
    let pageVocab = reduxState.sortedData.filter(
      (d) => d.page.toString() === pageNumber.toString()
    );
    let i = 0;
    let vocabDOM = pageVocab.map((d) => {
      i = i + 1;
      vocabAudioArray.push([
        d.App_Audio,
        new Audio(`${fetchAudio(d.App_Audio)}`),
      ]);
      let fontSize = 30;
      if (d.Language.length > 20) {
        fontSize = 13;
      } else if (d.Language.length > 9) {
        fontSize = 24;
      }
      return (
        <div
          className="storyGame-outerBox storyGame-headerText SG-vocab"
          style={{fontSize: fontSize}}
          onClick={_playVocabAudio.bind(null, d.App_Audio)}
          key={i}>
          {d.Language}&nbsp;<FontAwesomeIcon icon={faVolumeUp} />
        </div>
      );
    });
    return vocabDOM;
  };
  return (
    <div
      style={{ backgroundImage: backgroundRef.current }}
      className="SG-fullScreen">
      <div className="SG-topDisplay">
        <div
          onClick={_playSentenceAudio}
          className="storyGame-outerBox storyGame-sentenceContainer">
          {storyPages.length > 0 ? (
            <div className="storyGame-headerText SG-sentence">
              {findPage(pageNumber).fullSentence}{" "}
              <FontAwesomeIcon icon={faVolumeUp} />
            </div>
          ) : null}
        </div>
        <div className="SG-topDisplayInner">
          <div className="storyGame-topBar">
            <div
              onClick={previousPage}
              className="storyGame-outerBox storyGame-topBarContent">
              <FontAwesomeIcon icon={faBackward} />
            </div>
            <div className="storyGame-pageNumber storyGame-topBarContent">
              {pageNumber}
            </div>
            <div
              onClick={nextPage}
              className="storyGame-outerBox storyGame-topBarContent">
              <FontAwesomeIcon icon={faForward} />
            </div>
          </div>
          <div>{vocabDisplay()}</div>
        </div>
      </div>
    </div>
  );
};

export default StoryGame;

StoryGame.propTypes = {
  backToStart: PropTypes.func,
  checkWorld: PropTypes.func,
  storySpecificVocab: PropTypes.array,
};
