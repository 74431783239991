import "../index.scss";
import React from "react";
import PropTypes from "prop-types";

const StartGame = ({setStartGame}) => {
  return (
    <div className="start-game-container">
      <div
        onClick={() => setStartGame("game")}
        className="start-game-button hovercursor">
        <div className="badaBoom start-game-button-text">Play!</div>
      </div>
    </div>
  );
};

const GameEnds = ({setStartGame}) => {
  return (
    <div className="start-game-container" style={{ flexDirection: "column" }}>
      <div className="game-ends-title-container">
        <div className="game-ends-title badaBoom">You Won!!</div>
      </div>
      <div
        onClick={() => setStartGame("start")}
        className="start-game-button hovercursor"
      >
        <div className="badaBoom start-game-button-text">Play Again?</div>
      </div>
    </div>
  );
};

export { StartGame, GameEnds };

StartGame.propTypes = {
  setStartGame: PropTypes.func,
};