import "./index.scss";
import "../index.scss";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// import GameSelectButton from "../../GameSelectButton";
import ReadyToStart from "../ReadyToStart";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { fetchImage } from "../../nonUIFuncs";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../../Store/store";

const DisplayMultiCategories = (props) => {
  let multiCategoryArray = props.levelData.filter(
    (datum) => datum.spreadGameType === props.game
  );
  let compressGame = props.game.replace(/ /g, "");
  multiCategoryArray = multiCategoryArray.filter(
    (datum) => datum.gameType !== compressGame
  );
  multiCategoryArray = multiCategoryArray.filter(
    (datum) => datum.gameType !== "WhackAMole"
  );
  let gameTypeArray = [];
  let finalMultiCategoryArray = multiCategoryArray.map((datum) => {
    if (!gameTypeArray.includes(datum.gameType)) {
      gameTypeArray.push(datum.gameType);
      return datum;
    }
  });
  finalMultiCategoryArray = finalMultiCategoryArray.filter(
    (d) => d !== undefined
  );
  let multiCatDOM = finalMultiCategoryArray.map((datum) => {
    return (
      <div key={datum.gameType} className="EM-CS-content">
        <div className="EM-CS-title">{datum.gameType}</div>
        <div className="EM-CS-innerContainer">
          {datum.levelEasy === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-easyCirc">E</div>Lvl {datum.levelEasy}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-easyCircFull">
                <div className="EM-CS-categoryContainer">
                  {datum.categoryEasy.map((string) => (
                    <div key={string}>{string}</div>
                  ))}
                </div>
                <div>E: Lvl {datum.levelEasy}</div>
              </div>
            </div>
          )}
          {datum.levelMedium === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-mediumCirc">M</div>Lvl {datum.levelMedium}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-mediumCircFull">
                <div className="EM-CS-categoryContainer">
                  {datum.categoryMedium.map((string) => (
                    <div key={string}>{string}</div>
                  ))}
                </div>
                <div>M: Lvl {datum.levelMedium}</div>
              </div>
            </div>
          )}
          {datum.levelHard === 0 ? (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-hardCirc">H</div>Lvl {datum.levelHard}
            </div>
          ) : (
            <div className="EM-CS-circContainer">
              <div className="EM-CS-hardCircFull">
                <div className="EM-CS-categoryContainer">
                  {datum.categoryHard.map((string) => (
                    <div key={string}>{string}</div>
                  ))}
                </div>
                <div>H: Lvl {datum.levelHard}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });
  return <div className="EM-CS-container">{multiCatDOM}</div>;
};

const ButtonContainer = (props) => {
  let levelInfo = {};
  props.levelData.map((datum) => {
    if (datum.spreadGameType === props.game) {
      let categories = datum.category;
      if (categories.length === 1) {
        categories = categories[0];
      }
      if (categories === props.category) {
        levelInfo = datum;
      }
    }
  });
  let categoryImage = {
    backgroundImage: `url(${fetchImage(props.categoryImage)})`,
    backgroundSize: '100% 100%',
    height: 100,
    width: 100,
  };
  return (
    <div className="EM-CS-BC-container">
      {props.children}
      <div className="EM-CS-innerContainer">
        <div style={categoryImage} />
        {levelInfo.levelEasy === 0 ? (
          <div className="EM-CS-circContainer">
            <div className="EM-CS-easyCirc">E</div>
            Lvl {levelInfo.levelEasy}
          </div>
        ) : (
          <div className="EM-CS-circContainer">
            <div className="EM-CS-easyCirc">E: Lvl {levelInfo.levelEasy}</div>
          </div>
        )}
        {levelInfo.levelMedium === 0 ? (
          <div className="EM-CS-circContainer">
            <div className="EM-CS-mediumCirc">M</div>
            Lvl {levelInfo.levelMedium}
          </div>
        ) : (
          <div className="EM-CS-circContainer">
            <div className="EM-CS-mediumCirc">
              M: Lvl {levelInfo.levelMedium}
            </div>
          </div>
        )}
        {levelInfo.levelHard === 0 ? (
          <div className="EM-CS-circContainer">
            <div className="EM-CS-hardCirc">H</div>
            Lvl {levelInfo.levelHard}
          </div>
        ) : (
          <div className="EM-CS-circContainer">
            <div className="EM-CS-hardCirc">H: Lvl {levelInfo.levelHard}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const DisplayCategories = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  const reduxStateRef = useRef(reduxState);
  reduxStateRef.current = reduxState;
  let displayCategories = props.categories.map((datum) => {
    let displayName = datum;
    let findDisplayName = reduxState.data.find(
      (game) => game.category === datum
    );
    let findLessonNumber = reduxStateRef.current.gameData.find((d) => d.homeName === datum);
    let isStory = false;
    if (findLessonNumber === undefined) {
      findLessonNumber = reduxStateRef.current.storybookVocab.find((d) => d.storyGame === datum);
      isStory = true;
    }
    findLessonNumber = findLessonNumber.lesson !== undefined ? findLessonNumber.lesson : findLessonNumber.story;
    let image = isStory
      ? `Category_Selection_Story_${findLessonNumber}.png`
      : `Category_Selection_${findLessonNumber}1.png`;
    if (findDisplayName.categoryLanguage.length > 0) {
      displayName = findDisplayName.categoryLanguage;
    }
    let titleFontSize = 270 / displayName.length;
    if (titleFontSize > 24) {
      titleFontSize = 24;
    } else if (titleFontSize < 12) {
      titleFontSize = 12;
    }
    return (
      <div key={datum}>
        {props.chosenCategories.includes(datum) ? (
          <div
            className="EM-CS-singleButton"
            onClick={() => props.updateCategories(datum)}
            id={datum}
          >
            <ButtonContainer
              levelData={props.levelData}
              game={props.game}
              category={datum}
              categoryImage={image}
            >
              <div className="EM-CS-checkmarkDiv">
                <div className="EM-CS-checkContainer">
                  <FAIcon
                    size={
                      reduxStateRef.current.width > 1300 &&
                      reduxStateRef.current.height > 700
                        ? "3x"
                        : reduxStateRef.current.width < 700 &&
                          reduxStateRef.current.height > 300
                        ? "xl"
                        : "2x"
                    }
                    className="EM-CS-icon"
                    icon={faCircleCheck}
                  />
                </div>
                {/* <GameSelectButton
                  buttonBackground={fetchImage(
                    reduxState.data.find((game) => game.category === datum)
                      .backgroundImg
                  )}
                  parent="gameButtons"
                  uniqueCategoryName={displayName}
                /> */}
                <div className="EM-BC-titlecontainer">
                  <div className="EM-BC-title" style={{fontSize: titleFontSize}}>{displayName}</div>
                </div>
              </div>
            </ButtonContainer>
          </div>
        ) : (
          <div
            className="EM-CS-singleButton"
            onClick={() => props.updateCategories(datum)}
            id={datum}
          >
            <ButtonContainer
              levelData={props.levelData}
              game={props.game}
              category={datum}
              categoryImage={image}
            >
              <div className="EM-BC-titlecontainer">
                <div className="EM-BC-title" style={{fontSize: titleFontSize}}>{displayName}</div>
              </div>
            </ButtonContainer>
          </div>
        )}
      </div>
    );
  });
  return displayCategories;
};
const CategorySelect = (props) => {
  const [shouldSetCategories, setShouldSetCategories] = useState(false);
  const resetCategories = () => {
    props.resetCategories();
    setShouldSetCategories(false);
  };
  return (
    <div className="EM-CS-fullscreen">
      <div className="EM-topBanner">
        <div className="EM-topBanner-inner">
          {props.game}
          <div className="EM-CS-topBannerSub">Choose up to 5 categories</div>
        </div>
      </div>
      <div id="button-container" className="EM-CS-buttonContainer">
        <div
          className="EM-CS-button-goback"
          onClick={() => props.setDisplay("games")}
        >
          Go Back
        </div>
        <div
          className="EM-CS-button-continue"
          onClick={() => setShouldSetCategories(true)}
        >
          Continue
        </div>
      </div>
      {props.chosenCategories.length >= 5 || shouldSetCategories === true ? (
        <ReadyToStart
          categories={props.chosenCategories}
          resetCategories={resetCategories}>
          <div className="EM-CS-RTS-container">
            <div className="EM-CS-RTS-innerContainer">
              <div className="EM-CS-RTS-titletext">Do you want to choose new categories or continue?</div>
              <div className="EM-CS-categoryDisplay">
                {props.chosenCategories.map((datum) => (
                  <div className="EM-CS-RTS-catStringContainer" key={datum}>
                    {datum}
                  </div>
                ))}
              </div>
            </div>
            <div className="EM-CS-RTS-buttonContainer">
              <div className="EM-CS-button-goback" onClick={resetCategories}>
                Reselect
              </div>
              <div
                className="EM-CS-button-continue"
                onClick={() => props.setDisplay("difficulty")}
              >
                Continue
              </div>
            </div>
          </div>
        </ReadyToStart>
      ) : null}
      <div className="EM-container">
        <div className="EM-CS-mainContainer">
          <DisplayMultiCategories
            game={props.game}
            levelData={props.levelData}
          />
          <DisplayCategories
            categories={props.categories}
            chosenCategories={props.chosenCategories}
            game={props.game}
            levelData={props.levelData}
            updateCategories={props.updateCategories}
          />
        </div>
      </div>
    </div>
  );
};

export default CategorySelect;

CategorySelect.propTypes = {
  categories: PropTypes.array,
  chosenCategories: PropTypes.array,
  game: PropTypes.string,
  levelData: PropTypes.array,
  resetCategories: PropTypes.func,
  setDisplay: PropTypes.func,
  updateCategories: PropTypes.func,
};

ButtonContainer.propTypes = {
  category: PropTypes.string,
  children: PropTypes.element,
  game: PropTypes.string,
  levelData: PropTypes.array,
};

DisplayMultiCategories.propTypes = {
  game: PropTypes.string,
  levelData: PropTypes.array,
};

DisplayCategories.propTypes = {
  categories: PropTypes.array,
  chosenCategories: PropTypes.array,
  data: PropTypes.array,
  game: PropTypes.string,
  levelData: PropTypes.array,
  updateCategories: PropTypes.func,
};
