import "./index.scss";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setInitialGameData } from "../../Store/store";
import PlayScreen from "../PlayScreen";
import PlayEndlessScreen from "../PlayEndlessScreen";
import PlayAgainScreen from "../PlayAgainScreen";
import GameContainer from "../GameContainer";
import {
  redirectFromParams,
  findSquishName,
  getSearchParams,
  capEveryWord,
} from "../nonUIFuncs";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "../UI/ProgressBar";
import PropTypes from 'prop-types';

const GameSwitcher = ({
  screen,
  setScreen,
  gameDescription,
  setGameTime,
  gameTime,
  updateLevelData,
  updateScoreData,
  scoreData,
  setProgress,
  scoreType,
  gameTypeImage,
  findStars,
  starsObj,
  game,
  bestScore,
  gameToSave,
  starsToAdd,
  gameStyle,
  config,
}) => {
  switch (screen) {
    case "playScreen":
      return (
        <PlayScreen
          setScreen={setScreen}
          gameDescription={gameDescription}
          scoreData={scoreData}
          gameTypeImage={gameTypeImage}
          config={config}
        />
      );
    case "gameScreen":
      return (
        <GameContainer
          gameTime={gameTime}
          setScreen={setScreen}
          setGameTime={setGameTime}
          updateLevelData={updateLevelData}
          setProgress={setProgress}
          scoreType={scoreType}
          gameTypeImage={gameTypeImage}
          findStars={findStars}
          starsObj={starsObj}
          gameStyle={gameStyle}
        />
      );
    case "playAgainScreen":
      return (
        <PlayAgainScreen
          setScreen={setScreen}
          gameTime={gameTime}
          updateScoreData={updateScoreData}
          scoreType={scoreType}
          gameTypeImage={gameTypeImage}
          starsObj={starsObj}
          game={game}
          bestScore={bestScore}
          gameToSave={gameToSave}
          starsToAdd={starsToAdd}
        />
      );
    case "playEndlessScreen":
      return (
        <PlayEndlessScreen
          setScreen={setScreen}
          gameDescription={gameDescription}
          scoreData={scoreData}
          gameTypeImage={gameTypeImage}
        />
      );
    default:
      return (
        <PlayScreen
          setScreen={setScreen}
          gameDescription={gameDescription}
          scoreData={scoreData}
          gameTypeImage={gameTypeImage}
        />
      );
  }
};

const FullGameContainer = (
  {setShowTopNav, updateLevelData, updateScoreData, scoreData, config}
) => {
  const location = useLocation();
  const reduxStateRef = useRef();
  const gameTimeRef = useRef(0);
  const gameStyleRef = useRef("normal");
  const [screen, setScreen] = useState(location.pathname.includes('/endless-mode/') ? 'playEndlessScreen' : 'playScreen');
  const [gameTime, setGameTime] = useState(0); // This may need a ref to get correct time in playAgainScreen
  const [progress, setProgress] = useState('0%');
  const [gameTypeImage, setGameTypeImage] = useState('');
  const [starOne, setStarOne] = useState(false);
  const [starTwo, setStarTwo] = useState(false);
  const [starThree, setStarThree] = useState(false);
  const [game, setGame] = useState({});
  const [gameStyle, setGameStyle] = useState("normal");
  const reduxState = useSelector(selectReduxSlice);

  const [gameToSave, setGameToSave] = useState({});
  const [starsToAdd, setStarsToAdd] = useState(0);
  const [bestScore, setBestScore] = useState('');

  const dispatch = useDispatch();
  const params = getSearchParams(location);
  const gameTypeParam = params.find((p) => p.id === 'game-type')?.value;
  const unitParam = params.find((p) => p.id === 'unit')?.value;
  let SquishName = findSquishName(gameTypeParam);
  const navigate = useNavigate();
  reduxStateRef.current = reduxState;
  gameTimeRef.current = gameTime;
  gameStyleRef.current = gameStyle
  useEffect(() => {
    let gameImg = params
      .find((p) => p.id === "game-type")
      ?.value.replaceAll("-", "_")
      .replaceAll(" ", "_");
    setGameTypeImage(`Game_Type_${gameImg}2x.png`);
    if (!location.pathname.includes('endless-mode')) {
      let string = `${
        params.find((d) => d.id === "unit").value
      } games - ${gameTypeParam} - ${
        params.find((d) => d.id === "game-name").value
      }`;
      document.title = capEveryWord(string);

      let redirectPath =
        redirectFromParams(["difficulty", "unit", "game-type", "game-name"]) ||
        "";
      if (redirectPath.length > 0) {
        navigate(redirectPath);
      }
      getGameData();
    } else {
      let string = `endless - ${gameTypeParam}`;
      document.title = capEveryWord(string);
      getEndlessData();
    }
    return () => {
      setShowTopNav(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setShowTopNav(screen === 'playScreen' ? true : false);
  }, [screen]);
  const getGameData = () => {
    let filteredArray = [];
    let i = 0;
    const findGame = () => {
      let searchParam = config.ParamsToFindGame[i];
      let arrayToFilterFrom = filteredArray.length === 0 ? reduxStateRef.current.data : filteredArray;
      filteredArray = [];
      arrayToFilterFrom.forEach((d) => {
        if (
          d[searchParam.data] ===
          params.find((p) => p.id === searchParam.param)?.value
        ) {
          filteredArray.push(d);
        }
      });
      if (i < config.ParamsToFindGame.length - 1) {
        i = i + 1;
        findGame();
      }
      return filteredArray[0];
    };
    let selectedGame = findGame();
    let singleGameData = reduxStateRef.current.gameData.filter(
      (d) => d.subCat === selectedGame.target
    );
    if (selectedGame.game.toUpperCase() === "ITEMFALL") {
      setGameStyle("blank");
    }
    if (unitParam.toUpperCase().includes("STORY")) {
      let dSearchKey = 'group';
      let gameSearch = selectedGame.target;
      if (selectedGame.game.toUpperCase() === "FILLINTHEBLANK") {
        setGameStyle("blank");
      } else if (selectedGame.game.toUpperCase() === "STORYBOOK") {
        setGameStyle("blank");
        dSearchKey = 'storyGame';
        gameSearch = selectedGame.category;
      }
      singleGameData = reduxStateRef.current.storybookVocab.filter(
        (d) => d[dSearchKey] === gameSearch
      );
    }

    if (gameTypeParam === "Verb Activity") {
      singleGameData = reduxStateRef.current.verbActivity.filter(
        (d) => d.subCat === selectedGame.target
      );
    }
    dispatch(
      setInitialGameData(
        location,
        reduxStateRef.current.data,
        reduxStateRef.current.gameData,
        gameTypeParam || "Word To Image",
        singleGameData,
        false,
        undefined,
      )
    );
  };
  const getEndlessData = () => {
    let filteredArray = [];
    let i = 0;
    const findGameData = () => {
      let categories = params.find((d) => d.id === 'categories').value.split(',');
      let data = [];
      categories.forEach((d) => {
        let dataToPush = [];
        if (d.toUpperCase().includes('STORY')) {
          dataToPush = reduxStateRef.current.storybookVocab.filter((datum) => datum.storyGame === d); 
        } else {
          dataToPush = reduxStateRef.current.gameData.filter((datum) => datum.homeName === d);
        }
        data = [
          ...data,
          ...dataToPush,
        ];
      });
      return data;
    };
    let selectedGame = reduxStateRef.current.data.find(
      (d) => d.displayGameType === gameTypeParam
    );
    let gameData = findGameData();
    if (selectedGame.game.toUpperCase() === "ITEMFALL") {
      setGameStyle("blank");
    }
    dispatch(
      setInitialGameData(
        location,
        reduxStateRef.current.data,
        reduxStateRef.current.gameData,
        gameTypeParam || "Word To Image",
        gameData,
        true,
        selectedGame,
      )
    );
  };
  const findStars = (time, isStories) => {
    if (isStories) {
      let object = {
        difficulty: reduxStateRef.current.difficulty,
        score: reduxStateRef.current.stats.score,
        time: time,
        correct: reduxStateRef.current.stats.hits,
        incorrect: reduxStateRef.current.stats.misses,
        Key: reduxStateRef.current.specificGame.Key,
        stars: 9,
        firstStars: 9,
        firstNextGoals: null,
        secondStars: null,
        secondNextGoals: null,
      };
      setStarOne(true);
      setStarTwo(true);
      setStarThree(true);
      setGameToSave(object);
      setStarsToAdd(object.stars - game.stars);
      return;
    } else {
      setGameTime(time);
      let gameInfo = reduxStateRef.current.goldStar.find(
        (d) => d.game === reduxStateRef.current.specificGame.game
      );
      let difficultyGoldStar = {
        oneStar: gameInfo?.oneStarEasy,
        twoStar: gameInfo?.twoStarEasy,
        threeStar: gameInfo?.threeStarEasy,
        oneStarTwo: gameInfo?.oneStarTwoEasy,
        twoStarTwo: gameInfo?.twoStarTwoEasy,
        threeStarTwo: gameInfo?.threeStarTwoEasy,
      };
      if (reduxStateRef.current.difficulty === "medium") {
        difficultyGoldStar = {
          oneStar: gameInfo?.oneStarMedium,
          twoStar: gameInfo?.twoStarMedium,
          threeStar: gameInfo?.threeStarMedium,
          oneStarTwo: gameInfo?.oneStarTwoMedium,
          twoStarTwo: gameInfo?.twoStarTwoMedium,
          threeStarTwo: gameInfo?.threeStarTwoMedium,
        };
      } else if (reduxStateRef.current.difficulty === "hard") {
        difficultyGoldStar = {
          oneStar: gameInfo?.oneStarHard,
          twoStar: gameInfo?.twoStarHard,
          threeStar: gameInfo?.threeStarHard,
          oneStarTwo: gameInfo?.oneStarTwoHard,
          twoStarTwo: gameInfo?.twoStarTwoHard,
          threeStarTwo: gameInfo?.threeStarTwoHard,
        };
      }
      gameInfo = {
        game: gameInfo?.game,
        Key: gameInfo?.Key,
        notImplimented: gameInfo?.notImplimented,
        scoreType: gameInfo?.scoreType,
        ratings: [
          {
            type: gameInfo?.type,
            threeStar: Number(difficultyGoldStar.threeStar),
            twoStar: Number(difficultyGoldStar.twoStar),
            oneStar: Number(difficultyGoldStar.oneStar),
            stars: 0,
            nextGoals: null,
          },
          {
            type: gameInfo?.secondType,
            threeStar: Number(difficultyGoldStar.threeStarTwo),
            twoStar: Number(difficultyGoldStar.twoStarTwo),
            oneStar: Number(difficultyGoldStar.oneStarTwo),
            stars: 0,
            nextGoals: null,
          },
        ],
      };
      gameInfo.ratings = gameInfo?.ratings.filter(d => d.type !== 'none');
      let correctedTime = time === null ? 99999 : time;
      const checkFuncObj = {
        correct: (val) => reduxStateRef.current.stats.score >= val,
        time: (val) => correctedTime <= val,
        incorrect: (val) => reduxStateRef.current.stats.misses <= val,
        accuracy: (val) =>
          reduxStateRef.current.stats.hits /
            (reduxStateRef.current.stats.hits +
              reduxStateRef.current.stats.misses) >=
          val,
      };
      gameInfo.ratings.forEach(d => {
        let checkFunc = checkFuncObj[d.type];
        if (checkFunc(d.threeStar)) {
          d.stars = 3;
        } else if (checkFunc(d.twoStar)) {
          d.stars = 2;
          d.nextGoals = d.threeStar;
        } else if (checkFunc(d.oneStar)) {
          d.stars = 1;
          d.nextGoals = d.twoStar;
        } else {
          d.nextGoals = d.oneStar;
        }
      });
      let object = {
        difficulty: reduxStateRef.current.difficulty,
        score: reduxStateRef.current.stats.score,
        time: time,
        correct: reduxStateRef.current.stats.hits,
        incorrect: reduxStateRef.current.stats.misses,
        Key: reduxStateRef.current.specificGame.Key,
        stars: gameInfo.ratings[0].stars,
        firstStars: gameInfo.ratings[0].stars,
        firstNextGoals: gameInfo.ratings[0].nextGoals,
        secondStars: null,
        secondNextGoals: null,
      };
      let fullGame = scoreData.find(
        (d) => d.Key === reduxStateRef.current.specificGame.Key
      );
      setGame(fullGame.difficultyScores[object.difficulty]);
      if (gameInfo.ratings.length > 1) {
        if (fullGame.secondType !== 'none') {
          object.stars = gameInfo.ratings[0].stars + gameInfo.ratings[1].stars;
          object.secondStars = gameInfo.ratings[1].stars;
          object.secondNextGoals = gameInfo.ratings[1].nextGoals;
        }
      }
      setBestScore(game.score > object.score ? 'previous' : 'current');
      let starNum = Math.floor(
        object.stars /
          (fullGame.secondType === undefined ||
          fullGame.secondType === 'none'
            ? 1
            : 2)
      );
      if (starNum >= 1) {
        setStarOne(true);
      }
      if (starNum >= 2) {
        setStarTwo(true);
      }
      if (starNum >= 3) {
        setStarThree(true);
      }
      setGameToSave(object);
      setStarsToAdd(object.stars - game.stars);
      if (starsToAdd < 0) {
        setStarsToAdd(0);
        setGameToSave(game);
      }
    }
  };
  let fullScreen = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  let gameDesc = reduxStateRef.current.gameDescriptions.find(
    (desc) =>
      desc.game ===
      params.find((p) => p.id === "game-type")?.value.replaceAll("-", " ")
  )?.description;
  if (SquishName === "SolveIt") {
    SquishName = "SolveIt2"
  }
  let scoreType =
    reduxStateRef.current.goldStar.find(gs => gs.game === SquishName).scoreType;
  return (
    <div className='fullgamecontainer_container' style={fullScreen}>
      <GameSwitcher
        updateScoreData={updateScoreData}
        screen={screen}
        setScreen={setScreen}
        gameTime={gameTimeRef.current}
        setGameTime={setGameTime}
        gameDescription={
          gameDesc !== undefined ? gameDesc : 'No Description Found!'
        }
        updateLevelData={updateLevelData}
        scoreData={scoreData}
        setProgress={setProgress}
        scoreType={scoreType}
        gameTypeImage={gameTypeImage}
        findStars={findStars}
        starsObj={{starOne:starOne, starTwo: starTwo, starThree: starThree}}
        game={game}
        bestScore={bestScore}
        gameToSave={gameToSave}
        starsToAdd={starsToAdd}
        params={params}
        gameStyle={gameStyleRef.current}
        config={config}
      />
      {/* Progress Bar / Timer */}
      {screen === 'gameScreen' ? (
        <div className="gamecontainer_progressbar_container">
          <ProgressBar
            width={
              reduxStateRef.current.height > 500
                ? 'clamp(200px, 25vw, 350px)'
                : 'clamp(100px, 12.5vw, 175px)'
            }
            id={`game-screen`}
            progress={progress}
            isTimer={scoreType === 'normal' ? true : false}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FullGameContainer;

GameSwitcher.propTypes = {
  screen: PropTypes.string,
  setScreen: PropTypes.func,
  gameDescription: PropTypes.string,
  setGameTime: PropTypes.func,
  gameTime: PropTypes.number,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
  scoreData: PropTypes.array,
  setProgress: PropTypes.func,
  scoreType: PropTypes.string,
  gameTypeImage: PropTypes.string,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
  game: PropTypes.object,
  bestScore: PropTypes.string,
  gameToSave: PropTypes.object,
  starsToAdd: PropTypes.number,
};

FullGameContainer.propTypes = {
  setShowTopNav: PropTypes.func,
  updateLevelData: PropTypes.func,
  updateScoreData: PropTypes.func,
  scoreData: PropTypes.array,
  config: PropTypes.object,
};