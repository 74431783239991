import { useLocation } from "react-router-dom";
// import config from "../config/RedLake/config";
import config from "../config/Salish/config";
// import config from "../config/FortBelknap/config";
// import config from "../config/Pokagon/config";


const AUDIO_PRE = `${config.FilePath}Audio/`;
const IMAGE_PRE = `${config.FilePath}Image/`;

const fetchImage = (fileName) => {
  return `${IMAGE_PRE}${fileName}`;
};
const fetchAudio = (fileName) => {
  return `${AUDIO_PRE}${fileName}`;
};

const urlParams = new URLSearchParams();
const useLocationParams = (addStr, removeArr) => {
  let params = useLocation().search;
  if (addStr.length !== 0 || removeArr.length !== 0) {
    return `${params}&${addStr}`;
  } else {
    return "";
  }
};

const redirectFromParams = (expectedParams) => {
  // TODO: Redirect from incorrect parameters
  // Must bring in data to know what 'incorrect parameters are
  // console.log(window);
  let params = window.location?.search?.split("&");
  let paramNames = params.map((str) => {
    let newStr = str.replace("?", "");
    return newStr.split("=")[0];
  });
  let missingParams = [];
  expectedParams.forEach(param => {
    if (!paramNames.includes(param)) {
      missingParams.push(param);
    }
  });
  let redirectPath = "";
  if (missingParams.length > 0) {
    if (missingParams.includes("difficulty")) {
      redirectPath = "/";
    } else if (missingParams.includes("unit") || missingParams.includes("game-name")) {
      redirectPath = `/choose-unit${window.location.search}`;
    } else if (missingParams.includes("game-type")) {
      redirectPath = `/choose-game-type${window.location.search}`;
    }
  }
  return redirectPath;
};

const goBack = (location) => {
  const params = getSearchParams(location);
  const unitParam = params.find((d) => d.id === 'unit');
  const locationArr = [
    { Key: 0, pathname: "/", expectedParams: [] },
    {
      Key: 1,
      pathname: "/choose-unit",
      alternate: "/choose-story-unit",
      expectedParams: ["difficulty"],
    },
    {
      Key: 2,
      pathname: "/choose-game-type",
      expectedParams: ["difficulty", "unit", "game-name"],
    },
    {
      Key: 3,
      pathname: "/start-game",
      expectedParams: ["difficulty", "unit", "game-type", "game-name"],
    },
  ];
  let newURL = { pathname: "/", search: "" };
  let newURLKeyObject = locationArr.filter(
    (loc) => loc.pathname === location.pathname
  );
  let newURLKey = newURLKeyObject.length > 0 ? newURLKeyObject[0].Key - 1 : 0;
  if (newURLKeyObject.length === 0) {
    newURL.pathname = "/";
  } else {
    let obj = locationArr.find((loc) => loc.Key === newURLKey);
    if (obj?.pathname === '/choose-unit' && unitParam.value.includes('Story')) {
      newURL.pathname = obj?.alternate ?? "/";
    } else {
      newURL.pathname = obj?.pathname ?? "/";
    }
    newURL.search = filterURLsearch(obj ?? newURLKeyObject[0], location.search);
  }
  return newURL;
};

const filterURLsearch = (location, search) => {
  let searchToReturn = "?";
  if (location.expectedParams.length === 0) {
    searchToReturn = "";
  } else {
    let searchArr = search.replace("?", "").split("&"); // difficulty=1
    location.expectedParams.forEach((param) => {
      searchArr.forEach((str) => {
        if (str.includes(param)) {
          searchToReturn = searchToReturn.concat(`&${str}`);
        }
      });
    });
  }
  return searchToReturn;
};

const getSearchParams = (location) => {
  let paramArray = [];
  let splitLoc = location.search
    .replaceAll("%20", " ")
    .replaceAll("%27", "'")
    .replaceAll("%E2%80%99", "’")
    .replaceAll("%C3%A9", "é")
    .replaceAll("%C3%AB", "ë")
    .replaceAll(" & ", "|*|") // Create unique string to replace later with original content
    .replace("?", "")
    .split("&")
    .filter((s) => s.length > 0);
  splitLoc.forEach((s) => {
    let newS = s.replaceAll("|*|", " & ") // Put back original content while looking for unique string
    newS = newS.split("=");
    let modifier = "";
    if (newS[0] === "unit") {
      if (isNaN(newS[1].slice(0, 2))) {
        modifier = newS[1].slice(0, 1);
        newS[1] = newS[1].slice(1);
      } else {
        modifier = newS[1].slice(0, 2);
        newS[1] = newS[1].slice(2);
      }
    }
    paramArray.push({ id: newS[0], value: newS[1], modifier: modifier });
  });
  return paramArray;
};
const compare = (a, b) => {
  let engA = a.toString().toUpperCase();
  let engB = b.toString().toUpperCase();
  let comparison = 0;
  if (!isNaN(Number(engA)) && !isNaN(Number(engB))) {
    if (Number(engA) > Number(engB)) {
      comparison = 1;
    } else {
      comparison = -1;
    }
  } else {
    if (engA > engB) {
      comparison = 1;
    } else {
      comparison = -1;
    }
  }
  return comparison;
};
const shuffle = (array) => {
  let newArray = [...array];
  let j, x, i;
  for (i = newArray.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = newArray[i];
    newArray[i] = newArray[j];
    newArray[j] = x;
  }
  return newArray;
};
const placementArray = ( xStart, yStart, xEnd, yEnd, xInc, yInc) => {
  let array = [];
  let x;
  let y;
  for (x = xStart; x < xEnd; x = x + xInc) {
    for (y = yStart; y < yEnd; y = y + yInc) {
      array.push({ x: x, y: y });
    }
  }
  return array;
};

const findValueFromClamp = (clampString, max) => {
  let fixedString = clampString
    .replace("clamp(", "")
    .replace(")", "")
    .replace(" ", "")
    .split(",");
  let findNum = /\d+/;
  let minValue = Number(fixedString[0].replace("px", ""));
  let variableValue = Number(fixedString[1].match(findNum)) / 100;
  let maxValue = Number(fixedString[2].replace("px", ""));
  let finalValue = max * variableValue;
  if (finalValue > maxValue) {
    finalValue = maxValue;
  } else if (finalValue < minValue) {
    finalValue = minValue;
  }
  return finalValue;
};

const findSquishName = (string) => {
  let squishName = "";
  if (string !== undefined) {
    squishName = string
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .replaceAll(/[0-9]/g, "");
  }
  return squishName;
};

const capEveryWord = (string) => {
  let wordArray = string.split(" ");
  for (let i = 0; i < wordArray.length; i++) {
    wordArray[i] = wordArray[i][0].toUpperCase() + wordArray[i].substr(1);
  }
  return wordArray.join(" ");
};

const findGameFromConfigAndParams = (data, params) => {
  let filteredGames = [];
  config.ParamsToFindGame.forEach((p) => {
    let arrayToFilter = filteredGames.length > 0 ? filteredGames : data;
    let param = params.find((d) => d.id === p.param);
    let value = param.value;
    let dataToCheck = p.data;
    if (param.id === "game-type") {
      value = value.replaceAll(" ", "").replaceAll("-", "");
      dataToCheck = "gameType";
    }
    arrayToFilter = arrayToFilter.filter((d) => d[dataToCheck] === value);
    filteredGames = arrayToFilter;
  });
  return filteredGames[0];
};

export {
  fetchImage,
  fetchAudio,
  // useURLParamQuery,
  urlParams,
  useLocationParams,
  redirectFromParams,
  goBack,
  compare,
  shuffle,
  placementArray,
  getSearchParams,
  findValueFromClamp,
  findSquishName,
  // findDataset,
  capEveryWord,
  findGameFromConfigAndParams,
};
