import "./index.scss";
import "../../../App.scss";

import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

import { selectReduxSlice } from "../../../Store/store";


const RoundBanner = ({ round, findStars, starsObj }) => {
  const reduxState = useSelector(selectReduxSlice);
  const reduxStateRef = useRef(reduxState);
  reduxStateRef.current = reduxState;
  useEffect(() => {
    findStars();
  }, []);
  let finalText = "You win!";
  if (starsObj.starThree) {
    finalText = "You Win!";
  } else if (starsObj.starTwo) {
    finalText = "So Close!";
  } else if (starsObj.starOne) {
    finalText = "Try Again!";
  }

  return (
    <div className="roundbanner_banner">
      <h1 className="roundbanner_text">
        {round <= 3 ? `Round ${round}` : finalText}
      </h1>
    </div>
  );
};

export default RoundBanner;

RoundBanner.propTypes = {
  round: PropTypes.number,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};