import "./index.scss";
import "../../App.scss";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectReduxSlice, setStats, setEndlessLevel, setEndlessLevelProgress } from "../../Store/store";
import { useLocation } from "react-router-dom";
import {
  fetchAudio,
  fetchImage,
  shuffle,
  placementArray,
  capEveryWord,
  getSearchParams,
} from "../nonUIFuncs";
import styled from "styled-components";
import PropTypes from 'prop-types';

import PopupWord from "../UI/PopupWord";
import CloseEndless from "../UI/CloseEndless";
import EndEndlessGame from "../UI/EndEndlessGame";
import FloatingNumber from "../UI/FloatingNumber";
import RoundBanner from "../UI/RoundBanner";

let displayArray = [];
let displayArrayDenom = [3, 3];
let currentTarget = {
  App_Art: "",
  App_Audio: "",
  English: "",
  Language: "",
};
let imageArray = [];
let gameDOMArray = [];
let targetAudio = undefined;
let correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
let testNum = 0;
let showPopup = false;
const ROUND_TIME = 60;
let shortTimer = 0;

let stepDifficultyTimeout = null;
let timer = null;
const GoodGuess = ({
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  findStars,
  starsObj,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);

  const roundClockRef = useRef(0);
  const reduxStateRef = useRef(reduxState);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const roundRef = useRef(0);

  const [keyword, setKeyword] = useState("");
  const [chosenPopupWord, setChosenPopupWord] = useState("");
  const [display, setDisplay] = useState("stats");
  const [correctness, setCorrectness] = useState(null);
  const [previousTarget, setPreviousTarget] = useState(undefined);
  const [roundClock, setRoundClock] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [mouseClickX, setMouseClickX] = useState(0);
  const [mouseClickY, setMouseClickY] = useState(0);
  const [round, setRound] = useState(0);

  roundClockRef.current = roundClock;
  reduxStateRef.current = reduxState;
  let difficulty = reduxStateRef.current.difficulty;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  roundRef.current = round;
  let changeDisplayTimer = null;

  useEffect(() => {
    
    if (!Location.pathname.includes("/endless-mode/")) {
      let string = `${Params.find((d) => d.id === "game-type").value} - ${
        Params.find((d) => d.id === "game-name").value
      } - ${reduxStateRef.current.difficulty}`;
      document.title = capEveryWord(string);
    } else {
      difficulty = Params.find(
        (d) => d.id === "difficulty"
      ).value.toLowerCase();
    }
    timer = setInterval(updateDisplayedClock, 1000);
    if (stepDifficultyTimeout !== null) {
      clearTimeout(stepDifficultyTimeout);
    }
    stepDifficultyTimeout = setTimeout(stepDifficulty, 3000);
    return () => {
      setDisplay("stats");
      clearInterval(timer);
      clearInterval(changeDisplayTimer);
      setRoundClock(0);
      setRound(0);
      shortTimer = 0;
      if (targetAudio !== undefined) {
        targetAudio?.playableAudio?.pause();
        targetAudio.currentTime = 0;
        targetAudio = undefined;
      }
    };
  }, []);
  const initializeNewRound = () => {
    // To run at the beginning of each round when game screen starts
    // creates respawn timer
    changeDisplayTimer = setInterval(() => {
      if (roundClockRef.current < ROUND_TIME - 1) {
        changeDisplay();
      } else {
        if (stepDifficultyTimeout !== null) {
          clearTimeout(stepDifficultyTimeout);
        }
        stepDifficultyTimeout = setTimeout(stepDifficulty, 3000);
        stopRound();
        setDisplay("stats");
      }
    }, 6000);
  };
  const stopRound = () => {
    // To run at the end of each round when stat screen appears
    // clears intervals and timers
    clearInterval(changeDisplayTimer);
    if (targetAudio !== undefined) {
      targetAudio.pause();
      targetAudio.currentTime = 0;
      targetAudio = undefined;
    }
  };
  const updateDisplayedClock = () => {
    shortTimer = shortTimer + 1;
    
    if (!Location.pathname.includes("/endless-mode/")) {
      let testVal = Math.floor((shortTimer / ROUND_TIME) * 100);
      setProgress(testVal >= 100 ? "100%" : `${testVal}%`);
      setRoundClock(roundClockRef.current + 1);
    }
  };
  const changeDisplay = () => {
    setPreviousTarget(currentTarget);
    imageArray = [];
    let shuffled = shuffle(reduxStateRef.current.sortedData);
    currentTarget = shuffled[0];
    if (previousTarget !== undefined) {
      while (previousTarget.English === currentTarget.English) {
        shuffled = shuffle(reduxStateRef.current.sortedData);
        currentTarget = shuffled[0];
      }
    }
    playTargetAudio();
    setKeyword(currentTarget.Language);
    let correctAmount = 2;
    let i = 0;
    let distracterAmount = 1;
    if (difficulty === "easy") {
      correctAmount = Math.floor(Math.random() * 2) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter(
        (d) => d.English !== currentTarget.English
      );
      displayArray = placementArray(1, 1.5, 3, 3.5, 1, 1);
      displayArrayDenom = [4, 4];
      distracterAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(
        0,
        distracterAmount
      );
      let doubledDistractors = [];
      slicedDistractorArray.forEach((d) => {
        doubledDistractors.push(d);
        doubledDistractors.push(d);
      });
      let shuffledDistractors = shuffle(doubledDistractors);
      let finalDistractors = shuffledDistractors.slice(0, distracterAmount);
      finalDistractors.forEach((d) => imageArray.push(d));
    } else if (difficulty === "medium") {
      correctAmount = Math.floor(Math.random() * 3) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter(
        (d) => d.English !== currentTarget.English
      );
      displayArray = placementArray(0.5, 1.5, 3.5, 3.5, 1, 1);
      displayArrayDenom = [4, 4];
      distracterAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(
        0,
        distracterAmount
      );
      let doubledDistractors = [];
      slicedDistractorArray.forEach((d) => {
        doubledDistractors.push(d);
        doubledDistractors.push(d);
      });
      let shuffledDistractors = shuffle(doubledDistractors);
      let finalDistractors = shuffledDistractors.slice(0, distracterAmount);
      finalDistractors.forEach((d) => imageArray.push(d));
    } else if (difficulty === "hard") {
      correctAmount = Math.floor(Math.random() * 4) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter(
        (d) => d.English !== currentTarget.English
      );
      displayArray = placementArray(1.25, 1.25, 4.25, 3.5, 1, 1);
      displayArrayDenom = [6, 5];
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        displayArray = placementArray(0.5, 1, 3.5, 3.25, 1, 1);
        displayArrayDenom = [4, 5];
      }
      distracterAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(
        0,
        distracterAmount
      );
      let doubledDistractors = [];
      slicedDistractorArray.forEach((d) => {
        doubledDistractors.push(d);
        doubledDistractors.push(d);
      });
      let shuffledDistractors = shuffle(doubledDistractors);
      let finalDistractors = shuffledDistractors.slice(0, distracterAmount);
      finalDistractors.forEach((d) => imageArray.push(d));
    }
    imageArray = shuffle(imageArray);
    gameDifficultyDisplay();
  };
  const nextEndlessLevel = () => {
    dispatch(setEndlessLevel(reduxStateRef.current.currentLevel + 1));
    dispatch(setEndlessLevelProgress(0));
    let number = document.getElementById("levelNumber");
    number?.classList.add("levelNumber");
    setTimeout(() => number?.classList.remove("levelNumber"), 1200);
    setTimeout(() => setProgress("0%"), 700);
  };
  const checkCorrectness = (e) => {
    let isEndless = Location.pathname.includes("/endless-mode/");
    let clickedObject = gameDOMArray.find(
      (DOMElement) => DOMElement.props.id === e.target.id
    );
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    let clickedObjectImg =
      clickedObject.props.children.props.style.backgroundImage;
    let targetImgPath = `url(${fetchImage(currentTarget.App_Art)})`;
    if (targetImgPath === clickedObjectImg) {
      if (isEndless === false) {
        // let progressBarVal = Math.round(
        //   ((imageIndex.length + 1) / (imageDOMArray.length + imageIndex.length)) * 100
        // );
        // setProgress(`${progressBarVal}%`);
      } else {
        let current = reduxStateRef.current.currentLevelProgress + 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      }


      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = reduxStateRef.current.currentLevelProgress + 1;
      dispatch(setEndlessLevelProgress(progress));
      if (reduxStateRef.current.currentLevel + 2 === progress && isEndless) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness("correct");



    } else {


      if (isEndless && reduxStateRef.current.currentLevelProgress > 0) {
        let current = reduxStateRef.current.currentLevelProgress - 1;
        let denom = reduxStateRef.current.currentLevel + 2;
        let progressBarVal = Math.round((current / denom) * 100);
        setProgress(`${progressBarVal}%`);
      }
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      if (reduxStateRef.current.currentLevelProgress > 0) {
        dispatch(
          setEndlessLevelProgress(
            reduxStateRef.current.currentLevelProgress - 1
          )
        );
      }
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness("wrong");



    }
    setTimeout(() => setCorrectness(null), 1000);
    gameDOMArray = gameDOMArray.filter(
      (DOMElement) => DOMElement.props.id !== e.target.id
    );
  };
  const stepDifficulty = () => {
    initializeNewRound();
    setProgress("0%");
    setDisplay("game");
    if (roundRef.current < 3) {
      shortTimer = 0;
      timer = setInterval(updateDisplayedClock, 1000);
      setRoundClock(0);
      setRound(roundRef.current + 1);
      changeDisplay();
    } else {
      checkWorld();
    }
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    imageArray.map((d) => {
      let imgDimm = reduxStateRef.current.width / 6;
      let buffer = 15;
      if (reduxStateRef.current.width > reduxStateRef.current.height) {
        imgDimm = reduxStateRef.current.height / 6;
      }
      if (difficulty !== "hard") {
        if (imgDimm < 50) {
          imgDimm = 50;
        } else if (imgDimm > 375) {
          imgDimm = 375;
        }
      } else {
        imgDimm = reduxStateRef.current.width / 8;
        if (reduxStateRef.current.width > reduxStateRef.current.height) {
          imgDimm = reduxStateRef.current.height / 8;
        }
        if (imgDimm < 35) {
          imgDimm = 35;
        } else if (imgDimm > 300) {
          imgDimm = 300;
        }
      }

      let xyCoord = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let vocabContainer = {
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            (imgDimm + buffer) / 2,
        };
        let vocabImg = {
          backgroundImage: `url(${fetchImage(d.App_Art)})`,
          height: imgDimm,
          width: imgDimm,
        };
        gameDOMArray.push(
          <div
            key={key}
            id={`${key}`}
            style={vocabContainer}
            className="goodguess_vocabcontainer"
          >
            <div
              id={`${key}`}
              style={vocabImg}
              className="goodguess_vocab"
              onClick={checkCorrectness}
            />
          </div>
        );
      }
    });
  };
  const playTargetAudio = () => {
    if (targetAudio !== undefined) {
      targetAudio.pause();
      targetAudio.currentTime = 0;
      targetAudio = undefined;
    }
    if (currentTarget.App_Audio.length > 0) {
      targetAudio = new Audio(
        `${fetchAudio(currentTarget.App_Audio)}`
      );
      targetAudio.play();
    } else {
      console.log("no audio listed for", currentTarget);
    }
  };
  if (display === "stats") {
    clearInterval(timer);
    if (Location.pathname.includes("/endless-mode/")) {
      setDisplay("game");
    }
  } else {
    if (testNum !== reduxState.height * reduxState.width) {
      testNum = reduxState.height * reduxState.width;
      gameDifficultyDisplay();
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${mouseClickYRef.current}px - 10vh);
    left: ${mouseClickXRef.current}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div className="goodguess_fullscreen">
      {children}
      {display === "stats" ? (
        <RoundBanner
          round={roundRef.current + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className="centerContentContainer">
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <div
            className="goodguess_keywordbox"
            onClick={() => playTargetAudio()}
          >
            <div
              className="keyword noselect goodguess_keyword"
              style={{
                fontSize:
                  keyword.length > 21
                    ? "2vmin"
                    : keyword.length > 12
                    ? "3vmin"
                    : "5vmin",
              }}
            >
              {keyword}
            </div>
          </div>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {gameDOMArray}

          {Location.pathname.includes("/endless-mode/") ? (
            <div>
              <CloseEndless
                enabled={Location.pathname.includes("/endless-mode/")}
                onClick={setShowEndlessEnd}
              />
              <EndEndlessGame
                enabled={showEndlessEnd}
                currentLevel={reduxStateRef.current.currentLevel}
                setShowEndlessEnd={setShowEndlessEnd}
                updateLevelData={updateLevelData}
                endGame={undefined}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default GoodGuess;

GoodGuess.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
  children: PropTypes.object,
  setProgress: PropTypes.func,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};