import "./index.scss";
import React, { useEffect } from "react";
import { fetchImage } from "../nonUIFuncs";

const Credits = ({config}) => {
  useEffect(() => {
    document.title = "Kids Zone - Credits";
  }, []);
  return (
    <div className="credits-full-screen">
      <div style={{backgroundColor: 'rgba(255,255,255,0.3)'}}>
        <div className="credits-contentContainer">
          <div className="credits-creditContainer">
            {config.Credits.credits.map((str) => {
              return <div className="credits-headerText">{str}</div>;
            })}
          </div>
          <div className="credits-logosContainer">
            {config.Credits.links.map((d) => {
              return (
                <a href={d.link}>
                  <div className="credits-logoAndSite">
                    <img
                      src={`${fetchImage(d.image)}`}
                      className="credits-logo"
                      alt=""
                    />
                    <div className="credits-link">{d.link}</div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Credits;

{
  /* Checked PropTypes */
}
