
import "./index.scss";

import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import "firebase/auth";
import "firebase/firestore";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import store from './Store';
import {
  selectReduxSlice,
  getData,
  findInitialPlayerLevelOnLoad,
} from "./Store/store";
import App from './App';
import LoadingScreen from './Components/LoadingScreen';
// import config from './config/RedLake/config';
import config from './config/Salish/config';
// import config from './config/FortBelknap/config';
// import config from './config/Pokagon/config';

let scoreData = [];
let checkDataArray = [];
let gameList = [];
let gameTypeDifficulties = [
  { gameType: "Whack-A-Mole", difficulty: 2 },
  { gameType: "Matching", difficulty: 2 },
  { gameType: "Good Guess", difficulty: 1 },
  { gameType: "Word To Image", difficulty: 1 },
  { gameType: "Wordless", difficulty: 3 },
  { gameType: "Bubble Pop", difficulty: 2 },
  { gameType: "Counting", difficulty: 2 },
  { gameType: "Verb Activity", difficulty: 1 },
  { gameType: "Story Book", difficulty: 1 },
  { gameType: "Fill In The Blank", difficulty: 3 },
  { gameType: "Solve It", difficulty: 3 },
  { gameType: "Item Fall", difficulty: 3 },
  { gameType: "Crocodile", difficulty: 1 },
];

const setInitialLevelData = (
  data,
  setInitialLevel,
  levelData,
  setLevelData
) => {
  let newLevelData = levelData;
  if (localStorage[`KZ_${config.Language}_LevelData`] && localStorage[`KZ_${config.Language}_LevelData`].length > 2) {
    newLevelData = JSON.parse(localStorage[`KZ_${config.Language}_LevelData`]);
    if (newLevelData.type === "string") {
      newLevelData = JSON.parse(newLevelData);
    }
  } else {
    data.forEach((game) => {
      if (
        game.game !== "Bingo" &&
        game.game !== "FillInTheBlank" &&
        game.game !== "StoryBook" &&
        game.game !== "Crocodile" &&
        game.game !== "SolveIt2" &&
        game.category !== "Math"
      ) {
        if (!gameList.includes(game.game)) {
          gameList.push(game.game);
          newLevelData.push({
            gameType: "2 Categories",
            spreadGameType: game.displayGameType,
            category: [],
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
          newLevelData.push({
            gameType: "3 Categories",
            spreadGameType: game.displayGameType,
            category: [],
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
          newLevelData.push({
            gameType: "4 Categories",
            spreadGameType: game.displayGameType,
            category: [],
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
          newLevelData.push({
            gameType: "5 Categories",
            spreadGameType: game.displayGameType,
            category: [],
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
        }
        if (!checkDataArray.includes(`${game.game}${game.category}`)) {
          checkDataArray.push(`${game.game}${game.category}`);
          newLevelData.push({
            gameType: game.game,
            spreadGameType: game.displayGameType,
            category: game.category,
            levelEasy: 0,
            levelMedium: 0,
            levelHard: 0,
            categoryEasy: [],
            categoryMedium: [],
            categoryHard: [],
          });
        }
      }
    });
  }
  setLevelData(newLevelData);
  setInitialLevel(true);
};

const setInitialScoreData = (
  data,
  GoldStar,
  setInitialScore,
  dispatch
) => {
  // TODO: check if param "data" length is the same as the localStorage data. If
  // they don't match, games have either been removed or added and the local
  // data must be altered
  let currentStars = 0;
  let totalStars = 0;
  if (localStorage[`KZ_${config.Language}_ScoreData`] && localStorage[`KZ_${config.Language}_ScoreData`].length > 2) {
    scoreData = JSON.parse(localStorage[`KZ_${config.Language}_ScoreData`]);
    if (scoreData.type === "string") {
      scoreData = JSON.parse(scoreData);
    }
    scoreData.forEach((indivGame) => {
      // Finding currentStars //
      let scores = indivGame.difficultyScores;
      let indivGameStars = 0;
      if (scores !== undefined) {
        indivGameStars =
          scores.easy.stars + scores.medium.stars + scores.hard.stars;
      }
      currentStars = currentStars + indivGameStars;
      // Finding totalStars //
      if (indivGame.secondType === "none") {
        totalStars = totalStars + 9;
      } else {
        totalStars = totalStars + 18;
      }
    });
  } else {
    data.forEach((game) => {
      let scoreType = GoldStar.find((type) => type.game === game.game);
      if (scoreType === undefined && game.displayGameType !== "Verb Activity") {
        return;
      }
      let easyDifficulty = gameTypeDifficulties.find(
        (d) => d.gameType === game.displayGameType
      ).difficulty;
      if (scoreType.secondType === "none") {
        totalStars = totalStars + 9;
      } else {
        totalStars = totalStars + 18;
      }
      scoreData.push({
        difficultyScores: {
          easy: {
            difficulty: easyDifficulty,
            correct: 0,
            incorrect: 0,
            score: 0,
            time: 0,
            stars: 0,
            firstStars: 0,
            secondStars: 0,
            firstNextGoals: null,
            secondNextGoals: null,
          },
          medium: {
            difficulty: easyDifficulty + 1,
            correct: 0,
            incorrect: 0,
            score: 0,
            time: 0,
            stars: 0,
            firstStars: 0,
            secondStars: 0,
            firstNextGoals: null,
            secondNextGoals: null,
          },
          hard: {
            difficulty: easyDifficulty + 2,
            correct: 0,
            incorrect: 0,
            score: 0,
            time: 0,
            stars: 0,
            firstStars: 0,
            secondStars: 0,
            firstNextGoals: null,
            secondNextGoals: null,
          },
        },
        Key: game.Key,
        scoreType: scoreType.scoreType,
        category: game.category,
        gameType: game.game,
        gameName: game.displayGameName,
        target: game.target,
        firstType: scoreType.type,
        secondType: scoreType.secondType,
      });
    });
  }
  dispatch(findInitialPlayerLevelOnLoad(currentStars, totalStars));
  setInitialScore(true);
};

const saveData = (score, level) => {
  localStorage.clear();
  localStorage.setItem(
    `KZ_${config.Language}_ScoreData`,
    JSON.stringify(score)
  );
  localStorage.setItem(
    `KZ_${config.Language}_LevelData`,
    JSON.stringify(level)
  );
};

const SendData = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const [initialScore, setInitialScore] = useState(false);
  const [initialLevel, setInitialLevel] = useState(false);
  const [levelData, setLevelData] = useState([]);
  useEffect(() => {
    config.SheetsToPull.forEach((d) => {
      dispatch(getData(d.collection, d.nameToSave));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (reduxState?.firebaseSheetsPulled.length >= config.SheetsToPull.length + 1) {
    if (initialScore === false) {
      setInitialScoreData(
        reduxState.data,
        reduxState.goldStar,
        setInitialScore,
        dispatch,
      );
    }
    if (initialLevel === false) {
      setInitialLevelData(
        reduxState.data,
        setInitialLevel,
        levelData,
        setLevelData
      );
    }
    return (
      <Router>
        <App
          style={{width: '100%', height: '100%', backgroundColor: 'red'}}
          scoreDataProp={scoreData}
          levelDataProp={levelData}
          saveData={saveData}
          config={config}
        />
      </Router>
    );
    } else { 
      return <LoadingScreen />;
    }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <SendData />
  </Provider>
);
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <SendData />
//     </Provider>
//   </React.StrictMode>
// );
