import "./index.scss";
import PropTypes from 'prop-types';

const CloseEndless = ({ onClick, enabled }) => {
  if (enabled) {
    return (
      <div className="playagain_closebutton" onClick={() => onClick(true)} />
    );
  } else {
    return null;
  }
};

export default CloseEndless;

CloseEndless.propTypes = {
  onClick: PropTypes.func,
  enabled: PropTypes.bool,
};