import "./index.scss";
import { useState, useEffect } from "react";
import PropTypes from 'prop-types';

const CardFlip = ({ cardData, flippedCurrent }) => {
  const [flipped, setFlipped] = useState(false);
  useEffect(() => {
    setFlipped(flippedCurrent[cardData.identifier] || false);
  }, [flippedCurrent]);
  let container = {
    height: cardData.targetBodyHeight,
    width: cardData.targetBodyWidth,
    top:
      (cardData.height * cardData.rpY) / cardData.positionArrayDenom[1] -
      cardData.targetBodyHeight / 2 +
      cardData.targetBodyHeight / 4,
    left:
      (cardData.width * cardData.rpX) / cardData.positionArrayDenom[0] -
      cardData.targetBodyWidth / 2,
  };
  let front = {
    height: cardData.targetBodyHeight,
    width: cardData.targetBodyWidth,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: cardData.frontImage,
  };
  let back = {
    ...front,
    backgroundImage: cardData.backImage,
  };
  return (
    <div
      id={`${cardData.identifier}`}
      style={container}
      onClick={(e) =>
        cardData.selectCard(
          e,
          cardData.identifier,
          cardData.backImage,
          cardData.App_Audio,
          cardData.Language
        )
      }
      className={`cardflip_container ${flipped ? "active" : null}`}
    >
      <div className="cardflip_flipper">
        <div style={front} className="cardflip_front" />
        <div style={back} className="cardflip_back" />
      </div>
    </div>
  );
};

export default CardFlip;

CardFlip.propTypes = {
  cardData: PropTypes.object,
  flippedCurrent: PropTypes.object,
};