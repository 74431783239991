import "./index.scss";
import "../../App.scss";

import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats, setEndlessLevel, setEndlessLevelProgress } from "../../Store/store";
import { useLocation } from "react-router-dom";
import { fetchAudio, fetchImage, shuffle, placementArray, getSearchParams, capEveryWord } from "../nonUIFuncs";
import PropTypes from 'prop-types';

import CardFlip from "../UI/CardFlip";
import PopupWord from "../UI/PopupWord";
import CloseEndless from "../UI/CloseEndless";
import EndEndlessGame from "../UI/EndEndlessGame";
import RoundBanner from "../UI/RoundBanner";

const MATCH_ANIMATION_TIMER = 800;
let difficulty = "easy";
let targetBodyHeight = 45;
let targetBodyWidth = 45;
let positionArrayLength = 1;
let cardFlipArray = [];
let cardAudio = null;
let filtered = undefined;
let switchData = 0;
let shortTimer = 0;
let switchDisplayVar = "stats";
let firstImg = null;
let firstId = "";
let firstAudio = "";
let removedCard = [];
let flippedCurrent = {};
let resetWordBox = null;
let testNum = 0;
let showPopup = false;
let correctSoundEffect = new Audio(`${fetchAudio("correctSound.mp3")}`);
let ready = false;
let round = 0;
let audioDOM = [];
let spliceNumber = 0;

const Matching = ({
  checkWorld,
  updateLevelData,
  setProgress,
  children,
  setScreenCover,
  findStars,
  starsObj,
}) => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  const Location = useLocation();
  const Params = getSearchParams(Location);

  const displayedClockRef = useRef(0);
  const reduxStateRef = useRef(reduxState);
  const flippedCurrentRef = useRef(flippedCurrent);

  const [correctMatchWord, setCorrectMatchWord] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState("");
  const [matchedWord, setMatchedWord] = useState("");
  const [displayedClock, setDisplayedClock] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);

  reduxStateRef.current = reduxState;

  // targetBodyHeight = reduxStateRef.current.height / 3.25;
  // targetBodyWidth = targetBodyHeight * 0.643;

  displayedClockRef.current = displayedClock;
  flippedCurrentRef.current = flippedCurrent;

  useEffect(() => {
    if (!Location.pathname.includes("/endless-mode/")) {
      let string = `${Params.find((d) => d.id === "game-type").value} - ${
        Params.find((d) => d.id === "game-name").value
      } - ${reduxStateRef.current.difficulty}`;
      document.title = capEveryWord(string);
      difficulty = reduxStateRef.current.difficulty;
    } else {
      difficulty = Params.find(
        (d) => d.id === "difficulty"
      ).value.toLowerCase();
    }
    let timer = setInterval(updateDisplayClock, 1000);
    dispatch(setEndlessLevel(0));
    dispatch(setEndlessLevelProgress(0));
    createAudio();
    // targetBodyHeight = reduxStateRef.current.height / 3.25;
    // targetBodyWidth = targetBodyHeight * 0.643;
    switchDisplayVar = "stats";
    flippedCurrent = {};
    removedCard = [];
    changeDisplay();
    ready = true;
    return () => {
      clearTimeout(resetWordBox);
      positionArrayLength = null;
      difficulty = "easy";
      switchDisplayVar = "stats";
      switchData = 0;
      round = 0;
      setDisplayedClock(0);
      clearInterval(timer);
      stopAudio();
      cardFlipArray = [];
      firstImg = null;
      firstId = "";
      firstAudio = "";
    };
  }, []);
  const updateDisplayClock = () => {
    setDisplayedClock(displayedClockRef.current + 1);
    shortTimer = shortTimer + 1;
  };
  const changeDisplay = () => { 
    shortTimer = 0;
    switchData = switchData + 1;
    positionArrayLength = null;
    setScreenCover(false);
    setDisplayedClock(0);
    firstImg = null;
    firstId = "";
    firstAudio = "";
    removedCard = [];
    flippedCurrent = {};
    cardFlipArray = [];
    
    let positionArray = [];
    let positionArrayDenom = [];
    if (difficulty === "easy") {
      spliceNumber = 4;
      positionArray = placementArray(0.4, 0.5, 4.4, 2.5, 1, 1);
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 6;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
        positionArrayDenom = [5, 3];
      } else {
        targetBodyHeight = reduxStateRef.current.height / 4;
        targetBodyWidth = targetBodyHeight * 0.643;
        positionArrayDenom = [5, 3];
      }
    } else if (difficulty === "medium") {
      spliceNumber = 6;
      positionArrayDenom = [5, 4];
      positionArray = placementArray(0.4, 0.7, 4.4, 3.1, 1, 0.82);
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 10;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
      } else {
        targetBodyHeight = reduxStateRef.current.height / 5.5;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    } else if (difficulty === "hard") {
      spliceNumber = 8;
      positionArray = placementArray(0.35, 0.7, 4.35, 3.1, 1, 0.8);
      positionArrayDenom = [5, 5];
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 10;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
      } else {
        targetBodyHeight = reduxStateRef.current.height / 8;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    }
    let selected = shuffle(reduxStateRef.current.sortedData).splice(
      0,
      spliceNumber
    );
    let gameData = [...selected];
    let copiedA = selected.map((obj) => {
      return { ...obj, id: obj.id + "copy" };
    });
    gameData.push(...copiedA);
    positionArrayLength = positionArray.length;
    while (gameData.length > 0) {
      let randomPositionNum = Math.floor(Math.random() * positionArray.length);
      let rpX = positionArray[randomPositionNum].x;
      let rpY = positionArray[randomPositionNum].y;
      positionArray.splice(randomPositionNum, 1);
      let randomObjectNum = Math.floor(Math.random() * gameData.length);
      let currentObject = gameData[randomObjectNum];
      gameData.splice(randomObjectNum, 1);
      let targetSprite = `url(${fetchImage(currentObject.App_Art)})`;
      let faceDownImg = `url(${fetchImage("Category_Selection_BG_12x.png")})`;
      cardFlipArray.push(
        (currentObject = {
          Language: currentObject.Language,
          App_Audio: currentObject.App_Audio,
          identifier: `${currentObject.Key}${rpX}${rpY}`,
          targetBodyWidth: targetBodyWidth,
          targetBodyHeight: targetBodyHeight,
          frontImage: faceDownImg,
          backImage: targetSprite,
          height: reduxStateRef.current.height,
          width: reduxStateRef.current.width,
          rpX: rpX,
          rpY: rpY,
          positionArrayDenom: positionArrayDenom,
          selectCard: _selectCard,
          flipped: () => flippedCurrent,
        })
      );
    }
  };
  const editCardFlipArray = () => {
    if (difficulty === "easy") {
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 6;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
      } else {
        targetBodyHeight = reduxStateRef.current.height / 4;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    } else {
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 10;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
      } else {
        if (difficulty === "medium") {
          targetBodyHeight = reduxStateRef.current.height / 5.5;
        } else {
          targetBodyHeight = reduxStateRef.current.height / 8;
        }
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    }
    cardFlipArray.forEach((d) => {
      d.height = reduxStateRef.current.height;
      d.width = reduxStateRef.current.width;
      d.targetBodyHeight = targetBodyHeight;
      d.targetBodyWidth = targetBodyWidth;
    });
  };
  const removeCorrectCards = () => {
    cardFlipArray = filtered;
  };
  const nextEndlessLevel = () => {
    dispatch(setEndlessLevel(reduxStateRef.current.currentLevel + 1));
    dispatch(setEndlessLevelProgress(0));
    let number = document.getElementById("levelNumber");
    number?.classList.add("levelNumber"); // TODO: Add the level number bounce for flare!!!
    setTimeout(() => number?.classList.remove("levelNumber"), 1200);
    setTimeout(() => setProgress("0%"), 700);
  };
  const checkCorrectness = ( id, App_Art, App_Audio, flipped, Language ) => {
    let isEndless = Location.pathname.includes("/endless-mode/");
    if (firstImg !== null && firstId !== id) {
      if (firstImg === App_Art && firstId !== id && firstAudio === App_Audio) {
        if (!isEndless) {
          let progressBarVal = Math.round(
            (((spliceNumber * 2) - (cardFlipArray.length - 2)) /
              (spliceNumber * 2)) *
              100
          );
          setProgress(`${progressBarVal}%`);
        } else {
          let current = reduxStateRef.current.currentLevelProgress + 1;
          let denom = reduxStateRef.current.currentLevel + 2;
          let progressBarVal = Math.round((current / denom) * 100);
          setProgress(`${progressBarVal}%`);
        }
        let popupWords = reduxStateRef.current.popupWords.filter(
          (d) => d.type === "correct"
        );
        let randomPopupNum = Math.floor(Math.random() * popupWords.length);
        showPopup = true;
        setTimeout(() => (showPopup = false), 1300);
        let progress = reduxStateRef.current.currentLevelProgress + 1;
        dispatch(setEndlessLevelProgress(progress))
        if (reduxStateRef.current.currentLevel + 2 === progress && isEndless) {
          setTimeout(nextEndlessLevel, 700);
        }
        correctSoundEffect.play();
        filtered = cardFlipArray.filter((d) => d.App_Audio !== firstAudio);
        flipped[id] = false;
        flipped[firstId] = false;
        setTimeout(() => {
          removeCorrectCards();
          removedCard = [...removedCard, id, firstId];
          if (isEndless && cardFlipArray.length === 0) {
            setTimeout(() => changeDisplay(), 500);
          }
        }, MATCH_ANIMATION_TIMER);
        setMatchedWord(Language);
        setChosenPopupWord(popupWords[randomPopupNum].English);
        setCorrectMatchWord(true);
        resetWordBox = setTimeout(() => setCorrectMatchWord(false), 3000);
        setScreenCover(true);
        setTimeout(() => setScreenCover(false), 1000);
      } else {
        setTimeout(() => (flippedCurrent = {}), 300);
        let popupWords = reduxStateRef.current.popupWords.filter(
          (d) => d.type === "incorrect"
        );
        let randomPopupNum = Math.floor(Math.random() * popupWords.length);
        showPopup = true;
        setTimeout(() => (showPopup = false), 1300);
        flipped[id] = false;
        flipped[firstId] = false;
        setChosenPopupWord(popupWords[randomPopupNum].English);
      }
      if (removedCard.length !== positionArrayLength) {
        firstImg = null;
        firstId = "";
        firstAudio = "";
      }
    }
  };
  const _selectCard = ( e, id, App_Art, App_Audio, Language ) => {
    if (cardAudio !== null) {
      cardAudio.pause();
      cardAudio = null;
    }
    cardAudio = document.getElementById(App_Audio);
    if (App_Audio.length > 0) {
      cardAudio.play();
    }
    let flipped = { ...flippedCurrent };
    flipped[id] = true;
    if (!e.target.matches(".cardflip_back")) {
      if (firstImg === null) {
        firstImg = App_Art;
        firstId = id;
        firstAudio = App_Audio;
      } else if (firstId !== id) {
        setTimeout(
          checkCorrectness,
          1000,
          id,
          App_Art,
          App_Audio,
          flipped,
          Language
        );
      }
      flippedCurrent = flipped;
    }
  };
  const stopAudio = () => {
    if (cardAudio !== null) {
      cardAudio.pause();
      cardAudio = null;
    }
  };
  const setStatDisplay = () => {
    switchDisplayVar = "stats";
    shortTimer = 0;
  };
  const stepDifficulty = () => {
    setProgress("0%");
    ready = false;
    switchDisplayVar = "game";
    setDisplayedClock(0);
    if (round < 3) {
      round = round + 1;
      changeDisplay();
      ready = true;
    } else {
      checkWorld();
    }
  };
  const createAudio = () => {
    audioDOM = reduxState.sortedData.map((d) => {
      if (d.App_Audio.length === 0) {
        return null;
      }
      return (
        <audio
          id={d.App_Audio}
          key={`${d.App_Audio}${d.Key}`}
          src={`${fetchAudio(d.App_Audio)}`}
        />
      );
    })
  };
  if (switchDisplayVar === "stats") {
    if (Location.pathname.includes("/endless-mode/")) {
      switchDisplayVar = "game";
      ready = false;
    } else if (shortTimer > 1) {
      stepDifficulty();
    }
  } else {
    if (
      testNum !==
      reduxStateRef.current.width * reduxStateRef.current.height
    ) {
      testNum = reduxStateRef.current.width * reduxStateRef.current.height;
      editCardFlipArray();
    }
    if (positionArrayLength === removedCard.length && ready === true) {
      setTimeout(setStatDisplay, MATCH_ANIMATION_TIMER);
      shortTimer = 0;
      switchDisplayVar = "stats";
    }
  }
  return (
    <div className="matching_startscreen">
      {children}
      {switchDisplayVar === "stats" ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div>
          {audioDOM}
          {cardFlipArray.map((cd) => {
            return (
              <CardFlip
                key={cd.identifier}
                cardData={cd}
                flippedCurrent={flippedCurrentRef.current}
              />
            );
          })}
          {correctMatchWord ? (
            <div className="matching_keywordbox noselect">
              <div
                className="keyword matching_keywordtext"
                style={{
                  fontSize:
                    matchedWord.length > 21
                      ? "2vmin"
                      : matchedWord.length > 12
                      ? "3vmin"
                      : "5vmin",
                }}
              >
                {matchedWord}
              </div>
            </div>
          ) : null}
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {Location.pathname.includes("/endless-mode/") ? (
            <div>
              <CloseEndless
                enabled={Location?.state?.endless}
                onClick={setShowEndlessEnd}
              />
              <EndEndlessGame
                enabled={showEndlessEnd}
                currentLevel={reduxStateRef.current.currentLevel}
                setShowEndlessEnd={setShowEndlessEnd}
                updateLevelData={updateLevelData}
                endGame={undefined}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Matching;

Matching.propTypes = {
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
  setProgress: PropTypes.func,
  children: PropTypes.object,
  setScreenCover: PropTypes.func,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};