import "./index.scss";
import "../../../App.scss";

import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';

import ProgressBar from "../ProgressBar";
import DifficultyBadge from "../DifficultyBadge";

import {
  selectReduxSlice,
} from "../../../Store/store";
import { fetchImage } from "../../nonUIFuncs";

const BottomContainer = ({ children, location }) => {
  return (
    <div className="bottomnavigation_container">
      <div
        className={
          location === "/"
            ? "bottomnavigation_backgroundimage_welcome"
            : "bottomnavigation_backgroundimage"
        }
      />
      
      {children !== null ? children : null}
    </div>
  );
};

const BottomNavigation = ({ fullStarScores, config }) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  let routeLocation = useLocation();
  const findStars = (difficultyNum, starType) => {
    let cumulativeScores = fullStarScores.filter(
      (d) => d.difficulty <= difficultyNum
    );
    let stars = 0;
    cumulativeScores.forEach((d) => {
      stars = stars + d[starType];
    });
    return stars;
  };
  if (routeLocation.pathname === "/") {
    let difficultyArray = [
      {
        key: 1,
        name: "Mouse",
        locked: false,
        currentScore: findStars(1, "currentScore") || 0,
        totalScore: findStars(1, "totalScore") || 1,
      },
      {
        key: 2,
        name: "Rabbit",
        locked: false,
        currentScore: findStars(2, "currentScore") || 0,
        totalScore: findStars(2, "totalScore") || 1,
      },
      {
        key: 3,
        name: "Beaver",
        locked: false,
        currentScore: findStars(3, "currentScore") || 0,
        totalScore: findStars(3, "totalScore") || 1,
      },
      {
        key: 4,
        name: "Moose",
        locked: true,
        currentScore: findStars(4, "currentScore") || 0,
        totalScore: findStars(4, "totalScore") || 1,
      },
      {
        key: 5,
        name: "Wolf",
        locked: true,
        currentScore: findStars(5, "currentScore") || 0,
        totalScore: findStars(5, "totalScore") || 1,
      },
    ];
    let locked = false;
    difficultyArray.map((d) => {
      let newD = d;
      newD.locked = locked;
      let percentage = (d.currentScore / d.totalScore) * 100;
      // At 75% of current highest difficulty progress, new difficulty will unlock
      if (percentage < -1) {
        locked = true;
      }
      return newD;
    });
    return (
      <BottomContainer location={routeLocation.pathname}>
        <div className="bottomnavigation_welcomecontents">
          {difficultyArray.map((dif) => {
            return (
              <Link
                key={dif.key}
                to={{
                  pathname: "/choose-unit",
                  search: `difficulty=${dif.key}`,
                }}
                className={dif.locked === true ? "disabled_link" : ""}>
                <DifficultyBadge {...dif} id={dif.key} />
              </Link>
            );
          })}
        </div>
        <div
          className="bottomnavigation_message"
          style={{ backgroundColor: config.BottomColor || 'black' }}>
          Please allow time for audio to load.
        </div>
      </BottomContainer>
    );
  } else if (
    routeLocation.pathname === "/choose-unit" ||
    routeLocation.pathname === "/choose-game-type"
  ) {
    let difficulty = Number(
      routeLocation.search
        .replace("?", "")
        .split("&")
        .find((str) => str.includes("difficulty"))
        ?.split("=")[1]
    );
    let progress = Math.round(
      (reduxState.bottomBarStarValues.currentStars /
        reduxState.bottomBarStarValues.totalStars) *
        100
    );
    return (
      <BottomContainer>
        <div className="bottomnavigation_chooseunitcontents">
          <div
            className="bottomnavigation_chooseunitbadge"
            style={{
              backgroundImage: `url(${fetchImage(
                "Level_" + difficulty + "_Button_Base1.png"
              )})`,
            }}
          />
          <ProgressBar
            width="clamp(100px, 60vw, 220px)"
            id=""
            progress={`${progress}%`}
          />
          {/* TODO: remove lock on this display if it unlocked */}
          {difficulty <= 4 ? (
            <div
              className="bottomnavigation_chooseunitbadge"
              style={{
                backgroundImage: `url(
                    ${fetchImage(
                      "Level_" + (difficulty + 1) + "_Button_Base1.png"
                    )}
                  )`,
              }}
            >
              {progress < 70 ? (
                <div className="bottomnavigation_chooseunitbadgecover">
                  <div className="bottomnavigation_chooseunitbadgelock" />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </BottomContainer>
    );
  } else if (routeLocation.pathname.includes("/start-game")) {
    return null;
  } else {
    return <BottomContainer children={null} />;
  }
};

export default BottomNavigation;

BottomContainer.propTypes = {
  children: PropTypes.object,
};
BottomNavigation.propTypes = {
  fullStarScores: PropTypes.array,
};