import "./index.scss";
import "../../../App.scss";
import PropTypes from 'prop-types';

const ReadyToStart = ({ categories, children }) => {
  if (!categories || categories.length > 0) {
    return (
      <div className="readytostart_overlayscreen">
        <div className="readytostart_box readytostart_popupwindow">
          <div className="readytostart_contentcontainer">{children}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="readytostart_overlayscreen">
        <div className="readytostart_box readytostart_popupwindow">
          <div className="readytostart_contentcontainer">
            <div>There are no selected categories.</div>
            <div>Please select at least one category.</div>
            <div className="readytostart_button">Select Categories</div>
          </div>
        </div>
      </div>
    );
  }
};

export default ReadyToStart;

ReadyToStart.propTypes = {
  categories: PropTypes.array,
  children: PropTypes.object,
};