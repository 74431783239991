import React from "react";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "./../Store/store";
import {
  fetchImage,
  compare,
  // fetchAudio,
} from "./nonUIFuncs";

const DataImageTest = () => {
  const reduxState = useSelector(selectReduxSlice);
  let sortedData = [...reduxState.gameData].sort((a, b) =>
    compare(a.App_Art, b.App_Art)
  );
  sortedData = sortedData.filter((d) => d.App_Art.length > 0);
  const fullscreen = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(4, 174, 239, 1.0)",
    display: "flex",
    justifyContent: "center",
  };
  return (
    <div style={fullscreen}>
      <div
        style={{
          overflowY: "scroll",
          height: "72%",
          marginTop: "8%",
          width: 400,
        }}
      >
        {sortedData.map((d) => {
          let fixedSetID = d.SetID.split("/");
          fixedSetID = fixedSetID[fixedSetID.length - 1];
          // let audioFile: any = {play: () => console.log('no audio file listed')};
          // if (d.App_Audio.length > 0) {
          //   audioFile = new Audio(fetchAudio(d.App_Audio));
          // }
          return (
            <div
              // onClick={() => audioFile.play()}
              key={d.Key}
              style={{
                backgroundColor:
                  d.App_Audio.length > 0 ? "yellow" : "rgba(0,0,0,0)",
                borderBottom: "2px black solid",
                display: "flex",
                justifyContent: "space-between",
                padding: "0 10px",
              }}
            >
              <div>
                <div>Key: {d.Key}</div>
                <div>{fixedSetID}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div>{d.English}</div>
                <div>
                  {d.App_Art.length > 0 ? (
                    <div
                      style={{
                        backgroundSize: "100% 100%",
                        backgroundImage: `url(${fetchImage(d.App_Art)})`,
                        height: 50,
                        width: 50,
                      }}
                    />
                  ) : (
                    <div>NO IMAGE LISTED</div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DataImageTest;
