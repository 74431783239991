import "../index.scss";
import React from "react";
import PropTypes from "prop-types";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../../Store/store";

const Card = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  const droppableContents = () => {
    let smallerDim = 1000;
    if (reduxState.height > reduxState.width) {
      smallerDim = reduxState.width;
    } else {
      smallerDim = reduxState.height;
    }
    let fontSize = smallerDim / 30;
    if (fontSize > 35) {
      fontSize = 35;
    } else if (fontSize < 18) {
      fontSize = 18;
    }
    return props.content.map((content) => {
      return (
        <Draggable
          key={content.id}
          type="cards"
          draggableId={content.id}
          index={props.indexed}>
          {(provided) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}>
              <div className="card-buttonStyle FITB-card">
                <div className="aboriginalSans noselect FITB-cardContents">
                  {content.content}
                </div>
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  };
  return (
    <Droppable type="cards" droppableId={props.cardLocation.id}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="emptyCard FITB-emptyCard">
          {droppableContents()}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
export default Card;

Card.propTypes = {
  cardLocation: PropTypes.object,
  content: PropTypes.array,
  indexed: PropTypes.number,
};
