import "./index.scss";
import PropTypes from 'prop-types';

import { fetchImage } from "../../nonUIFuncs";


let plusOne = fetchImage("+1.png");
let minusOne = fetchImage("-1.png");

const FloatingNumber = ({ correctness }) => {
  if (correctness !== null && correctness.length > 0) {
    return (
      <img
        src={correctness === "correct" ? plusOne : minusOne}
        className="floatingnumber_plusminusimage"
        alt=""
      />
    );
  } else {
    return null;
  }
};

export default FloatingNumber;

FloatingNumber.propTypes = {
  correctness: PropTypes.string,
};
