import "./index.scss";
import "../../../App.scss";
import { Link, useLocation } from "react-router-dom";
import ReadyToStart from "../ReadyToStart";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../../Store/store";
import { getSearchParams, goBack } from "../../nonUIFuncs";
import PropTypes from 'prop-types';

const EndEndlessGame = ({
  currentLevel,
  enabled,
  setShowEndlessEnd,
  endGame,
  updateLevelData,
}) => {
  const reduxState = useSelector(selectReduxSlice);
  let location = useLocation();
  const Params = getSearchParams(location);
  let categories = Params.find((d) => d.id === 'categories').value.split(',');
  let gameType = reduxState.specificGame.game;
  if (categories.length > 1) {
    gameType = `${categories.length} Categories`;
  }
  let data = {
    gameType: gameType,
    category: categories || null,
    difficulty: Params.find((d) => d.id === 'difficulty').value.toLowerCase(),
    newLevel: currentLevel,
    spreadGameType: reduxState.specificGame.displayGameType,
  };
  if (enabled) {
    return (
      <ReadyToStart
        categories={undefined}
        children={
          <div className="endendlessgame_container">
            <div className="endendlessgame_headercontainer">
              <h1>Are you sure you want to end this game?</h1>
              <h1>
                If you end this game, your current level will be recorded.
              </h1>
            </div>
            <div className="endendlessgame_buttoncontainer">
              <div
                className="endendlessgame_button_red"
                onClick={() => setShowEndlessEnd(false)}
              >
                <p className="endendlessgame_buttontext">Cancel</p>
              </div>
              <Link
                onClick={() => {
                  endGame !== undefined
                    ? endGame()
                    : console.log("no function");
                  updateLevelData(data);
                }}
                to={{
                  pathname: goBack(location).pathname,
                  search: goBack(location).search,
                }}
              >
                <div className="endendlessgame_button_green">
                  <p className="endendlessgame_buttontext">Continue</p>
                </div>
              </Link>
            </div>
          </div>
        }
      />
    );
  } else {
    return null;
  }
};

export default EndEndlessGame;

EndEndlessGame.propTypes = {
  currentLevel: PropTypes.number,
  enabled: PropTypes.bool,
  setShowEndlessEnd: PropTypes.func,
  endGame: PropTypes.func,
  updateLevelData: PropTypes.func,
};