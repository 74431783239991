import "./index.scss";

import { Link, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

import { fetchImage, goBack, getSearchParams } from "../../nonUIFuncs";
import config from "../../../config/Salish/config";
import { selectReduxSlice } from "../../../Store/store";

const TopNavigationButton = ({ pathname, icon, search }) => {
  return (
    <Link
      to={{
        pathname: pathname,
        search: search,
      }}
      className={`topnavigation_button_container_${icon}`}
    />
  );
};

const TopNavigation = ({
  showNav,
  showClearData,
  setShowClearData,
}) => {
  const routeLocation = useLocation();
  const params = getSearchParams(routeLocation);
  const unitParam = params.find((d) => d.id === 'unit');
  const difficultyParam = params.find((d) => d.id === 'difficulty');
  const reduxState = useSelector(selectReduxSlice);
  let fixSearchForStoryUnit = routeLocation.search.split('&');
  // console.log(routeLocation.search.includes("difficulty"));
  if (routeLocation.search.includes("difficulty")) {
    let difficulty = fixSearchForStoryUnit
      .find((d) => d.includes("difficulty"))
      .split("?")[1];
    fixSearchForStoryUnit = `?&${difficulty}`;
  }
  let badgeImage = '';
  if (unitParam !== undefined) {
    badgeImage = `url(${fetchImage(
      `Common_Category_Badge_${unitParam.modifier}1.png`
    )})`;
    if (unitParam.value.toUpperCase().includes("STORY")) {
      badgeImage = `url(${fetchImage(
        `Common_Category_Badge_Story_${unitParam.modifier}.png`
      )})`;
    }
  }
  if (showNav === true) {
    return (
      <div className="topnavigation_container">
        <div className="topnavigation_backgroundimage" />
        <div className="topnavigation_contents">
          <TopNavigationButton pathname="/" icon="home" search="" />
          <TopNavigationButton
            pathname={goBack(routeLocation).pathname}
            icon="back"
            search={goBack(routeLocation).search}
          />
          <TopNavigationButton pathname="/dictionary" icon="menu" search="" />
          {reduxState.historyData !== undefined ? (
            <TopNavigationButton
              pathname="/timeline"
              icon="timeline"
              search=""
            />
          ) : null}
          {reduxState.storybookVocab !== undefined &&
          difficultyParam !== undefined ? (
            <TopNavigationButton
              pathname="/choose-story-unit"
              icon="stories"
              search={fixSearchForStoryUnit}
            />
          ) : null}
          {config.Credits !== undefined ? (
            <TopNavigationButton
              pathname="/endless-mode"
              icon="endless"
              search=""
            />
          ) : null}
          {config.Credits !== undefined ? (
            <TopNavigationButton
              pathname="/credits"
              icon="credits"
              search=""
            />
          ) : null}
          {/* <TopNavigationButton pathname="/shoot-me" icon="plus" search="" />
          <TopNavigationButton pathname="/" icon="pause" search="" /> */}
          <div
            className="topnavigation_button_container_exit"
            onClick={() => setShowClearData(!showClearData)}
          />
        </div>
        {unitParam?.value.length > 0 ? (
          <div className="topnavigation_headercontainer">
            <h1 className="topnavigation_header">{unitParam.value}</h1>
          </div>
        ) : null}
        {unitParam?.value.length > 0 ? (
          <div className="topnavigation_badgecontainer">
            <div
              className="topnavigation_badge"
              style={{
                backgroundImage: badgeImage,
              }}
            />
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default TopNavigation;
export { TopNavigationButton };

TopNavigationButton.propTypes = {
  pathname: PropTypes.string,
  icon: PropTypes.string,
  search: PropTypes.string,
};

TopNavigation.propTypes = {
  showNav: PropTypes.bool,
  showClearData: PropTypes.bool,
  setShowClearData: PropTypes.func,
};