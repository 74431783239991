import "./index.scss";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DragDropContext } from "react-beautiful-dnd";

// import BottomStatBar from "../BottomStatBar";
// import StatScreen from "../StatScreen";
// import Timer from "../Timer";
import Card from "./Card";
import Symbol from "./Symbol";
import { shuffle, fetchImage } from "../nonUIFuncs";
import PopupWord from "../UI/PopupWord";
import FloatingNumber from "../UI/FloatingNumber";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";
import RoundBanner from "../UI/RoundBanner";

let currentTarget = {};
let shortTimer = 0;
let mouseClickX = 0;
let mouseClickY = 0;
let roundData = [];
let alreadyRemoved = false;
let distractorNumber = 2;
let formattedData = {
  cards: {},
  cardLocations: {},
  cardLocationOrder: [],
};
let formattedButtonData = {
  buttons: {},
  buttonLocations: {},
  buttonLocationOrder: [],
};
let totalBlankCards = 2;
let totalBlankButtons = 1;
let cardHeight = 45;
let cardWidth = 45;
let buttonDimm = 45;
let placematWidth = 45;
let placematOffset = buttonDimm * 2.5;
let originalFormattedData = {};
let firstNumber = undefined;
let bodyElement = null;
let fullTime = 40;
let solveSpotsButtons = [];
let solveSpotsCards = [];
let buttonDataFound = false;
let cardDataFound = false;
let showPopup = false;
let intervalId;
let round = 0;
let ready = false;

const SwitchDisplay = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  if (props.display === "stats" && props.drag === false) {
    if (shortTimer === 2) {
      props.stepDifficulty();
    }
    return (
      <div className="round-banner-container" >
        <RoundBanner
          findStars={props.findStars}
          starsObj={props.starsObj}
          round={round + 1}
        />
      </div>
    );
  } else if (props.display === "game") {
    if (reduxState.specificGame.category === "Math") {
      let showDivContainer = {
        position: "absolute",
        top: mouseClickY - reduxState.height / 10,
        left: mouseClickX,
        zIndex: 99999,
        height: 2,
        width: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
      if (fullTime < shortTimer) {
        shortTimer = 0;
        props.setDisplay("stats");
      }
      return (
        <div>
          <div style={showDivContainer}>
            <FloatingNumber correctness={props.correctness} />
          </div>
          <div className="SIG-content-container">
            <div className="SIG-buttonDataContainer">
              {props.displayButtonData()}
            </div>
            <div className="SIG-mainContentContainer">
              {props.displaySolveSpots()}
              {props.displayData()}
            </div>
          </div>
          <PopupWord popupWord={props.chosenPopupWord} enabled={showPopup} />
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const SolveItGame = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const difficultyRef = useRef();
  const reduxStateRef = useRef();
  const [correctness, setCorrectness] = useState(null);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [drag, setDrag] = useState(false);
  const [display, setDisplay] = useState("stats");
  const [difficulty, setDifficulty] = useState("easy");
  difficultyRef.current = difficulty;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    intervalId = setInterval(updateDisplayClock, 1000);
    cardHeight = reduxStateRef.current.height / 4.5;
    cardWidth = cardHeight * 0.714;
    buttonDimm = reduxStateRef.current.height / 16;
    placematWidth =
      reduxStateRef.current.width - reduxStateRef.current.height / 10;
    setDisplay("stats");
    setDifficulty("easy");
    gatherData();
    bodyElement = Array.from(document.getElementsByTagName("body"));
    bodyElement[0].style.overflow = "hidden";
    ready = true;
    return () => {
      setDisplay("stats");
      bodyElement[0].style.overflow = "auto";
      round = 0;
      setDifficulty("easy");
      clearInterval(intervalId);
      resetData();
    };
  }, []);
  const minMaxScreen = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const updateDisplayClock = () => {
    props.setProgress(`${Math.round((shortTimer / fullTime) * 100)}`);
    shortTimer = shortTimer + 1;
  };
  const gatherData = () => {
    resetData();
    mouseClickX = reduxStateRef.current.width / 2;
    mouseClickY = reduxStateRef.current.height / 2;
    let previousFirstNumber = firstNumber;
    let secondNumber = null;
    let thirdNumber = null;
    let fourthNumber = null;
    let pickANumber = 0;
    if (reduxStateRef.current.width < reduxStateRef.current.height) {
      placematWidth = reduxStateRef.current.width * 0.95;
      cardWidth = reduxStateRef.current.width / 7;
      cardHeight = cardWidth * 1.4;
      buttonDimm = reduxStateRef.current.width / 14;
    }
    if (reduxStateRef.current.difficulty !== "hard") {
      placematOffset = placematWidth / 8 - buttonDimm / 2;
      if (reduxStateRef.current.width < reduxStateRef.current.height) {
        placematOffset = placematWidth / 7 - buttonDimm / 2;
      }
      totalBlankCards = 2;
      totalBlankButtons = 1;
      distractorNumber = 1;
      firstNumber = minMaxScreen(2, reduxState.sortedData.length - 1);
      if (previousFirstNumber !== undefined) {
        while (previousFirstNumber === firstNumber) {
          firstNumber = minMaxScreen(2, reduxState.sortedData.length - 1);
        }
      }
      let snRange = firstNumber - 1;
      secondNumber = minMaxScreen(1, snRange);
      thirdNumber = firstNumber - secondNumber;
      pickANumber = Math.floor(Math.random() * 3);
      if (reduxStateRef.current.difficulty === "medium") {
        distractorNumber = 3;
      }
    } else {
      placematOffset = placematWidth / 10 - buttonDimm / 2;
      if (reduxStateRef.current.width < reduxStateRef.current.height) {
        placematOffset = placematWidth / 8.75 - buttonDimm / 2;
      }
      firstNumber = minMaxScreen(6, reduxState.sortedData.length - 1);
      if (previousFirstNumber !== undefined) {
        while (previousFirstNumber === firstNumber) {
          firstNumber = minMaxScreen(6, reduxState.sortedData.length - 1);
        }
      }

      let snRange = firstNumber - 2;
      secondNumber = minMaxScreen(2, snRange);
      let tnRange = firstNumber - secondNumber;
      thirdNumber = minMaxScreen(1, tnRange);
      fourthNumber = firstNumber - secondNumber - thirdNumber;
      pickANumber = Math.floor(Math.random() * 4);
      totalBlankCards = 3;
      totalBlankButtons = 2;
      distractorNumber = 3;
    }
    if (pickANumber === 0) {
      currentTarget = reduxState.sortedData[firstNumber];
    } else if (pickANumber === 1) {
      currentTarget = reduxState.sortedData[secondNumber];
    } else if (pickANumber === 2) {
      currentTarget = reduxState.sortedData[thirdNumber];
    } else if (pickANumber === 3) {
      currentTarget = reduxState.sortedData[fourthNumber];
    }
    let allNumbers = [];
    allNumbers.push(
      reduxState.sortedData[firstNumber],
      reduxState.sortedData[secondNumber],
      reduxState.sortedData[thirdNumber]
    );
    if (fourthNumber !== null) {
      allNumbers.push(reduxState.sortedData[fourthNumber]);
    }
    allNumbers.map((datum) => {
      if (datum.English === currentTarget.English && alreadyRemoved === false) {
        alreadyRemoved = true;
      } else {
        roundData.push(datum);
      }
    });
    let i = 0;
    while (i < distractorNumber) {
      let randomDistractorNumber = Math.floor(
        Math.random() * reduxState.sortedData.length
      );
      let distractor = reduxState.sortedData[randomDistractorNumber];
      roundData.push(distractor);
      i = i + 1;
    }
    shuffle(roundData);
    formatData();
    formatButtonData();
  };
  const formatButtonData = () => {
    let blankSpots = 0;
    while (blankSpots < totalBlankButtons) {
      blankSpots = blankSpots + 1;
      formattedButtonData.buttonLocations = {
        ...formattedButtonData.buttonLocations,
        [`solveButtonSpot${blankSpots}`]: {
          id: `solveButtonSpot${blankSpots}`,
          contentId: [],
        },
      };
      formattedButtonData.buttonLocationOrder.push(
        `solveButtonSpot${blankSpots}`
      );
    }
    formattedButtonData.buttons = {
      ...formattedButtonData.buttons,
      ["button1"]: { id: "button1", content: "+" },
      ["button2"]: { id: "button2", content: "+" },
      ["button3"]: { id: "button3", content: "-" },
      ["button4"]: { id: "button4", content: "-" },
    };
    formattedButtonData.buttonLocations = {
      ...formattedButtonData.buttonLocations,
      ["buttonSpot1"]: { id: "buttonSpot1", contentId: ["button1"] },
      ["buttonSpot2"]: { id: "buttonSpot2", contentId: ["button2"] },
      ["buttonSpot3"]: { id: "buttonSpot3", contentId: ["button3"] },
      ["buttonSpot4"]: { id: "buttonSpot4", contentId: ["button4"] },
    };
    formattedButtonData.buttonLocationOrder.push(
      "buttonSpot1",
      "buttonSpot2",
      "buttonSpot3",
      "buttonSpot4"
    );
  };
  const displayButtonData = () => {
    let buttonOptions = formattedButtonData.buttonLocationOrder.map(
      (buttonLocationId, index) => {
        const buttonLocation =
          formattedButtonData.buttonLocations[buttonLocationId];
        const content = buttonLocation.contentId.map(
          (buttonId) => formattedButtonData.buttons[buttonId]
        );
        return (
          <Symbol
            className="solveItGame-yesClick"
            key={buttonLocation.id}
            buttonLocation={buttonLocation}
            content={content}
            indexed={index}
          />
        );
      }
    );
    solveSpotsButtons = buttonOptions.slice(0, totalBlankButtons);
    let i = 0;
    for (i = 0; i < totalBlankButtons; i++) {
      buttonOptions.shift();
    }
    buttonDataFound = true;
    let placemat = {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      position: "absolute",
      top: reduxStateRef.current.height / 12,
      left: reduxStateRef.current.height / 20 + placematOffset,
      width: placematWidth + cardWidth - buttonDimm,
      height: reduxStateRef.current.height / 3,
    };
    if (reduxStateRef.current.width < reduxStateRef.current.height) {
      placemat.left = reduxStateRef.current.width * 0.025 + placematOffset;
      placemat.top = reduxStateRef.current.width * 0.025;
    }
    let button = {
      fontSize: 60,
      fontColor: "black",
      backgroundColor: "rgba(150,150,255,.4)",
      height: buttonDimm,
      width: buttonDimm,
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderStyle: "solid",
      borderColor: "black",
      borderWidth: 7,
    };
    button.fontSize = button.width / 1.25;
    return <div className="SIG-buttonOption">{buttonOptions}</div>;
  };
  const formatData = () => {
    let blankSpots = 0;
    while (blankSpots < totalBlankCards) {
      blankSpots = blankSpots + 1;
      formattedData.cardLocations = {
        ...formattedData.cardLocations,
        [`solveSpot${blankSpots}`]: {
          id: `solveSpot${blankSpots}`,
          contentId: [],
        },
      };
      formattedData.cardLocationOrder.push(`solveSpot${blankSpots}`);
    }
    let i = 0;
    roundData.map((datum) => {
      formattedData.cards = {
        ...formattedData.cards,
        [`cardDraggable${i}`]: {
          id: `cardDraggable${i}`,
          content: datum.English,
          Language: datum.Language,
        },
      };
      formattedData.cardLocations = {
        ...formattedData.cardLocations,
        [`location${i}`]: {
          id: `location${i}`,
          contentId: [`cardDraggable${i}`],
        },
      };
      formattedData.cardLocationOrder.push(`location${i}`);
      i = i + 1;
    });
    originalFormattedData = formattedData;
  };
  const displayData = () => {
    let cardOptions = formattedData.cardLocationOrder.map(
      (cardLocationId, index) => {
        const cardLocation = formattedData.cardLocations[cardLocationId];
        const content = cardLocation.contentId.map(
          (cardId) => formattedData.cards[cardId]
        );
        return (
          <Card
            cardLocation={cardLocation}
            content={content}
            indexed={index}
            key={cardLocation.id}
          />
        );
      }
    );
    solveSpotsCards = cardOptions.slice(0, totalBlankCards);
    let i = 0;
    for (i = 0; i < totalBlankCards; i++) {
      cardOptions.shift();
    }
    cardDataFound = true;
    return <div className="SIG-cardDOMDisplay">{cardOptions}</div>;
  };
  const displaySolveSpots = () => {
    let goalButtonDOM = (
      <div className="symbol-buttonStyle SIG-solveSpotButton">=</div>
    );
    let goalCardDOM = (
      <div className="card-buttonStyle SIGC-card thickBorder">
        <div className="SIGC-cardContents">{currentTarget.Language}</div>
        {/* <div className="SIGC-cardContents">{currentTarget.English}</div> */}
      </div>
    );
    if (buttonDataFound && cardDataFound) {
      if (difficultyRef.current !== "hard") {
        return (
          <div className="SIG-solveSpotContainer">
            {solveSpotsCards[0]}
            {solveSpotsButtons[0]}
            {solveSpotsCards[1]}
            {goalButtonDOM}
            {goalCardDOM}
          </div>
        );
      } else {
        return (
          <div className="SIG-solveSpotContainer">
            {solveSpotsCards[0]}
            {solveSpotsButtons[0]}
            {solveSpotsCards[1]}
            {solveSpotsButtons[1]}
            {solveSpotsCards[2]}
            {goalButtonDOM}
            {goalCardDOM}
          </div>
        );
      }
    } else {
      return null;
    }
  };
  const resetData = () => {
    roundData = [];
    formattedData = {
      cards: {},
      cardLocations: {},
      cardLocationOrder: [],
    };
    formattedButtonData = {
      buttons: {},
      buttonLocations: {},
      buttonLocationOrder: [],
    };
    alreadyRemoved = false;
  };
  const stepDifficulty = () => {
    resetData();
    props.setProgress("0%");
    ready = false;
    shortTimer = 0;
    setDisplay("game");
    if (round < 3) {
      round = round + 1;
      gatherData();
      ready = true;
    } else {
      props.checkWorld();
    }
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const onDragEnd = (result) => {
    setDrag(false);
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (formattedData.cardLocations[source.droppableId] !== undefined) {
      let begin = formattedData.cardLocations[source.droppableId];
      let end = formattedData.cardLocations[destination.droppableId];
      let beginContent = begin.contentId;
      let endContent = end.contentId;
      const newBegin = {
        ...begin,
        contentId: endContent,
      };
      const newEnd = {
        ...end,
        contentId: beginContent,
      };
      const newFormattedData = {
        ...formattedData,
        cardLocations: {
          ...formattedData.cardLocations,
          [newBegin.id]: newBegin,
          [newEnd.id]: newEnd,
        },
      };
      formattedData = newFormattedData;
    } else {
      let begin = formattedButtonData.buttonLocations[source.droppableId];
      let end = formattedButtonData.buttonLocations[destination.droppableId];
      let beginContent = begin.contentId;
      let endContent = end.contentId;
      const newBegin = {
        ...begin,
        contentId: endContent,
      };
      const newEnd = {
        ...end,
        contentId: beginContent,
      };
      const newFormattedData = {
        ...formattedButtonData,
        buttonLocations: {
          ...formattedButtonData.buttonLocations,
          [newBegin.id]: newBegin,
          [newEnd.id]: newEnd,
        },
      };
      formattedButtonData = newFormattedData;
    }
    if (
      formattedData.cardLocations.solveSpot1.contentId.length !== 0 &&
      formattedData.cardLocations.solveSpot2.contentId.length !== 0 &&
      formattedButtonData.buttonLocations.solveButtonSpot1.contentId.length !==
        0
    ) {
      if (difficultyRef.current !== "hard") {
        checkMath();
      } else {
        if (
          formattedData.cardLocations.solveSpot3.contentId.length !== 0 &&
          formattedButtonData.buttonLocations.solveButtonSpot2.contentId
            .length !== 0
        ) {
          checkMath();
        }
      }
    }
  };
  const checkMath = () => {
    let firstCardId = formattedData.cardLocations.solveSpot1.contentId[0];
    let firstNumber = null;
    let secondCardId = formattedData.cardLocations.solveSpot2.contentId[0];
    let secondNumber = null;
    let firstButtonId =
      formattedButtonData.buttonLocations.solveButtonSpot1.contentId[0];
    let firstButton = null;
    let thirdCardId = null;
    let secondButtonId = null;
    if (difficultyRef.current === "hard") {
      thirdCardId = formattedData.cardLocations.solveSpot3.contentId[0];
      secondButtonId =
        formattedButtonData.buttonLocations.solveButtonSpot2.contentId[0];
    }
    let thirdNumber = null;
    let secondButton = null;
    let findNumbers = Object.entries(formattedData.cards);
    findNumbers.map((datum) => {
      if (datum[0] === firstCardId) {
        firstNumber = Number(datum[1].content);
      } else if (datum[0] === secondCardId) {
        secondNumber = Number(datum[1].content);
      }
      if (difficultyRef.current !== "easy") {
        if (datum[0] === thirdCardId) {
          thirdNumber = Number(datum[1].content);
        }
      }
    });
    let findSymbols = Object.entries(formattedButtonData.buttons);
    findSymbols.map((datum) => {
      if (datum[0] === firstButtonId) {
        firstButton = datum[1].content;
      }
      if (difficultyRef.current !== "easy") {
        if (datum[0] === secondButtonId) {
          secondButton = datum[1].content;
        }
      }
    });
    let solution = null;
    if (difficultyRef.current !== "hard") {
      if (firstButton === "+") {
        solution = firstNumber + secondNumber;
      } else {
        solution = firstNumber - secondNumber;
      }
    } else {
      if (firstButton === "+") {
        if (secondButton === "+") {
          solution = firstNumber + secondNumber + thirdNumber;
        } else {
          solution = firstNumber + secondNumber - thirdNumber;
        }
      } else {
        if (secondButton === "+") {
          solution = firstNumber - secondNumber + thirdNumber;
        } else {
          solution = firstNumber - secondNumber - thirdNumber;
        }
      }
    }
    if (solution === Number(currentTarget.English)) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setCorrectness("correct");
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      gatherData(difficultyRef.current);
    } else {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setCorrectness("wrong");
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      resetData();
      formattedData = originalFormattedData;
      formatButtonData();
    }
    setTimeout(() => setCorrectness(null), 1000);
  };
  return (
    <DragDropContext onDragStart={() => setDrag(true)} onDragEnd={onDragEnd}>
      <div>
        <SwitchDisplay
          chosenPopupWord={chosenPopupWord}
          correctness={correctness}
          difficulty={difficultyRef.current}
          display={display}
          displayButtonData={displayButtonData}
          displayData={displayData}
          displaySolveSpots={displaySolveSpots}
          drag={drag}
          roundStats={reduxStateRef.current.stats.roundStats}
          setDisplay={setDisplay}
          stepDifficulty={stepDifficulty}
          findStars={props.findStars}
          starsObj={props.starsObj}
        />
      </div>
    </DragDropContext>
  );
};

export default SolveItGame;

SolveItGame.propTypes = {
  checkWorld: PropTypes.func,
};
SwitchDisplay.propTypes = {
  chosenPopupWord: PropTypes.string,
  correctness: PropTypes.string,
  difficulty: PropTypes.string,
  display: PropTypes.string,
  displayButtonData: PropTypes.func,
  displayData: PropTypes.func,
  displaySolveSpots: PropTypes.func,
  drag: PropTypes.bool,
  roundStats: PropTypes.object,
  setDisplay: PropTypes.func,
  stepDifficulty: PropTypes.func,
};
