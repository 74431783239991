import "./index.scss";
import "../../App.scss";
import { useEffect, useState, useRef } from "react";
import {
  fetchImage,
  redirectFromParams,
  compare,
  useLocationParams,
  getSearchParams,
} from "../nonUIFuncs";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, getBottomPercentageValues } from "../../Store/store";
import PropTypes from "prop-types";

const Unit = ({ image, unitName, Key, displayStars }) => {
  return (
    <div className="unit_container">
      <div className="unit_background" />
      <div className="unit_header">
        <h4 className="unit_headertext">{unitName}</h4>
      </div>
      <div className="unit_contents">
        <div className="unit_centercontents">
          <div
            className="unit_image"
            style={{ backgroundImage: `url(${fetchImage(image)})` }}
          >
            <Link
              to={{
                pathname: "/story-choose-game-type",
                search: useLocationParams(`unit=${Key + unitName}`, []),
              }}
              className="unit_playbutton hovercursor"
            >
              <p className="unit_playbuttontext">Play</p>
            </Link>
          </div>
        </div>
        <div className={`unit_stars_${displayStars}`} />
      </div>
    </div>
  );
};

const UnitDisplaySmallScreen = ({ units }) => {
  const currentUnitRef = useRef(1);
  const [currentUnit, setCurrentUnit] = useState(1);
  currentUnitRef.current = currentUnit;
  const backwardUnit = () => {
    if (currentUnitRef.current === 1) {
      setCurrentUnit(units.length);
    } else {
      setCurrentUnit(currentUnitRef.current - 1);
    }
  };
  const forwardUnit = () => {
    if (currentUnitRef.current === units.length) {
      setCurrentUnit(1);
    } else {
      setCurrentUnit(currentUnitRef.current + 1);
    }
  };
  let currentUnitData = units.find((d) => d.Key === currentUnit) || {
    image: "",
    unitName: "",
    Key: 1,
    displayStars: 0,
  };
  return (
    <div className="unitmenu_smallscreencontainer">
      <div onClick={backwardUnit} className="unitmenu_backwardunit" />
      <Unit {...currentUnitData} />
      <div onClick={forwardUnit} className="unitmenu_forwardunit" />
    </div>
  );
};

const StoryUnitMenu = ({ scoreData, config }) => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let difficultyFromLocation = Number(
    getSearchParams(location).find((param) => param.id === "difficulty").value
  );
  const reduxStateRef = useRef(reduxState);
  const [units, setUnits] = useState([]);
  reduxStateRef.current = reduxState;

  useEffect(() => {
    document.title = `${config.AppName} - Units`;
    let redirectPath = redirectFromParams(["difficulty"]) || "";
    if (redirectPath.length > 0) {
      navigate(redirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    findDifficultyGames(
      reduxStateRef.current.data,
      reduxStateRef.current.storybookVocab
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreData]);

  let unitArr = [];
  let unitNumArr = [];
  const findDifficultyGames = (data, gameData) => {
    let games = [];
    scoreData.forEach((d) => {
      if (d.difficultyScores.easy.difficulty === difficultyFromLocation) {
        games.push({
          ...d.difficultyScores.easy,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Easy`,
        });
      } else if (
        d.difficultyScores.medium.difficulty === difficultyFromLocation
      ) {
        games.push({
          ...d.difficultyScores.medium,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Medium`,
        });
      } else if (
        d.difficultyScores.hard.difficulty === difficultyFromLocation
      ) {
        games.push({
          ...d.difficultyScores.hard,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Hard`,
        });
      }
    });
    // setDifficultyGames(games);
    findUnits(games, data, gameData);
  };
  const findUnits = (games, data, gameData) => {
    gameData.forEach((datum) => {
      if (
        !unitNumArr.includes(datum.story) &&
        typeof Number(datum.story) === "number"
      ) {
        unitNumArr.push(datum.story);
        unitArr.push({
          Key: Number(datum.story),
          name: `Unit ${datum.story}`,
          unitName: datum.storyGame,
          image: `Category_Selection_Story_${datum.story}.png`,
          currentStars: 0,
          totalStars: 0,
          displayStars: 0,
        });
      }
      unitArr.sort((a, b) => compare(a.Key, b.Key));
    });
    findStars(unitArr, games, data);
  };
  const findStars = (unitArr, games, data) => {
    let testTotal = 0;
    let testCurrent = 0;
    let adjustedUnits = unitArr.map((unit) => {
      let currentStars = 0;
      let totalStars = 0;
      let displayStars = 0;
      games.forEach((d) => {
        if (d.category === unit.unitName) {
          currentStars = currentStars + d.stars;
          let goldStar = reduxStateRef.current.goldStar.find(
            (g) => g.game === d.gameType
          );
          if (goldStar?.secondType !== "none") {
            totalStars = totalStars + 6;
          } else {
            totalStars = totalStars + 3;
          }
        }
      });
      testTotal = testTotal + totalStars;
      testCurrent = testCurrent + currentStars;
      let percent = (currentStars / totalStars) * 100;
      if (percent >= 99) {
        displayStars = 3;
      } else if (percent >= 50 && percent < 99) {
        displayStars = 2;
      } else if (percent >= 1 && percent < 50) {
        displayStars = 1;
      } else {
        displayStars = 0;
      }
      return {
        ...unit,
        totalStars: totalStars,
        currentStars: currentStars,
        displayStars: displayStars,
      };
    });
    let uniqueCategories = [];
    adjustedUnits = adjustedUnits.filter((d) => {
      if (!uniqueCategories.includes(d.unitName)) {
        uniqueCategories.push(d.unitName);
        return d;
      }
    });
    let categoriesWGames = [];
    data.forEach((d) => {
      if (!categoriesWGames.includes(d.category.toUpperCase())) {
        categoriesWGames.push(d.category.toUpperCase());
      }
    });
    let filteredUnits = adjustedUnits.filter((d) =>
      categoriesWGames.includes(d.unitName.toUpperCase())
    );
    dispatch(getBottomPercentageValues(testCurrent, testTotal));
    setUnits(filteredUnits);
  };
  return (
    <div className="all_pages_container unitmenu_container">
      <div className="unitmenu_bannercontainer">
        <div className="unitmenu_banner">
          <h1>Pick A Unit!</h1>
        </div>
        <div className="unitmenu_guidecharacter" />
      </div>

      {reduxStateRef.current.width > 775 ? (
        <div className="unitmenu_contentscontainer">
          <div className="unitmenu_contents">
            {units.map((unit) => {
              return <Unit key={unit.name} {...unit} />;
            })}
          </div>
        </div>
      ) : (
        <UnitDisplaySmallScreen units={units} />
      )}
    </div>
  );
};

export default StoryUnitMenu;

Unit.propTypes = {
  image: PropTypes.string,
  unitName: PropTypes.string,
  Key: PropTypes.number,
  displayStars: PropTypes.number,
};

UnitDisplaySmallScreen.propTypes = {
  units: PropTypes.array,
};

StoryUnitMenu.propTypes = {
  scoreData: PropTypes.array,
  config: PropTypes.object,
};
