import "./index.scss";
import "../../App.scss";
import { useEffect } from 'react';
import DataImageTest from "../DataImageTest";
import { PropTypes } from "prop-types";

const Welcome = ({config}) => {
  useEffect(() => {
    document.title = `${config.AppName} - Home`;
  }, []);
  return (
    <div className="welcome_container all_pages_container">
      <div className="welcome_seasonstree">
        <div className="welcome_modal">
          <div className="welcome_guidecharacter" />
          <h1 className="welcome_modaltext">Welcome Back!</h1>
          <h1 className="welcome_modaltext">Choose your Level!</h1>
        </div>
      </div>
      {/* <DataImageTest /> */}
    </div>
  );
};

export default Welcome;

Welcome.propTypes = {
  config: PropTypes.object,
};