import "./index.scss";
import "../../App.scss";
import "../../index.scss";
import { Link } from "react-router-dom";
import { useLocationParams } from "../nonUIFuncs";

const RedirectPage = () => {
  return (
    <div className="redirect_container all_pages_container">
      <h1>This page does not exist</h1>
      <Link
        to={{ pathname: "/", search: useLocationParams("", []) }}
        className="redirect_button hovercursor">
        <p className="redirect_buttontext">Click here to return Home</p>
      </Link>
    </div>
  );
};

export default RedirectPage;
