import "../../index.scss";
import "./index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStats, setDifficulty, selectReduxSlice } from "../../Store/store";
// import { useLocation } from "react-router-dom";
import { fetchImage, getSearchParams } from "../nonUIFuncs";
import PropTypes from "prop-types";

const PlayScreenContainer = ({ children, gameDescription, gameTypeImage }) => {
  return (
    <div className="playscreen_container">
      <div className="playscreen_content">
        <div className="playscreen_buttoncontainer">{children}</div>
        <div className="playscreen_descriptionbox">
          <p className="playscreen_descriptiontext">{gameDescription}</p>
        </div>
      </div>
      <div className="playscreen_gametypebadgecontainer">
        <div
          className="playscreen_gametypebadge"
          style={{ backgroundImage: `url(${fetchImage(gameTypeImage)})` }}
        />
      </div>
    </div>
  );
};

const PlayEndlessScreen = ({
  setScreen,
  gameDescription,
  // scoreData,
  gameTypeImage,
}) => {
  const dispatch = useDispatch();
  return (
    <PlayScreenContainer
      gameDescription={gameDescription}
      gameTypeImage={gameTypeImage}>
      <div
        className="playscreen_playbutton hovercursor"
        onClick={() => {
          setScreen("gameScreen");
          dispatch(clearStats());
          dispatch(setDifficulty("easy"));
        }}>
        <h1 className="playscreen_playtext">Play!</h1>
      </div>
    </PlayScreenContainer>
  );
};

export default PlayEndlessScreen;

PlayEndlessScreen.propTypes = {
  setScreen: PropTypes.func,
  gameDescription: PropTypes.string,
  scoreData: PropTypes.array,
  gameTypeImage: PropTypes.string,
};
