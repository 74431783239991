import "./index.scss";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";
import { goBack, fetchImage } from "../nonUIFuncs";
import { useLocation, Link } from "react-router-dom";
import WordToImage from "../WordToImage";
import Stories from "../Stories";
import WhackAMole from "../WhackAMole";
import Matching from "../Matching";
import GoodGuess from "../GoodGuess";
import Wordless from "../Wordless";
import BubblePop from "../BubblePop";
import Counting from "../Counting";
import VerbActivity from "../VerbActivity";
import FillInTheBlank from "../FillInTheBlank";
import SolveIt from "../SolveIt";
import Crocodile from "../Crocodile";
import ItemFall from "../ItemFall";
import PropTypes from 'prop-types';

const AllGameSwitcher = ({
  gameType,
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  screenCover,
  setScreenCover,
  findStars,
  starsObj,
  backToStart,
  currentLevel,
  currentLevelProgress,
  setCurrentLevel,
  setCurrentLevelProgress,
}) => {
  switch (gameType) {
    case "Item Fall":
      return (
        <ItemFall
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          setScreenCover={setScreenCover}
          findStars={findStars}
          starsObj={starsObj}
          currentLevel={currentLevel}
          currentLevelProgress={currentLevelProgress}
          setCurrentLevel={setCurrentLevel}
          setCurrentLevelProgress={setCurrentLevelProgress}
        />
      );
    case "Crocodile":
      return (
        <Crocodile
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          setScreenCover={setScreenCover}
          findStars={findStars}
          starsObj={starsObj}
        />
      );
    case "Solve It":
      return (
        <SolveIt
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          setScreenCover={setScreenCover}
          findStars={findStars}
          starsObj={starsObj}
        />
      );
    case "Fill In The Blank":
      return (
        <FillInTheBlank
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          setScreenCover={setScreenCover}
          findStars={findStars}
          starsObj={starsObj}
        />
      );
    case "Story Book":
      return (
        <Stories
          checkWorld={checkWorld}
          children={children}
          setProgress={setProgress}
          findStars={findStars}
          starsObj={starsObj}
          backToStart={backToStart}
        />
      );
    case "Word To Image":
      return (
        <WordToImage
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          setScreenCover={setScreenCover}
          findStars={findStars}
          starsObj={starsObj}
        />
      );
    case "Whack-A-Mole":
      return (
        <WhackAMole
          children={children}
          setProgress={setProgress}
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          findStars={findStars}
          starsObj={starsObj}
          currentLevel={currentLevel}
          currentLevelProgress={currentLevelProgress}
          setCurrentLevel={setCurrentLevel}
          setCurrentLevelProgress={setCurrentLevelProgress}
        />
      );
    case "Matching":
      return (
        <Matching
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          setProgress={setProgress}
          children={children}
          findStars={findStars}
          starsObj={starsObj}
          setScreenCover={setScreenCover}
          currentLevel={currentLevel}
          currentLevelProgress={currentLevelProgress}
          setCurrentLevel={setCurrentLevel}
          setCurrentLevelProgress={setCurrentLevelProgress}
        />
      );
    case "Good Guess":
      return (
        <GoodGuess
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          findStars={findStars}
          starsObj={starsObj}
          currentLevel={currentLevel}
          currentLevelProgress={currentLevelProgress}
          setCurrentLevel={setCurrentLevel}
          setCurrentLevelProgress={setCurrentLevelProgress}
        />
      );
    case "Wordless":
      return (
        <Wordless
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          setScreenCover={setScreenCover}
          findStars={findStars}
          starsObj={starsObj}
          currentLevel={currentLevel}
          currentLevelProgress={currentLevelProgress}
          setCurrentLevel={setCurrentLevel}
          setCurrentLevelProgress={setCurrentLevelProgress}
        />
      );
    case "Bubble Pop":
      return (
        <BubblePop
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          findStars={findStars}
          starsObj={starsObj}
          currentLevel={currentLevel}
          currentLevelProgress={currentLevelProgress}
          setCurrentLevel={setCurrentLevel}
          setCurrentLevelProgress={setCurrentLevelProgress}
        />
      );
    case "Counting":
      return (
        <Counting
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          findStars={findStars}
          starsObj={starsObj}
          currentLevel={currentLevel}
          currentLevelProgress={currentLevelProgress}
          setCurrentLevel={setCurrentLevel}
          setCurrentLevelProgress={setCurrentLevelProgress}
        />
      );
    case "Verb Activity":
      return (
        <VerbActivity
          checkWorld={checkWorld}
          updateLevelData={updateLevelData}
          children={children}
          setProgress={setProgress}
          setScreenCover={setScreenCover}
          findStars={findStars}
          starsObj={starsObj}
        />
      );
    default:
      return <div>No Game Found</div>;
  }
};
const GameContainer = ({
  setScreen,
  setGameTime,
  updateLevelData,
  setProgress,
  scoreType,
  gameTypeImage,
  findStars,
  starsObj,
  gameStyle,
}) => {
  const displayTimeRef = useRef(0);
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const [displayTime, setDisplayTime] = useState(0);
  const [screenCover, setScreenCover] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const reduxState = useSelector(selectReduxSlice);
  const reduxStateRef = useRef(reduxState);
  const location = useLocation();
  reduxStateRef.current = reduxState;
  displayTimeRef.current = displayTime;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;

  useEffect(() => {
    let timer = setInterval(
      () => setDisplayTime(displayTimeRef.current + 1),
      1000
    );
    return () => {
      clearInterval(timer);
    };
  }, []);
  let unitName = "";
  let Key = 1;
  let gameType = location.search
    .replace("?", "")
    .split("&")
    .find((str) => str.includes("game-type="))
    ?.split("=")[1]
    .replaceAll("%20", " ")
    .replaceAll("%27", "'")
    .replaceAll("%E2%80%99", "’");
  let searchArr = location.search
    .replace("?", "")
    .split("&")
    .find((str) => str.includes("unit="));
  if (searchArr !== undefined) {
    unitName = searchArr
      .split("=")[1]
      .replaceAll("%20", " ")
      .replaceAll("%27", "'")
      .replaceAll("%E2%80%99", "’");
    if (isNaN(Number(unitName.slice(0, 2)))) {
      Key = Number(unitName.slice(0, 1));
      unitName = unitName.slice(1);
    } else {
      Key = Number(unitName.slice(0, 2));
      unitName = unitName.slice(2);
    }
  }
  const checkWorld = () => {
    setGameTime(displayTimeRef.current);
    setScreen("playAgainScreen");
  };
  const findStarsUpdated = (isStories) => {
    setGameTime(displayTimeRef.current);
    findStars(displayTimeRef.current, isStories);
  };
  if (gameStyle === 'blank') {
    return (
      <div>
        <Link
          to={{
            pathname: goBack(location).pathname,
            search: goBack(location).search,
          }}
          className="gamecontainer_backbutton"
        />
        <AllGameSwitcher
          setProgress={setProgress}
          checkWorld={checkWorld}
          gameType={typeof gameType === "string" ? gameType : ""}
          updateLevelData={updateLevelData}
          screenCover={screenCover}
          setScreenCover={setScreenCover}
          findStars={findStarsUpdated}
          starsObj={starsObj}
          backToStart={() => setScreen("playScreen")}
          currentLevel={currentLevelRef.current}
          setCurrentLevel={setCurrentLevel}
          currentLevelProgress={currentLevelProgressRef.current}
          setCurrentLevelProgress={setCurrentLevelProgress}
        />
        <div
          className={`gamecontainer_gametypebadgecontainer${
            scoreType === "time" &&
            !location.pathname.includes("/endless-mode/")
              ? "_alt"
              : ""
          }`}
        >
          <div
            className="gamecontainer_gametypebadge"
            style={{ backgroundImage: `url(${fetchImage(gameTypeImage)})` }}
          />
        </div>
        <div className="gamecontainer_statcontainer">
          {location.pathname.includes("/endless-mode/") ? (
            <div className="gamecontainer_statcontents">
              <h2 className="gamecontainer_statcontents_stat">
                {reduxStateRef.current.currentLevel}
              </h2>
              <div className="gamecontainer_statcontents_banner">
                <p>Level</p>
              </div>
            </div>
          ) : scoreType !== "time" ? (
            <div className="gamecontainer_statcontents">
              <h2 className="gamecontainer_statcontents_stat">
                {reduxState.stats.score}
              </h2>
              <div className="gamecontainer_statcontents_banner">
                <p id="levelNumber">Score</p>
              </div>
            </div>
          ) : (
            <div
              className="gamecontainer_statcontents"
              style={{ opacity: 0 }}
            />
          )}
          {scoreType !== "normal" ? (
            <div className="gamecontainer_statcontents">
              <h2 className="gamecontainer_statcontents_stat">
                {displayTimeRef.current}
              </h2>
              <div className="gamecontainer_statcontents_banner">
                <p>Time</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="gamecontainer_container">
        <div className="gamecontainer_container_background" />
        {/* UI Start */}
        {/* Top Banner */}
        {!location.pathname.includes("/endless-mode/") ? (
          <div className="gamecontainer_topbanner">
            <div className="gamecontainer_topbanner_contents">
              <div
                className="gamecontainer_topbanner_badge"
                style={{
                  backgroundImage: `url(${fetchImage(
                    `Common_Category_Badge_${Key}1.png`
                  )})`,
                }}
              />
              <h1 className="gamecontainer_topbanner_headertext">{unitName}</h1>
              <div
                className="gamecontainer_topbanner_badge"
                style={{
                  backgroundImage: `url(${fetchImage(
                    `Common_Category_Badge_${Key}1.png`
                  )})`,
                }}
              />
            </div>
          </div>
        ) : null}
        {/* Back Button */}
        <Link
          to={{
            pathname: goBack(location).pathname,
            search: goBack(location).search,
          }}
          className="gamecontainer_backbutton"
        />
        {/* UI End */}
        <div className="gamecontainer_contents">
          <AllGameSwitcher
            setProgress={setProgress}
            checkWorld={checkWorld}
            gameType={typeof gameType === "string" ? gameType : ""}
            updateLevelData={updateLevelData}
            screenCover={screenCover}
            setScreenCover={setScreenCover}
            findStars={findStarsUpdated}
            starsObj={starsObj}
            currentLevel={currentLevelRef.current}
            setCurrentLevel={setCurrentLevel}
            currentLevelProgress={currentLevelProgressRef.current}
            setCurrentLevelProgress={setCurrentLevelProgress}
            // children={

            // }
          />
        </div>
        <div
          className={`gamecontainer_gametypebadgecontainer${
            scoreType === "time" && !location.pathname.includes('/endless-mode/') ? "_alt" : ""
          }`}>
          <div
            className="gamecontainer_gametypebadge"
            style={{ backgroundImage: `url(${fetchImage(gameTypeImage)})` }}
          />
        </div>
        <div className="gamecontainer_statcontainer">
          {location.pathname.includes("/endless-mode/") ? (
            <div className="gamecontainer_statcontents">
              <h2 className="gamecontainer_statcontents_stat">
                {reduxStateRef.current.currentLevel}
              </h2>
              <div className="gamecontainer_statcontents_banner">
                <p>Level</p>
              </div>
            </div>
          ) : scoreType !== "time" ? (
              <div className="gamecontainer_statcontents">
                <h2 className="gamecontainer_statcontents_stat">
                  {reduxState.stats.score}
                </h2>
                <div className="gamecontainer_statcontents_banner">
                  <p id="levelNumber">Score</p>
                </div>
              </div>
            ) : (
              <div
                className="gamecontainer_statcontents"
                style={{ opacity: 0 }}
              />
            )}
          {scoreType !== "normal" ? (
            <div className="gamecontainer_statcontents">
              <h2 className="gamecontainer_statcontents_stat">
                {displayTimeRef.current}
              </h2>
              <div className="gamecontainer_statcontents_banner">
                <p>Time</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {screenCover &&
      (gameType === "Wordless" ||
        gameType === "Word To Image" ||
        gameType === "Matching") ? (
        <div className="timeoutdiv" onClick={() => setScreenCover(false)} />
      ) : null}
    </div>
  );
};

export default GameContainer;

GameContainer.propTypes = {
  setScreen: PropTypes.func,
  setGameTime: PropTypes.func,
  updateLevelData: PropTypes.func,
  setProgress: PropTypes.func,
  scoreType: PropTypes.string,
  gameTypeImage: PropTypes.string,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};

AllGameSwitcher.propTypes = {
  gameType: PropTypes.string,
  checkWorld: PropTypes.func,
  updateLevelData: PropTypes.func,
  children: PropTypes.object,
  setProgress: PropTypes.func,
  screenCover: PropTypes.bool,
  setScreenCover: PropTypes.func,
  findStars: PropTypes.func,
  starsObj: PropTypes.object,
};