import "./index.scss";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { DragDropContext } from "react-beautiful-dnd";
import { shuffle, fetchAudio, fetchImage } from "../nonUIFuncs";
import PopupWord from "../UI/PopupWord";
import FloatingNumber from "../UI/FloatingNumber";
import { useSelector, useDispatch } from "react-redux";
import { selectReduxSlice, setStats } from "../../Store/store";
import styled from "styled-components";

import Card from "./Card";
import RoundBanner from "../UI/RoundBanner";

let shortTimer = 0;
let distractorNumber = 2;
let formattedData = {
  cards: {},
  cardLocations: {},
  cardLocationOrder: [],
};
let totalBlankCards = 2;
let originalFormattedData = {};
let storySentences = [];
let allPossibleVocab = [];
let wordBank = [];
let currentPage = "";
let splitSentence = null;
let pageNumber = 1;
let sentenceAudio = undefined;
let listening = false;
let switchDisplayVar = "loading";
let showPopup = false;
let background = "";
let round = 0;
let ready = false;



const DisplayData = () => {
  let cardOptions = formattedData.cardLocationOrder.map(
    (cardLocationId, index) => {
      const cardLocation = formattedData.cardLocations[cardLocationId];
      const content = cardLocation.contentId.map(
        (cardId) => formattedData.cards[cardId]
      );
      return (
        <Card
          cardLocation={cardLocation}
          content={content}
          indexed={index}
          key={cardLocation.id}
        />
      );
    }
  );
  let solveSpots = cardOptions.slice(0, totalBlankCards);
  let i = 0;
  for (i = 0; i < totalBlankCards; i++) {
    cardOptions.shift();
  }
  let j = 0;
  let mergedArrays = splitSentence.map((string) => {
    if (string === "_") {
      let newString = solveSpots[j];
      j = j + 1;
      return newString;
    } else {
      return (
        <div className="fillInTheBlank-aboriginal FITB-plainText" key={string}>
          {string}
        </div>
      );
    }
  });
  return (
    <div className="FITB-container">
      <div className="FITB-placemat">{mergedArrays}</div>
      <div className="FITB-cardOptionsContainer">{cardOptions}</div>
    </div>
  );
};

const FillInTheBlankGame = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(selectReduxSlice);
  const reduxStatsRef = useRef();
  const reduxStateRef = useRef(reduxState);
  const [chosenPopupWord, setChosenPopupWord] = useState(null);
  const [correctness, setCorrectness] = useState(null);
  const [quicktime, setQuicktime] = useState(0);
  const [displayedClock, setDisplayedClock] = useState(0);
  reduxStatsRef.current = reduxState.stats;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    let timer = setInterval(updateDisplayClock, 1000);
    listening = false;
    switchDisplayVar = "game";
    storySentences = reduxState.storybookSentence.filter(
      (datum) => datum.target === reduxState.specificGame.target
    );
    storySentences.map((datum) => {
      if (datum.phaseOneWord !== "") {
        allPossibleVocab = [...allPossibleVocab, datum.phaseOneWord];
        if (datum.phaseTwoWord !== "") {
          allPossibleVocab = [...allPossibleVocab, datum.phaseTwoWord];
          if (datum.phaseThreeWord !== "") {
            allPossibleVocab = [...allPossibleVocab, datum.phaseThreeWord];
          }
        }
      }
    });
    pageNumber = Number(storySentences[0].page);
    gatherData();
    ready = true;
    return () => {
      listening = false;
      if (switchDisplayVar === "stats") {
        switchDisplayVar = "game";
      }
      if (sentenceAudio !== undefined) {
        sentenceAudio.pause();
        sentenceAudio = undefined;
      }
      round = 0;
      clearInterval(timer);
      setDisplayedClock(0);
      resetData();
    };
  }, []);
  const updateDisplayClock = () => {
    setDisplayedClock(displayedClock + 1);
    shortTimer = shortTimer + 1;
  };
  const gatherData = () => {
    resetData();
    currentPage = storySentences.find((datum) => datum.page == pageNumber);
    if (currentPage !== undefined) {
      sentenceAudio = new Audio(`${fetchAudio(currentPage.App_Audio)}`);
    }
    let pageVocab = [];
    if (currentPage === undefined) {
      shortTimer = 0;
      switchDisplayVar = "stats";
    } else {
      if (reduxStateRef.current.difficulty === "easy") {
        distractorNumber = 3;
        totalBlankCards = 1;
        pageVocab.push(currentPage.phaseOneWord);
        splitSentence = currentPage.phaseOneSentence.split(/([_])\w+/);
      } else if (reduxStateRef.current.difficulty === "medium") {
        if (Number(currentPage.phaseNum) >= 2) {
          distractorNumber = 2;
          totalBlankCards = 2;
          pageVocab.push(currentPage.phaseOneWord, currentPage.phaseTwoWord);
          splitSentence = currentPage.phaseTwoSentence.split(/([_])\w+/);
        } else {
          distractorNumber = 3;
          totalBlankCards = 1;
          pageVocab.push(currentPage.phaseOneWord);
          splitSentence = currentPage.phaseOneSentence.split(/([_])\w+/);
        }
      } else {
        if (Number(currentPage.phaseNum) === 3) {
          distractorNumber = 1;
          totalBlankCards = 3;
          pageVocab.push(
            currentPage.phaseOneWord,
            currentPage.phaseTwoWord,
            currentPage.phaseThreeWord
          );
          splitSentence = currentPage.phaseThreeSentence.split(/([_])\w+/);
        } else if (Number(currentPage.phaseNum) === 2) {
          distractorNumber = 2;
          totalBlankCards = 2;
          pageVocab.push(currentPage.phaseOneWord, currentPage.phaseTwoWord);
          splitSentence = currentPage.phaseTwoSentence.split(/([_])\w+/);
        } else {
          distractorNumber = 3;
          totalBlankCards = 1;
          pageVocab.push(currentPage.phaseOneWord);
          splitSentence = currentPage.phaseOneSentence.split(/([_])\w+/);
        }
      }
      let possibleDistractors = [];
      possibleDistractors = allPossibleVocab.filter(
        (datum) => datum !== pageVocab[0]
      );
      if (pageVocab[1]) {
        possibleDistractors = allPossibleVocab.filter(
          (datum) => datum !== pageVocab[1]
        );
      }
      if (pageVocab[2]) {
        possibleDistractors = allPossibleVocab.filter(
          (datum) => datum !== pageVocab[2]
        );
      }
      possibleDistractors = shuffle(possibleDistractors);
      let distractors = possibleDistractors.slice(0, distractorNumber);
      wordBank = distractors.concat(pageVocab);
      wordBank = shuffle(wordBank);
      switchDisplayVar = "game";
      pageNumber = pageNumber + 1;
      formatData();
    }
  };
  const formatData = () => {
    let blankSpots = 0;
    while (blankSpots < totalBlankCards) {
      blankSpots = blankSpots + 1;
      formattedData.cardLocations = {
        ...formattedData.cardLocations,
        [`solveSpot${blankSpots}`]: {
          id: `solveSpot${blankSpots}`,
          contentId: [],
        },
      };
      formattedData.cardLocationOrder.push(`solveSpot${blankSpots}`);
    }
    let i = 0;
    wordBank.map((datum) => {
      formattedData.cards = {
        ...formattedData.cards,
        [`cardDraggable${i}`]: {
          id: `cardDraggable${i}`,
          content: datum,
        },
      };
      formattedData.cardLocations = {
        ...formattedData.cardLocations,
        [`location${i}`]: {
          id: `location${i}`,
          contentId: [`cardDraggable${i}`],
        },
      };
      formattedData.cardLocationOrder.push(`location${i}`);
      i = i + 1;
    });
    originalFormattedData = formattedData;
  };
  const resetData = () => {
    wordBank = [];
    formattedData = {
      cards: {},
      cardLocations: {},
      cardLocationOrder: [],
    };
  };
  const hideDiv = () => {
    setCorrectness(null);
  };
  const stepDifficulty = () => {
    resetData();
    switchDisplayVar = "game";
    pageNumber = 1;
    props.setProgress("0%");
    ready = false;
    switchDisplayVar = "game";
    setDisplayedClock(0);
    props.checkWorld();
    dispatch(setStats({ roundStats: { hits: 0, misses: 0, score: 0 } }));
  };
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let begin = formattedData.cardLocations[source.droppableId];
    let end = formattedData.cardLocations[destination.droppableId];
    let beginContent = begin.contentId;
    let endContent = end.contentId;
    const newBegin = {
      ...begin,
      contentId: endContent,
    };
    const newEnd = {
      ...end,
      contentId: beginContent,
    };

    const newFormattedData = {
      ...formattedData,
      cardLocations: {
        ...formattedData.cardLocations,
        [newBegin.id]: newBegin,
        [newEnd.id]: newEnd,
      },
    };
    formattedData = newFormattedData;
    setQuicktime(quicktime + 1);
    if (reduxStateRef.current.difficulty === "easy") {
      if (formattedData.cardLocations.solveSpot1.contentId.length !== 0) {
        listening = true;
        sentenceAudio.play();
        sentenceAudio.onended = checkSentence;
      }
    } else if (reduxStateRef.current.difficulty === "medium") {
      if (Number(currentPage.phaseNum) >= 2) {
        if (
          formattedData.cardLocations.solveSpot2.contentId.length !== 0 &&
          formattedData.cardLocations.solveSpot1.contentId.length !== 0
        ) {
          listening = true;
          sentenceAudio.play();
          sentenceAudio.onended = checkSentence;
        }
      } else if (
        formattedData.cardLocations.solveSpot1.contentId.length !== 0
      ) {
        listening = true;
        sentenceAudio.play();
        sentenceAudio.onended = checkSentence;
      }
    } else {
      if (Number(currentPage.phaseNum) === 3) {
        if (
          formattedData.cardLocations.solveSpot3.contentId.length !== 0 &&
          formattedData.cardLocations.solveSpot2.contentId.length !== 0 &&
          formattedData.cardLocations.solveSpot1.contentId.length !== 0
        ) {
          listening = true;
          sentenceAudio.play();
          sentenceAudio.onended = checkSentence;
        }
      } else if (Number(currentPage.phaseNum) === 2) {
        if (
          formattedData.cardLocations.solveSpot2.contentId.length !== 0 &&
          formattedData.cardLocations.solveSpot1.contentId.length !== 0
        ) {
          listening = true;
          sentenceAudio.play();
          sentenceAudio.onended = checkSentence;
        }
      } else if (
        formattedData.cardLocations.solveSpot1.contentId.length !== 0
      ) {
        listening = true;
        sentenceAudio.play();
        sentenceAudio.onended = checkSentence;
      }
    }
  };
  const checkSentence = () => {
    listening = false;
    let originalSentence = currentPage.fullSentence;
    let firstFilledId = formattedData.cardLocations.solveSpot1.contentId[0];
    let firstWord = formattedData.cards[firstFilledId].content;
    let secondFilledId = null;
    let secondWord = null;
    let thirdFilledId = null;
    let thirdWord = null;
    if (formattedData.cardLocations.solveSpot2) {
      secondFilledId = formattedData.cardLocations.solveSpot2.contentId[0];
      secondWord = formattedData.cards[secondFilledId].content;
      if (formattedData.cardLocations.solveSpot3) {
        thirdFilledId = formattedData.cardLocations.solveSpot3.contentId[0];
        thirdWord = formattedData.cards[thirdFilledId].content;
      }
    }
    let i = 0;
    let fixingSentence = splitSentence.map((string) => {
      if (string === "_") {
        let newString = null;
        if (i === 0) {
          newString = firstWord;
          i = i + 1;
        } else if (i === 1) {
          newString = secondWord;
          i = i + 1;
        } else if (i === 2) {
          newString = thirdWord;
          i = i + 1;
        }
        return newString;
      } else {
        return string;
      }
    });
    let fixedSentence = fixingSentence.join("");
    if (fixedSentence === originalSentence) {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness("correct");
      props.setProgress(`${Math.round(((pageNumber - 1) / storySentences.length) * 100)}%`);
      dispatch(
        setStats({
          hits: reduxStatsRef.current.hits + 1,
          score: reduxStatsRef.current.score + 1,
          roundStats: {
            hits: reduxStatsRef.current.roundStats.hits + 1,
            score: reduxStatsRef.current.roundStats.score + 1,
          },
        })
      );
      gatherData();
    } else {
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness("wrong");
      dispatch(
        setStats({
          misses: reduxStatsRef.current.misses + 1,
          score: reduxStatsRef.current.score - 1,
          roundStats: {
            misses: reduxStatsRef.current.roundStats.misses + 1,
            score: reduxStatsRef.current.roundStats.score - 1,
          },
        })
      );
      resetData();
      formattedData = originalFormattedData;
    }
    setTimeout(hideDiv, 1000);
  };
  let backgroundImage = reduxState.specificGame.backgroundImg;
  if (currentPage) {
    backgroundImage = currentPage.App_Art;
    if (!background.includes(backgroundImage)) {
      background = `url(${fetchImage(backgroundImage)})`;
    }
  }
  if (switchDisplayVar === "stats") {
    ready = false;
    if (shortTimer === 4) {
      stepDifficulty();
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    top: calc(${reduxStateRef.current.height / 2}px - 18vh);
    left: ${reduxStateRef.current.width / 2}px;
    z-index: 99999;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        id="fullScreen"
        style={{ backgroundImage: background, backgroundSize: "100% 100%" }}
        className="FITB-fullScreen overflow-y: hidden;"
      >
        {switchDisplayVar === "stats" ? (
          <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <RoundBanner
              round={4}
              findStars={props.findStars}
              starsObj={props.starsObj}
            />
          </div>
        ) : (
          <div className="centerContentContainer">
            <FloatingNumberContainer>
              <FloatingNumber correctness={correctness} />
            </FloatingNumberContainer>
            {currentPage ? <DisplayData /> : null}
            <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          </div>
        )}
      </div>
      {listening ? <div className="FITB-fullScreen" /> : null}
    </DragDropContext>
  );
};

export default FillInTheBlankGame;

FillInTheBlankGame.propTypes = {
  checkWorld: PropTypes.func,
  storySentences: PropTypes.array,
  time: PropTypes.number,
  uiWords: PropTypes.array,
};
