import "./index.scss";
import "../index.scss";
import React from "react";
import PropTypes from "prop-types";
import { fetchImage } from "../../nonUIFuncs";
import { selectReduxSlice } from "../../../Store/store";
import { useSelector } from "react-redux";

const GameSelect = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  return (
    <div className="EM-GS-container">
      <div className="EM-topBanner">
        <div className="EM-topBanner-inner">Choose A Game</div>
      </div>

      <div className="EM-container">
        {props.games.map((datum) => {
          let randomNumber = Math.floor(Math.random() * reduxState.data.length);
          let gameSpecLvlData = props.levelData.filter(
            (game) => game.spreadGameType === datum
          );
          let bestEasy = gameSpecLvlData[0];
          let bestMedium = gameSpecLvlData[0];
          let bestHard = gameSpecLvlData[0];
          if (gameSpecLvlData.length === 0) {
            return null;
          }
          gameSpecLvlData.map((game) => {
            if (game.levelEasy > bestEasy.levelEasy) {
              bestEasy = game;
            }
            if (game.levelMedium > bestMedium.levelMedium) {
              bestMedium = game;
            }
            if (game.levelHard > bestHard.levelHard) {
              bestHard = game;
            }
          });
          let gameImg = datum.replaceAll("-", "_").replaceAll(" ", "_");
          let image = `Game_Type_${gameImg}2x.png`;
          return (
            <div
              id={datum}
              className="EM-GS-buttonContainer"
              onClick={() => props.findCategories(datum)}
              key={datum}>
              <div className="EM-GS-buttonTitle">{datum}</div>
              <div className="EM-GS-buttonContent">
                {bestEasy.levelEasy > 0 ? (
                  <div className="EM-GS-difficultyDisplay">
                    <div className="EM-GS-scoreText">Best Easy</div>
                    <div>
                      {bestEasy.category.length === 1
                        ? bestEasy.category[0]
                        : bestEasy.gameType}
                      : Lvl {bestEasy.levelEasy}
                    </div>
                  </div>
                ) : (
                  <div className="EM-GS-difficultyDisplay">
                    <div>No score for Easy difficulty</div>
                  </div>
                )}
                <div style={{border: 'rgba(255,255,255,0.6) 3px outset', width: '90%'}} />
                {bestMedium.levelMedium > 0 ? (
                  <div className="EM-GS-difficultyDisplay">
                    <div className="EM-GS-scoreText">Best Medium</div>
                    <div>
                      {bestMedium.category.length === 1
                        ? bestMedium.category
                        : bestMedium.gameType}
                      : Lvl {bestMedium.levelMedium}
                    </div>
                  </div>
                ) : (
                  <div className="EM-GS-difficultyDisplay">
                    <div>No score for Medium difficulty</div>
                  </div>
                )}
                <div style={{border: 'rgba(255,255,255,0.6) 3px outset', width: '90%'}} />
                {bestHard.levelHard > 0 ? (
                  <div className="EM-GS-difficultyDisplay">
                    <div className="EM-GS-scoreText">Best Hard</div>
                    <div>
                      {bestHard.category.length === 1
                        ? bestHard.category
                        : bestHard.gameType}
                      : Lvl {bestHard.levelHard}
                    </div>
                  </div>
                ) : (
                  <div className="EM-GS-difficultyDisplay">
                    <div>No score for Hard difficulty</div>
                  </div>
                )}
              </div>
              <div className="EM-GS-Go-Button">
                <div className="EM-GS-buttonTitle">Go!</div>
              </div>
              <div
                className="EM-GS-badge"
                style={{ backgroundImage: `url(${fetchImage(image)})` }}
              />
            </div>
          );
        })}
      </div>
      <div style={{height: 100}} />
    </div>
  );
};

export default GameSelect;

GameSelect.propTypes = {
  findCategories: PropTypes.func,
  games: PropTypes.array,
  levelData: PropTypes.array,
};
