import "./index.scss";

import PropTypes from 'prop-types';

const ClearDataPopup = ({ clearData, setShowClearData }) => {
  return (
    <div className="cleardatapopup_fullscreen">
      <div className="cleardatapopup_modal">
        <div className="cleardatapopup_modalcontentscontainer">
          <h1>Do you want to delete your saved data?</h1>
          <div className="cleardatapopup_buttoncontainer">
            <div
              className="cleardatapopup_buttonno"
              onClick={() => setShowClearData(false)}>
              <p className="cleardatapopup_buttontext">No</p>
            </div>
            <div
              className="cleardatapopup_buttonyes"
              onClick={() => clearData()}>
              <p className="cleardatapopup_buttontext">Yes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClearDataPopup;

ClearDataPopup.propTypes = {
  clearData: PropTypes.func,
  setShowClearData: PropTypes.func,
};