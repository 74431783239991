import "./index.scss";

import { useLayoutEffect } from "react";
import PropTypes from 'prop-types';

import { fetchImage } from "../../nonUIFuncs";

const DifficultyBadge = ({id, name, locked, currentScore, totalScore}) => {
  useLayoutEffect(() => {
    let badges = Array.from(document.getElementsByClassName('difficultybadge_container'));
    let badgeCollection = Array.prototype.slice.call(badges);
    badgeCollection.forEach((element, index) =>
      element.style['animation-delay'] = `${(index * 0.5)}s`
    );
    let scoreContainers = Array.from(document.getElementsByClassName('difficultybadge_scorecontainer'));
    let scoreContainerCollection = Array.prototype.slice.call(scoreContainers);
    scoreContainerCollection.forEach((element, index) =>
      element.style['animation-delay'] = `${(index * 0.5) + 2.5}s`
    );
  }, []);
  let badgeImage = `url(${fetchImage('Level_'+id+'_Button_Base1.png')})`;
  return (
    <div className='difficultybadge_container'>
      <div
        className={`difficultybadge_level_${id}`}
        style={{backgroundImage: badgeImage}}>
        <div className={`difficultybadge_cover${locked ? '' : '_unlocked'}`}>
          {locked ? <div className='difficultybadge_lock' /> : null}
        </div>
        {!locked ? (
          <div className='difficultybadge_scorecontainer'>
            <div className='difficultybadge_star' />
            <div className='difficultybadge_score'>
              <p>{currentScore}/{totalScore}</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DifficultyBadge;

DifficultyBadge.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  locked: PropTypes.bool,
  currentScore: PropTypes.number,
  totalScore: PropTypes.number,
};