// import '../index.scss';
// import React from "react";
// import PropTypes from "prop-types";
// import { Droppable } from "react-beautiful-dnd";
// import Card from "../Card/index";
// import { useSelector } from "react-redux";
// import { selectReduxSlice } from "../../../Store/store";

// const Table = ({cardLocation, content, drag}) => {
//   const reduxState = useSelector(selectReduxSlice);
//   let isDragDisabled = false;
//   if (cardLocation.id === "table") {
//     isDragDisabled = true;
//   }

//   let container = {
//     height: reduxState.height * 0.4,
//     width: reduxState.width * 0.8,
//     margin: 8,
//     border: "1px solid lightgrey",
//     borderRadius: 10,
//     display: "flex",
//     flexDirection: "column",
//     backgroundColor: "rgba(240, 230, 140, 0.4)",
//   };
//   let title = {
//     paddingTop: container.height / 40,
//     paddingBottom: container.height / 40,
//     fontSize: container.height / 20,
//     backgroundColor: "rgba(135, 206, 250, 0.7)",
//     borderRadius: 10,
//     display: "flex",
//     justifyContent: "center",
//   };
//   return (
//     <div style={container}>
//       <div style={title}>{cardLocation.title}</div>
//       <Droppable direction="horizontal" droppableId={cardLocation.id}>
//         {(provided) => (
//           <div
//             ref={provided.innerRef}
//             {...provided.droppableProps}
//             className='table-table'
//             // style={table}
//           >
//             {content.map((datum, index) => {
//               if (datum !== undefined) {
//                 return (
//                   <Card
//                     content={datum}
//                     displayDate={cardLocation.id}
//                     drag={drag}
//                     index={index}
//                     isDragDisabled={isDragDisabled}
//                     key={datum.id}
//                   />
//                 );
//               }
//             })}
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     </div>
//   );
// };

// export default Table;

// Table.propTypes = {
//   content: PropTypes.array,
//   cardLocation: PropTypes.object,
//   drag: PropTypes.bool,
// };

import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import Card from "../Card/index";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../../Store/store";

const Table = props => {
  const reduxState = useSelector(selectReduxSlice);
  let isDragDisabled = false;
  if (props.cardLocation.id === "table") {
    isDragDisabled = true;
  }

  let container = {
    height: reduxState.height * 0.35,
    width: reduxState.width * 0.8,
    margin: 8,
    border: "1px solid lightgrey",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    backgroundColor:"rgba(240,230,140,.4)",
  };
  let table = {
    padding: 8,
    display: "flex",
    flexGrow: 1,
    justifyContent:"space-evenly",
    alignItems:"center"
  };
  let title = {
    paddingTop: container.height/40,
    paddingBottom: container.height/40,
    fontSize: container.height/20,
    backgroundColor:"rgba(135,206,250,.7)",
    borderRadius:10,
    display:"flex",
    justifyContent:"center"
  }
  return (
    <div style={container}>
      <div style={title}>{props.cardLocation.title}</div>
      <Droppable direction="horizontal" droppableId={props.cardLocation.id}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={table}
          >
            {props.content.map((datum, index) => {
              if(datum !== undefined) {
                return (
                  <Card
                    content={datum}
                    displayDate={props.cardLocation.id}
                    drag={props.drag}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    key={datum.id}
                  />
                );
              }
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
export default Table;

Table.propTypes = {
  content: PropTypes.array,
  cardLocation: PropTypes.object,
  drag: PropTypes.bool,
};

{/* Checked PropTypes */}