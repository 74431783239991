import "./index.scss";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { selectReduxSlice } from "../../Store/store";
import { fetchImage, fetchAudio } from "../nonUIFuncs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faMagnifyingGlass,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
// import PropTypes from "prop-types";

const SearchBar = ({filter, setFilter}) => {
  let value = filter;
  if (value === null) {
    value = "";
  }
  return (
    <div className="search-bar-container">
      <div className="search-bar-icon-container">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          color="rgba(160,160,160,1)"
          size="xl"
        />
      </div>
      <input name="Search..." value={value} className="search-bar-input" onInput={(e) => setFilter(e)} />
    </div>
  );
};

const Dictionary = () => {
  const reduxState = useSelector(selectReduxSlice);
  const categoriesRef = useRef([]);
  const reduxStateRef = useRef(reduxState);
  const [categories, setCategories] = useState([]);
  const [expandedCat, setExpandedCat] = useState(null);
  const [expandedWord, setExpandedWord] = useState(null);
  const [displayCategory, setDisplayCategory] = useState([]);
  const [audio, setAudio] = useState(null);
  const [filter, setFilter] = useState(null);
  categoriesRef.current = categories;
  reduxStateRef.current = reduxState;
  useEffect(() => {
    initializeDisplay();
  }, [reduxState.gameData]);
  const initializeDisplay = () => {
    let tempCats = [];
    reduxState.gameData.forEach((d) => {
      if (!tempCats.includes(d.homeName)) {
        tempCats.push(d.homeName);
      }
    });
    setCategories(tempCats);
  };
  const setWordData = (category, newFilter = null) => {
    setExpandedCat(category);
    let noRepeat = [];
    let wordsToDis = [];
    let firstFilter = reduxState.gameData.filter(
      (d) => d.homeName === category
    );
    firstFilter.forEach((d) => {
      if (!noRepeat.includes(d.English)) {
        noRepeat.push(d.English);
        wordsToDis.push(d);
      }
    });
    if (newFilter !== null) {
      wordsToDis = wordsToDis.filter(
        (d) => d.English.toUpperCase().includes(newFilter.toUpperCase())
      );
    }
    setDisplayCategory(wordsToDis);
  };


  const filterData = (e) => {
    let currentStr = typeof filter === "string" ? filter : "";
    let newFilter = currentStr;
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      let newSearchStr = currentStr.slice(0, -1);
      if (newSearchStr.length === 0) {
        newSearchStr = null;
      }
      newFilter = newSearchStr;
      setFilter(newFilter);
    } else if (e.nativeEvent.inputType === 'insertText') {
    let searchStr = e.nativeEvent.data;
      newFilter = `${currentStr}${searchStr}`;
      setFilter(newFilter);
    }
    setWordData(expandedCat, newFilter);
  };

  return (
    <div className="Dict-fullscreen-container">
      <div className="Dict-content-container">
        <SearchBar filter={filter} setFilter={filterData} />
        <div className="Dict-inner-content-container">
          <div
            className={
              reduxState.width > 550
                ? "Dict-content-left"
                : "Dict-content-container"
            }
          >
            <div className="Dict-content-left-inner">
              {categoriesRef.current.map((d) => {
                return (
                  <div
                    onClick={() => setWordData(d, filter)}
                    className={
                      expandedCat === d
                        ? "Dict-category-selected"
                        : "Dict-category"
                    }
                    key={d}
                  >
                    <div className="Dict-category-title">{d}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Dict-content-right">
            <div className="Dict-content-right-inner">
              <div className="Dict-right-title">
                {expandedCat === null
                  ? "Select a Category to the left"
                  : expandedCat}
              </div>
              <div
                style={{
                  columnCount: reduxState.width > 550 ? 2 : 1,
                  breakInside: "avoid-column",
                }}
              >
                {displayCategory.map((d) => {
                  return (
                    <div
                      key={d.English}
                      className="Dict-word-expanded-container"
                    >
                      <div
                        className="Dict-word-button"
                        onClick={() => {
                          setAudio(new Audio(`${fetchAudio(d.App_Audio)}`));
                          setExpandedWord(
                            expandedWord === d.English ? null : d.English
                          );
                        }}
                      >
                        <div className="Dict-word-button-title-container">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div className="Dict-word-button-title">
                              {d.Language}
                            </div>
                            <div className="Dict-word-button-subtitle">
                              {d.English}
                            </div>
                          </div>
                          <FontAwesomeIcon
                            icon={
                              expandedWord === d.English
                                ? faChevronDown
                                : faChevronRight
                            }
                            color="white"
                          />
                        </div>
                      </div>
                      {expandedWord === d.English ? (
                        <div className="Dict-word-button-expanded-content">
                          <img
                            alt=""
                            src={fetchImage(d.App_Art)}
                            className="Dict-word-button-expanded-image"
                          />
                          <div
                            style={{
                              width: 250,
                              height: 50,
                              backgroundColor: "rgba(23, 118, 35, 1.0)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              borderRadius: 10,
                            }}
                            onClick={() => audio.play()}
                          >
                            <FontAwesomeIcon icon={faPlay} size="3x" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dictionary;

// Dictionary.propTypes = {
//   children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
// };
