import "../index.scss";
import React from "react";
import PropTypes from "prop-types";
import { Draggable, Droppable } from "react-beautiful-dnd";

const Card = (props) => {
  const droppableContents = () => {
    return props.content.map((content) => {
      return (
        <Draggable
          key={content.id}
          type="cards"
          draggableId={content.id}
          index={props.indexed}>
          {(provided) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}>
              <div className="card-buttonStyle SIGC-card">
                <div className="noselect SIGC-cardContents">
                  {content.Language}
                </div>
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  };
  return (
    <Droppable type="cards" droppableId={props.cardLocation.id}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="SIGC-card emptyCard"
          style={{marginBottom: 20}}>
          {droppableContents()}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
export default Card;

Card.propTypes = {
  cardLocation: PropTypes.object,
  content: PropTypes.array,
  indexed: PropTypes.number,
};
