import "../../index.scss";
import "./index.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStats, setDifficulty, selectReduxSlice } from "../../Store/store";
import { useLocation } from "react-router-dom";
import { fetchImage, getSearchParams, findGameFromConfigAndParams } from "../nonUIFuncs";
import PropTypes from "prop-types";

const PlayScreenContainer = ({
  children,
  gameDescription,
  gameTypeImage,
}) => {
  const location = useLocation();
  const reduxState = useSelector(selectReduxSlice);
  return (
    <div className="playscreen_container">
      <div className="playscreen_content">
        {/* {location.pathname.includes("/endless-mode/") ? (
          <div>
            {reduxState.endlessCategories.map((d) => {
              return (
                <div className="playscreen_gametypebadgecontainer">
                  <div
                    className="playscreen_gametypebadge"
                    style={{
                      backgroundImage: `url(${fetchImage(gameTypeImage)})`,
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null} */}

        <div className="playscreen_buttoncontainer">{children}</div>
        <div className="playscreen_descriptionbox">
          <p className="playscreen_descriptiontext">{gameDescription}</p>
        </div>
      </div>
      <div className="playscreen_gametypebadgecontainer">
        <div
          className="playscreen_gametypebadge"
          style={{ backgroundImage: `url(${fetchImage(gameTypeImage)})` }}
        />
      </div>
    </div>
  );
};

const PlayScreen = ({
  setScreen,
  gameDescription,
  scoreData,
  gameTypeImage,
  config,
}) => {
  const [mediumDisabled, setMediumDisabled] = useState(true);
  const [hardDisabled, setHardDisabled] = useState(true);
  const location = useLocation();
  const params = getSearchParams(location);
  const dispatch = useDispatch();
  useEffect(() => {
    if (scoreData.length > 0) {
      let difficultyParam = params.find((p) => p.id === "difficulty").value;
      if (!location.pathname.includes('/endless-mode/')) {
        let unitParam = params.find((p) => p.id === "unit").value;
        let gameNameParam = params.find((p) => p.id === "game-name").value;
        let game = scoreData.find(
          (d) => d.gameName === gameNameParam && d.category === unitParam
        );
        // let filteredGames = [];
        // config.ParamsToFindGame.forEach((p) => {
        //   let arrayToFilter = filteredGames.length > 0 ? filteredGames : scoreData;
        //   let param = params.find((d) => d.id === p.param);
        //   let value = param.value;
        //   let dataToCheck = p.data;
        //   if (param.id === 'game-type') {
        //     value = value.replaceAll(' ', '').replaceAll('-', '');
        //     dataToCheck = 'gameType';
        //   }
        //   arrayToFilter = arrayToFilter.filter((d) => d[dataToCheck] === value);
        //   filteredGames = arrayToFilter;
        // });
        game = findGameFromConfigAndParams(scoreData, params);
        if (game !== undefined) {
          if (
            game?.difficultyScores.medium.difficulty <= difficultyParam
          ) {
            setMediumDisabled(false);
          }
          if (game?.difficultyScores.hard.difficulty <= difficultyParam) {
            setHardDisabled(false);
          }
        }
      } else {
        console.log('endlessmode');
      }
    }
  }, [scoreData]);
  let gameType = params.find((d) => d.id === "game-type").value;
  // if (gameType === 'Verb Activity') {
  //   return (
  //     <PlayScreenContainer
  //       gameDescription={gameDescription}
  //       gameTypeImage={gameTypeImage}>
  //       <div
  //         className="playscreen_playbutton hovercursor"
  //         onClick={() => {
  //           setScreen("gameScreen");
  //           dispatch(clearStats());
  //           dispatch(setDifficulty("easy"));
  //         }}>
  //         <h1 className="playscreen_playtext">Play!</h1>
  //       </div>
  //     </PlayScreenContainer>
  //   );
  // }
  if (gameType === 'Story Book') {
    return (
      <PlayScreenContainer
        gameDescription="Read the Story!"
        // gameDescription={gameDescription}
        gameTypeImage={gameTypeImage}>
        <div
          className="playscreen_playbutton hovercursor"
          onClick={() => {
            setScreen("gameScreen");
            dispatch(clearStats());
            dispatch(setDifficulty("easy"));
          }}>
          <h1 className="playscreen_playtext">Play!</h1>
        </div>
      </PlayScreenContainer>
    );
  }
  return (
    <PlayScreenContainer
      gameDescription={gameDescription}
      gameTypeImage={gameTypeImage}>
      <div
        className="playscreen_playbutton hovercursor"
        onClick={() => {
          setScreen("gameScreen");
          dispatch(clearStats());
          dispatch(setDifficulty("easy"));
        }}>
        <h1 className="playscreen_playtext">Easy!</h1>
      </div>
      <div
        className={`
        playscreen_playbutton
        ${mediumDisabled ? "_disabled" : "hovercursor"}
      `}
        onClick={() => {
          setScreen("gameScreen");
          dispatch(clearStats());
          dispatch(setDifficulty("medium"));
        }}>
        <h1 className="playscreen_playtext">Medium!</h1>
      </div>
      <div
        className={`
        playscreen_playbutton
        ${hardDisabled ? "_disabled" : "hovercursor"}
      `}
        onClick={() => {
          setScreen("gameScreen");
          dispatch(clearStats());
          dispatch(setDifficulty("hard"));
        }}>
        <h1 className="playscreen_playtext">Hard!</h1>
      </div>
    </PlayScreenContainer>
  );
};

export default PlayScreen;

PlayScreen.propTypes = {
  setScreen: PropTypes.func,
  gameDescription: PropTypes.string,
  scoreData: PropTypes.array,
  gameTypeImage: PropTypes.string,
};