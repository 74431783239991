import "./index.scss";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import GameSelectButton from "../../GameSelectButton/index";
import { Link } from "react-router-dom";
import { fetchImage } from "../../nonUIFuncs";
import {
  selectReduxSlice,
  setEndlessCategories,
} from "../../../Store/store";
import { useSelector, useDispatch } from "react-redux";
import ReadyToStart from "../ReadyToStart";

const DifficultySelect = (props) => {
  const reduxState = useSelector(selectReduxSlice);
  const dispatch = useDispatch();
  const [difficulty, setDifficulty] = useState(null);
  useEffect(() => {
    setDifficulty(null);
  }, []);
  const resetSelections = () => {
    setDifficulty(null);
    props.resetSelections();
  };
  let chosenGame = "";
  if (props.game === "Wordless") {
    chosenGame = "Wordless";
  } else if (props.game === "Word To Image") {
    chosenGame = "WordToImage";
  } else if (props.game === "Matching") {
    chosenGame = "Matching";
  } else if (props.game === "Whack-A-Mole") {
    chosenGame = "WhackAMole";
  } else if (props.game === "Item Fall") {
    chosenGame = "ItemFall";
  } else if (props.game === "Good Guess") {
    chosenGame = "GoodGuess";
  } else if (props.game === "Bubble Pop") {
    chosenGame = "BubblePop";
  } else if (props.game === "Counting") {
    chosenGame = "Counting";
  }
  const DifficultyReturn = ({term}) => {
    let data = reduxState.uiWords.find((d) => d.English === term);
    if (data.Language.length > 0) {
      return (
        <div className="EM-DS-textcontainer">
          <p className="EM-DS-languagetext">{data.Language}</p>
        </div>
      );
    } else {
      return (
        <div className="EM-DS-textcontainer">
          <p className="EM-DS-englishtext">{data.English}</p>
        </div>
      );
    }
  };
  return (
    <div className="EM-DS-fullscreen">
      <div className="EM-topBanner">
        <div className="EM-topBanner-inner">Select a Difficulty</div>
      </div>
      <div className="EM-DS-buttonContainer">
        <div
          className="EM-DS-button"
          onClick={() => setDifficulty("Easy")}
        >
          <DifficultyReturn term="Easy" />
        </div>
        <div
          className="EM-DS-button"
          onClick={() => setDifficulty("Medium")}
        >
          <DifficultyReturn term="Medium" />
        </div>
        <div
          className="EM-DS-button"
          onClick={() => setDifficulty("Hard")}
        >
          <DifficultyReturn term="Hard" />
        </div>
      </div>
      <div className="EM-DS-bottomdisplay">
        <div className="EM-DS-bottomheader">
          <div className="EM-DS-bottomheader-text">{props.game}</div>
        </div>
        <div className="EM-DS-categoryContainer">
          {props.categories.map((datum) => {
            return (
              <div className="EM-DS-categoryText" key={datum}>
                {datum}
              </div>
            );
          })}
        </div>
      </div>
      {difficulty !== null ? (
        <ReadyToStart
          children={
            <div style={{width: '100%', height: '100%'}}>
              <div className="EM-DS-textContainer">
                <div className="EM-DS-headercontainer">
                  <div className="EM-DS-headertext">{props.game}</div>
                </div>
                <div className="EM-DS-categoryContainerFull">
                  {props.categories.map((datum) => (
                    <div className="EM-DS-categoryText" key={datum}>
                      {datum}
                    </div>
                  ))}
                </div>
                <div className="EM-DS-difficultyDisplay">
                  {difficulty}
                </div>
              </div>
              <div className="EM-DS-flex">
                <div className="EM-CS-button-goback" onClick={resetSelections}>
                  Reselect
                </div>
                <Link
                  to={{
                    pathname: `/endless-mode/start-game/`,
                    search: `game-type=${props.game}&difficulty=${difficulty}&categories=${props.categories}`,
                  }}
                  state={{
                    categories: props.categories,
                    difficulty: reduxState.difficulty,
                    endless: true,
                  }}
                  onClick={() => dispatch(setEndlessCategories(props.categories))}
                >
                  <div className="EM-CS-button-continue">Start Playing</div>
                </Link>
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
};

export default DifficultySelect;

DifficultySelect.propTypes = {
  categories: PropTypes.array,
  game: PropTypes.string,
  resetSelections: PropTypes.func,
};
